/*
    Orgへのアクセス制御
    org_admin: メンバー追加、設定変更ができる。ワークスペース作成者は自動でadminになる
    org_member: チームに参加できる
*/

export const ORGANIZATION_ADMIN = 'org_admin';
export const ORGANIZATION_MEMBER = 'org_member';

export const DELETE = 'delete';

/*
    ファイルやフォルダへのアクセス制御
    full_access: アクセス制御までできる
    can_edit: ファイルの編集までできる
    can_comment: コメント追加までできる
    can_view: 閲覧しかできない
*/

export const FULL_ACCESS = 'full_access';
export const CAN_EDIT = 'can_edit';
export const CAN_COMMENT = 'can_comment';
export const CAN_VIEW = 'can_view';
