import { filesToStudiesForUpload } from '../../lib/filesToStudies';
import { DICOM_FILETYPE } from '../constants';

export const splitDicomFiles = async (filesWithMime) => {
  /**
   * Split a list of files into DICOM files, and non-DICOM files.
   * Return both lists in an array.
   */
  // dicom以外とdicomに分離
  const notDcmFiles = filesWithMime.filter(
    file => file.filetype != DICOM_FILETYPE
  );
  const dcmFiles = filesWithMime.filter(
    file => file.filetype == DICOM_FILETYPE
  );
  return [dcmFiles, notDcmFiles];
}
