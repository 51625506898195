import React from 'react';
import PropTypes from 'prop-types';

import SharedList from './SharedList';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
  },
});

function SharedUser({
  data,
  permData,
  changeUserPerm,
  changeOrgPerm,
  isDisabled,
}) {
  const classes = useStyles();

  return (
    <>
      <SharedList
        data={data}
        users={permData['users']}
        own_org={permData['own_org']}
        changeUserPerm={changeUserPerm}
        changeOrgPerm={changeOrgPerm}
        isDisabled={isDisabled}
      />
    </>
  );
}

SharedUser.propTypes = {
  data: PropTypes.object.isRequired,
  permData: PropTypes.object.isRequired,
  changeUserPerm: PropTypes.func.isRequired,
  changeOrgPerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default SharedUser;
