import { useSelector } from 'react-redux';

export const useUsedOrgUUID = (orgUUID = '') => {
  // currentOrgUUID が空なら currentOrgUUID, そうでなければ orgUUID を返す
  const currentOrgUUID = useSelector(
    state => state.storageManager.currentOrgUUID
  );
  
  let usedOrgUUID;
  if (orgUUID == undefined || orgUUID == null || orgUUID == '') {
    usedOrgUUID = currentOrgUUID;
  } else {
    usedOrgUUID = orgUUID;
  }
  return usedOrgUUID;
};
