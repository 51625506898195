import React, {
  useCallback, useState, useContext, createContext, useEffect, useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import Button from '@material-ui/core/Button';
import {
  Box,
  Switch,
  Checkbox,
  FormControlLabel } from '@material-ui/core';

import {
  isDicom,
  isSeries,
  isFile,
  isRootFolder,
} from '../../../utils/checkData';
import { checkFullAccess } from '../../../utils/checkAccessPerm';
import { useUpdateMoveData } from '../api/fetchUpdateInfo';
import { patchFile, patchFolder, changeAnonymousPermApi } from '../../../api/rest.js';
import { TagsSelect } from '../components/tags/TagsSelect';
import { bite2XB, DateExtract } from '../../../utils/dataProcess';
import { getIcon } from '../../../utils/getIcon';

import { CAN_VIEW, DELETE } from '../../../utils/permissionSettings';

// 学会用なグローバル有効期限
import { useExpiryDate } from '../providers/ConferenceExpiryContext';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { filetypeExtract } from '../../../utils/dataProcess';
import { toast } from 'react-toastify';

import { useMultiUrl } from '../providers/MultiUrlProvider';
import { generateUniviewerUrl } from '../../univiewer/UniversalViewerContainer.js';
import { isConferencePage } from '../components/Conference/StorageConference.js';


export const ActiveRowContext = createContext();

export const ActiveRowProvider = props => {
  const [activeCell, setActiveCell] = useState({ rowIndex: -1, colId: '' });
  return (
    <ActiveRowContext.Provider
      value={{
        activeCell,
        setActiveCell,
      }}
    >
      {props.children}
    </ActiveRowContext.Provider>
  );
};

export const checkCanMove = (from, to) => {
  return !(
    from.uuid == to.uuid || // 自身から自身への移動は許さない
    isSeries(from) ||
    !checkFullAccess(from.perm) ||
    isDicom(to) ||
    isFile(to) ||
    (!checkFullAccess(to.perm) && !isRootFolder(to.type))
  );
};

export const useMoveData = () => {
  const updateMoveData = useUpdateMoveData();

  const moveFile = useCallback(async (data, parentUUID) => {
    await patchFile(data.uuid, { parent_folder: parentUUID });
  }, []);

  const moveFolder = useCallback(async (data, parentUUID) => {
    await patchFolder(data.uuid, { parent_folder: parentUUID });
  }, []);

  const moveData = useCallback(
    async (data, toFolder) => {
      if (!checkFullAccess(data.perm)) {
        alert(`移動するデータのフルアクセス権限が必要です`);
        return;
      } else if (isSeries(data)) {
        alert('シリーズを移動することはできません');
        return;
      } else if (isFile(data)) {
        await moveFile(data, toFolder.uuid);
      } else {
        await moveFolder(data, toFolder.uuid);
      }
      await updateMoveData(data, toFolder);
    },
    [moveFile, moveFolder, updateMoveData]
  );
  return moveData;
};

export const makeDropZoneIDforRoot = folderOrRoot => {
  return `drop-zone-target-${folderOrRoot.type}`;
};
export const makeDropZoneIDforFolder = folderOrRoot => {
  return `drop-zone-target-folder-${folderOrRoot.uuid}`;
};

function NameCoreRenderer({ data }) {

  // Import the checked state provider
  const { checkedStudyIDs, setCheckedStudyIDs } = useMultiUrl();
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    // Invert the existing state
    const isChecked = !checked
    setChecked(isChecked);
    console.log(data.uuid, 'check', checked);
    // Set checked state in MultiUrl list
    // Update the checkedStudyIDs set

    // TODO: BUG: when the checkbox for a study is selected here, it also changes selectData in useSelectData.js, making the UI think that the folder for this study is active (incorrect), rather than the parent folder containing this study (correct). selectData should not change just because we clicked this checkbox.
    setCheckedStudyIDs((prevCheckedStudyIDs) => {
      const newCheckedStudyIDs = new Set(prevCheckedStudyIDs);

      if (isChecked) {
        // Add data.uuid to the set if checked
        console.log('Add to the set', data.uuid);
        newCheckedStudyIDs.add(data.uuid);
      } else {
        // Remove data.uuid from the set if unchecked
        newCheckedStudyIDs.delete(data.uuid);
      }
      return newCheckedStudyIDs;
    });
  };

  useEffect(() => {
    if (checkedStudyIDs.size > 0) {
      console.log('Selected studies: ', checkedStudyIDs);
    }
  }, [checkedStudyIDs]);


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        lineHeight: '1.2',
        paddingTop: '10px',
        paddingBottom: '5px',
      }}
    >

      { isDicom(data) && (
        // Show a selectable checkbox here, only for DICOM data.
        // Use state from React Context provider to handle selection
        <Checkbox
          checked={checked}
          disabled={!isDicom(data)}
          // onChange doesn't work for Checkbox, use onClick
          onClick={handleChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )}
      {getIcon(data)}
      {data.name}
    </Box>
  );
}

function ActionCoreRenderer({ data }) {
  const history = useHistory();
  const openViewer = () => {
    history.push(`/viewer?url=/api/datamanage/dcm/folder/${data.uuid}`);
  };
  const openViewerFile = () => {
    // jpg, png
    history.push(
      `/viewer?url=/api/datamanage/dcm/fileimage/${data.uuid}&dataType=file`
    );
  };

  return (
    <>
      {(data.object_type == 'folder' && data.foldertype == 'study') ||
      data.foldertype == 'series' ||
      data.foldertype == 'imgSeries' ? (
        <Button onClick={openViewer} style={{ padding: 0 }}>
          <PageviewOutlinedIcon />
        </Button>
      ) : data.object_type == 'file' &&
        (data.filetype === 'image/png' ||
          data.filetype === 'image/jpg' ||
          data.filetype === 'image/jpeg') ? (
        <Button onClick={openViewerFile} style={{ padding: 0 }}>
          <PageviewOutlinedIcon />
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}

function getViewerURL(data) {
  if (isDicom(data)) {
    // Default: Universal Viewer URL
    const univiewerUrl = generateUniviewerUrl(data);
    return univiewerUrl;
  } else {
    const extractedFiletype = filetypeExtract(data.filetype);
    const isImgFile =
      data.object_type === 'file' &&
      (extractedFiletype === 'png' ||
        extractedFiletype === 'jpg' ||
        extractedFiletype === 'jpeg');
    if (isImgFile){
      return window.location.origin +
        `/viewer?url=/api/datamanage/dcm/fileimage/${data.uuid}&dataType=file`;
    } else {
      // Non-image file, e.g. PDF
      return window.location.origin +
        `/viewer?url=/api/datamanage/dcm/file/${data.uuid}`;
    }
  }
}

/**
 * Renders the widget containing the Conference URL shared state and URL copy button.
 * This widget is only displayed if the foldertype is study (DICOM data),
 * the user is at a Conference URL, and user is logged in.
 * @param {*} param0
 * @returns
 */
function ShareLinkCoreRenderer({ data }) {

  // Initialize shared state of each item using data from backend
  const initShared = data.anonymous_perm == 'can_view' ? true : false;
  const [shared, setShared] = useState(initShared);
  const isStudy = data.foldertype === 'study';

  // Check data type, get the appropriate URL
  const url = getViewerURL(data);

  // Get the global (conference-level) expiry date
  const isConference = isConferencePage();
  const { expiryDate, globalShared } = useExpiryDate();
  // Reference to keep track of first render. https://stackoverflow.com/a/53254028
  const firstUpdate = useRef(true);

  const saveSharedStatus = async (newStatus) => {
    // Set anonymous perm to 'view' if sharing enabled, otherwise set to 'delete'
    const newPerm = newStatus ? CAN_VIEW : DELETE;
    // Set expiry date to the selected date if sharing enabled, otherwise set to null
    const newExpiryDate = newStatus ? expiryDate: null;
    console.log(data.name, 'Public URL', newStatus, 'expiryDate', newExpiryDate, data);
    setShared(newStatus);
    await changeAnonymousPermApi(
      data.object_type,
      data.uuid,
      newPerm,
      newExpiryDate
    );
  }

  // This useEffect fires when the global expiry date is changed
  // when Expiry date is changed, apply to all items in folder
  useEffect(() => {
    if (isConference) {
      if (expiryDate) {
        if (shared && expiryDate) {
          console.debug(data.name, 'expiryDate UPDATED', expiryDate);
          // Save new expiry date for this item.
          saveSharedStatus(shared);
        } else {
          console.log(data.name, 'is not shared');
        }
      }
    } else {
      console.debug(data.name, 'Not in conference page.');
    }
  }, [expiryDate]);

  // This useEffect fires when the global shared switch is toggled
  // Apply the new shared state to all items in folder
  useEffect(() => {
    if (isConference) {
      // Only apply if this is not the initial render
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      console.log('globalShared set to', globalShared, 'save for', data.name);
      saveSharedStatus(globalShared);
    }
  }, [globalShared]);

  const handleClick = async () => {
    // On-off toggle was clicked. Save the opposite of current shared state
    await saveSharedStatus(!shared);
  }
  const onCopyURL = () => {
    const message = 'URLはコピーされました';
    console.log(message);
    toast.info(message);
  }

  return (
    <div>
      {isStudy && (
        <div>
          <Switch
            checked={shared}
            onClick={handleClick}
            name="shareCheckbox"
            color="primary"
          />
          <CopyToClipBoard text={url} onCopy={onCopyURL}>
            <Button
              variant="contained"
              disabled={!shared}
            >
              URLをコピー
            </Button>
          </CopyToClipBoard>
        </div>
      )}
    </div>
  );
}

const getFileTypeOrFolderType = ({ data }) => {
  if (data.object_type == 'folder') {
    return data.foldertype;
  } else if (data.object_type == 'file') {
    return data.filetype;
  } else {
    return '';
  }
};


let allColumnsDefs = [
  {
    field: 'name',
    headerName: '名前',
    rowDrag: true,
    /*
    wrapText&autoHeightの問題点
    - autoHeight is typically used with wrapText. If wrapText is not set, and no custom Cell Renderer Component is used, then the cell will display all its contents on one line. This is probably not the intention if using Auto Row Height.(https://www.ag-grid.com/react-data-grid/row-height/#auto-row-height)
    - Don't use Grid Auto Height when displaying large numbers of rows (https://www.ag-grid.com/javascript-data-grid/grid-size/#grid-auto-height)
    */
    wrapText: true,
    autoHeight: true,
    sortable: true,
    // Allow name field to be re-ordered along with other columns
    // lockPosition: 'left',
    cellRendererParams: {
      coreRenderer: NameCoreRenderer,
    },
  },

  {
    field: 'action',
    headerName: 'アクション',
    sortable: false,
    cellRendererParams: {
      coreRenderer: ActionCoreRenderer,
    },
  },
  {
    field: 'type',
    headerName: 'type',
    cellRendererParams: {
      coreRenderer: getFileTypeOrFolderType,
    },
    comparator: function(valueA, valueB, nodeA, nodeB, isDescending) {
      // valueA, valueB は undefinedになる
      const a = getFileTypeOrFolderType({ data: nodeA.data });
      const b = getFileTypeOrFolderType({ data: nodeB.data });
      if (a == b) return 0;
      return a > b ? 1 : -1;
    },
  },
  {
    field: 'tags',
    headerName: 'タグ',
    cellRendererParams: {
      coreRenderer: TagsSelect,
    },
  },
  {
    field: 'created_dt',
    headerName: 'アップロード日時',
    cellRendererParams: {
      coreRenderer: ({ data }) => {
        return DateExtract(data.created_dt);
      },
    },
  },
  {
    field: 'size',
    headerName: 'サイズ',
    cellRendererParams: {
      coreRenderer: ({ data }) => {
        return <div>{bite2XB(data.size)}</div>;
      },
    },
  },
  {
    field: 'PatientSex',
    headerName: '性別',
  },
  {
    field: 'modality_list',
    headerName: 'モダリティ',
  },
  {
    field: 'bodypartexamined_list',
    headerName: '検査部位',
  },
  {
    field: 'instance_count',
    headerName: 'インスタンス数',
  },
  {
    field: 'series_count',
    headerName: 'シリーズ数',
  },
  {
    field: 'PatientID',
    headerName: '患者ID',
  },
  {
    field: 'ProtocolName',
    headerName: 'ProtocolName',
  },
  {
    field: 'ReferringPhysicianName',
    headerName: '参照医師',
  },
  {
    field: 'InstitutionName',
    headerName: '診療所',
  },
  { field: 'uuid', headerName: 'ID' },
];

// Add 共有 column to the list of columns, if Conference
if (window.location.pathname.startsWith('/conference/')) {
  console.debug('aggridUtils Conference, add 共有 column');
  allColumnsDefs.splice(1, 0,
    {
      field: 'share',
      headerName: '共有',
      sortable: false,
      autoHeight: true,
      cellRendererParams: {
        coreRenderer: ShareLinkCoreRenderer,
      },
    }
  );
}

export { allColumnsDefs };
