import * as tools from '../tools';

const childTools = [];
Object.keys(tools).forEach(key => childTools.push(tools[key]));

// Label for annotation column (right drawer)
export const allTools = {
  id: 'allTools',
  name: 'アノテーション',
  childTools: childTools,
  options: {
    caseProgress: {
      include: true,
      evaluate: true,
    },
  },
};
