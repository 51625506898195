/**
 * This component is used by MNES Admin staff to control access to
 * custom applications within S9, such as Medical Search.
 *
 * It is also used to approve or deny new user accounts.
 */

import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  Dialog,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Typography,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  changeCustomPermApi, pendingUserApi
} from '../../../../api/rest';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '10px',
    // margin: '0.4rem',
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  userListContainer: {
    // maxWidth: '100%',
    width: '100%',
  },
}));

const CustomUserPermPage = ({
  adminList, setAdminList, medSearchList, setMedSearchList, allUserList
}) => {
  /**
   * props for this component:
   * - list of admins who are authorized to make changes to user perms
   * - list of users who can access Medical Search
   *  */

  const classes = useStyles();
  const isProd = process.env.REACT_APP_ENV === 'production';
  const isStaging = process.env.REACT_APP_ENV === 'development';

  // Delete and add Admin users, and Medical Search users
  const handleAddAdmin = async (user) => {
    // Ensure user is not already in the list
    const existingUser = adminList.find((item) => item.id === user.id);
    if (!existingUser){
      const data = {
        perm_name: 'change_user',
        action: 'add',
        user_update: user
      }
      const response = await changeCustomPermApi(data);
      setAdminList((adminList) => [...adminList, user]);
    }
  };

  const handleDelAdmin = async (user) => {
    const data = {
      perm_name: 'change_user',
      action: 'remove',
      user_update: user
    }
    const response = await changeCustomPermApi(data);
    setAdminList((adminList) => adminList.filter((item) => item.id !== user.id));
  };

  const handleAddMedSearch = async (user) => {
    // Ensure user id is not already in the list
    const existingUser = medSearchList.find((item) => item.id === user.id);
    if (!existingUser){
      // Call API to add user
      const data = {
        perm_name: 'can_access_medical_search',
        action: 'add',
        user_update: user
      }
      console.log('handleAddMedSearch', data);
      const response = await changeCustomPermApi(data);
      setMedSearchList((medSearchList) => [...medSearchList, user]);
    } else {
      console.log(user, 'already in the list, skip');
    }
  }

  const handleDelMedSearch = async (user) => {
    const data = {
      perm_name: 'can_access_medical_search',
      action: 'remove',
      user_update: user
    }
    console.log('handleDelMedSearch', data);
    const response = await changeCustomPermApi(data);
    setMedSearchList((medSearchList) => medSearchList.filter((item) => item.id !== user.id));
  };

  const userAutocomplete = (userList, addUserFunc) => {
    console.debug('userAutocomplete', userList);

    const handleSelect = (event, user) => {
      console.log('handleSelect', user);
      // user will be null if X in combo box was clicked
      if (user) {
        // Add new user with the specified addUser function
        addUserFunc(user);
      } else {
        console.log('No selection');
      }
    }

    return (
      <div>
        { userList ? (
          <Autocomplete
            id="user-email-combo-box"
            options={userList}
            getOptionLabel={(option) => option.email}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="メールアドレスで検索" variant="outlined" />}
            // onChange={() => handleSelect(addUserFunc)}
            onChange={handleSelect}
          />
        ) : (
          // if userList is empty (should never happen), return empty div
          <div></div>
        )}
      </div>

    );
  }

  function makeMedSearchList() {

    return (
      <div>
        {userAutocomplete(allUserList, handleAddMedSearch)}
        <List dense={true}>
          {medSearchList.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.email}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon
                    onClick={() => handleDelMedSearch(user)}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  function makeAdminList() {
    return (
      <div>
        {userAutocomplete(allUserList, handleAddAdmin)}

        <List dense={true}>
          {adminList.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.email}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon
                    onClick={() => handleDelAdmin(user)}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  return (
    <div>
      {/* Show admin user list */}
      <div>
        <Typography variant="h6" className={classes.title}>
          管理者ユーザ ーリスト
        </Typography>
        <Grid item xs={12} md={6}>
          {makeAdminList()}
        </Grid>
      </div>
      {/* Show Medical Search user list only if not production */}
      { !isProd && (
        <div>
          <Typography variant="h6" className={classes.title}>
            Medical Searchの権限管理
          </Typography>
          <Grid item xs={12} md={6}>
            {makeMedSearchList()}
          </Grid>
        </div>
      )}
    </div>
  );
}

const UserApprovalPage = ({ pendingUserList, refreshCustomUserPerm }) => {

  const classes = useStyles();

  const handlePendingUser = async (userId, action) => {
    console.log('handlePendingUser', userId, action);
    if (action != 'approve' && action != 'reject') {
      console.error('Invalid action', action);
      return
    }
    const response = await pendingUserApi(
      {userId: userId}, action);
    console.log('handlePendingUser', response);
    // Refresh user lists
    refreshCustomUserPerm();
  };

  function makePendingUserList() {
    /**
     * All new accounts must be manually approved by MNES Admin staff.
     * This function generates a list of users who have registered,
     * but whose accounts are still pending (neither approved nor denied).
     */
    return (
      <List dense={false}>
        {pendingUserList.map((user) => (
          <ListItem key={user.id}>
            <Grid container direction="row" justifyContent="flex-start">
              <Grid item xs={2}>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
              </Grid>
              <Grid item xs={6}>
                <ListItemText
                  primary={user.email}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePendingUser(user.id, 'approve')}
                  className={classes.button}
                >
                  有効
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handlePendingUser(user.id, 'reject')}
                  className={classes.button}
                >
                  拒否
                </Button>
              </Grid>
            </Grid>
          </ListItem>

        ))}
      </List>
    );
  }

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        保留中のユーザを管理する
      </Typography>
      <div>
        {makePendingUserList()}
      </div>
    </div>
  );
}

export { CustomUserPermPage, UserApprovalPage };
