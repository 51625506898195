import React, { useCallback, useContext, createRef } from 'react';
import Dropzone from 'react-dropzone';

import { useContextMenu } from '../components/ContextMenu';
import { UploadContext } from '../components/UploadContext';
import { useOrgDetail } from '../../organization/api/getOrgDetail';

const ContextMenuContext = React.createContext({});

const ContextMenuProvider = props => {
  const { makeOnDrop, makeOnDropRoot } = useContext(UploadContext);
  const { data: orgDetails } = useOrgDetail();

  const [rootPermName, setRootPermName] = React.useState('');
  const [contextMenuData, setContextMenuData] = React.useState({});

  const onDrop = useCallback(
    rootPermName !== ''
      ? makeOnDropRoot(orgDetails.uuid, rootPermName)
      : makeOnDrop(contextMenuData),
    [contextMenuData, rootPermName]
  );

  const dropzoneFileRef = createRef();
  const dropzoneFolderRef = createRef();
  const openDialogFile = useCallback(() => {
    if (dropzoneFileRef.current) {
      dropzoneFileRef.current.open();
    }
  }, [dropzoneFileRef]);
  const openDialogFolder = useCallback(() => {
    if (dropzoneFolderRef.current) {
      dropzoneFolderRef.current.open();
    }
  }, [dropzoneFolderRef]);

  const [openContextMenu, ContextMenu] = useContextMenu();
  const onOpenContextMenu = useCallback(
    (data, option) => {
      setContextMenuData(data);
      if (option.rootPermName) {
        setRootPermName(option.rootPermName);
      } else {
        setRootPermName('');
      }
      // console.log('openContextMenu option', option);
      openContextMenu(data, option);
    },
    [openContextMenu]
  );
  return (
    <ContextMenuContext.Provider
      value={{
        openContextMenu: onOpenContextMenu,
      }}
    >
      <Dropzone
        onDrop={onDrop}
        ref={dropzoneFolderRef}
        noClick
        noDrag
        noKeyboard
      >
        {({ getRootProps, getInputProps }) => (
          <span {...getRootProps()} className="link-dialog">
            <input
              {...getInputProps()}
              webkitdirectory="true"
              mozdirectory="true"
            />
          </span>
        )}
      </Dropzone>
      <Dropzone onDrop={onDrop} ref={dropzoneFileRef} noClick noDrag noKeyboard>
        {({ getRootProps, getInputProps }) => (
          <span {...getRootProps()} className="link-dialog">
            <input {...getInputProps()} />
          </span>
        )}
      </Dropzone>
      <ContextMenu
        openDialogFile={openDialogFile}
        openDialogFolder={openDialogFolder}
      />
      {props.children}
    </ContextMenuContext.Provider>
  );
};

export { ContextMenuContext, ContextMenuProvider };
