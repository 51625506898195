// notificationは2022/11/8時点では不使用

import { v4 as uuidv4 } from 'uuid';

`*
// notificationsに格納されるobjectの型
export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: string;
};
*`;

const defaultState = {
  notifications: [],
};

const notificationsManeger = (state = defaultState, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return Object.assign({}, state, {
        notifications: [
          ...state.notifications,
          { id: uuidv4(), ...action.state },
        ],
      });
    case 'DISSMISS_NOTIFICATION':
      return Object.assign({}, state, {
        notifications: state.notifications.filter(
          notification => notification.id !== action.state.id
        ),
      });
    default:
      return state;
  }
};

export default notificationsManeger;
