import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import {
  getTagsInfo,
  postFolderTag,
  deleteFolderTag,
  postFileTag,
  deleteFileTag,
} from '../../../../api/rest';
import { checkCanComment } from '../../../../utils/checkAccessPerm';
import { TagsButton } from './TagsButton';
import SelectedTagDialog from './SelectedTagDialog';
import {
  useOrgTagList,
  useOrgTagListInvalidate,
} from '../../../organization/api/getOrgTagList';
import { useFetchUpdateInfo } from '../../api/fetchUpdateInfo';

const useStyles = makeStyles(theme => ({
  root: {},
  tagsAction: {
    minHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  typography: {
    padding: theme.spacing(1),
  },
}));

// selectに表示させるために、label,valueのキーを増やす
const addLabelToObj = Tags => {
  return Tags.map(tag => {
    tag['label'] = tag.name;
    tag['value'] = tag.name;
    return tag;
  });
};

export function TagsSelect({ data }) {
  const classes = useStyles();
  const type = data.object_type;
  const { data: orgTags } = useOrgTagList();
  const invalidateOrgTags = useOrgTagListInvalidate();
  const fetchUpdateInfo = useFetchUpdateInfo();

  //権限チェック
  const canEditSelected = checkCanComment(data.perm);

  // タグデータ管理
  const [tagsList, setTagsList] = React.useState(addLabelToObj(data.tags));
  const [allTagsList, setAllTagsList] = React.useState([]);

  // タグボタン
  const [openSelectTag, setOpenSelectTag] = React.useState(false);
  const [dataInfo, setDataInfo] = React.useState({});
  const handleOnClickButton = tagUUID => async e => {
    e.preventDefault();
    e.stopPropagation();
    await getTagsInfo(tagUUID).then(data => {
      setDataInfo(data);
    });
    setOpenSelectTag(open => true);
  };

  // タグのセレクトコンポーネント管理
  // タグを編集をしたときに、tagslistを更新する
  // 編集を終えたときに、通信を行う
  const [openTags, setOpentags] = React.useState(false);
  const handleOpenTags = () => setOpentags(open => true);

  // タグをセットする
  const handleOnChangeTag = newList => {
    if (newList !== null) {
      setTagsList(newList);
    } else {
      setTagsList([]);
    }
  };

  // 通信を行う
  const handleCloseTags = () => {
    editTags(tagsList, type);
    setOpentags(open => false);
  };

  // ポップオーバー管理
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  // postするタグ、deleteするタグをリストで作成
  const getPostAndDeleteList = (prevList, newList) => {
    // 編集前と編集後のタグを結合
    const tmpList = newList ? [...prevList, ...newList] : [...prevList];
    // 編集前になくて、編集後にあるタグを取り出す
    const postTags = tmpList.filter((val, index, self) => {
      return (
        self.indexOf(val) === self.lastIndexOf(val) && index >= prevList.length
      );
    });
    // 編集前にあって、編集後にないタグを取り出す
    const deleteTags = tmpList.filter((val, index, self) => {
      return (
        self.indexOf(val) === self.lastIndexOf(val) && index < prevList.length
      );
    });
    return [postTags, deleteTags];
  };

  // タグが編集された時の通信
  const editTags = async (newList, type) => {
    const [postTags, deleteTags] = getPostAndDeleteList(
      addLabelToObj(data.tags),
      newList
    );
    if (type == 'folder') {
      for (let i = 0; i < postTags.length; i++) {
        await postFolderTag(data.uuid, postTags[i].value);
      }
      for (let i = 0; i < deleteTags.length; i++) {
        await deleteFolderTag(data.uuid, deleteTags[i].uuid);
      }
    } else if (type == 'file') {
      for (let i = 0; i < postTags.length; i++) {
        await postFileTag(data.uuid, postTags[i].value);
      }
      for (let i = 0; i < deleteTags.length; i++) {
        await deleteFileTag(data.uuid, deleteTags[i].uuid);
      }
    } else {
      console.log('type not found');
    }
    // リロード
    if (postTags.length + deleteTags.length > 0) {
      await invalidateOrgTags();
      await fetchUpdateInfo();
    }
  };

  useEffect(() => {
    setTagsList(addLabelToObj(data.tags));
  }, [data, data.tags]);

  useEffect(() => {
    setAllTagsList(addLabelToObj(orgTags));
  }, [orgTags]);

  const customStyles = {
    valueContainer: base => ({
      ...base,
      lineHeight: 'normal',
      height: '100%',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    /*
    container: base => ({
      ...base,
      height: '100%',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        maxHeight: '100%',
      };
    },
    */
  };
  return (
    <>
      {openTags ? ( // openTags
        <div onDoubleClick={e => e.stopPropagation()} className={classes.root}>
          <CreatableSelect
            value={tagsList}
            title={'タグセレクト'}
            autoFocus
            isMulti
            name="colors"
            options={allTagsList}
            styles={customStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            onBlur={handleCloseTags}
            onChange={handleOnChangeTag}
            isDisabled={!canEditSelected}
            isClearable={false}
            menuPortalTarget={document.body}
          />
        </div>
      ) : (
        <>
          <Box
            onClick={canEditSelected ? handleOpenTags : handleOpenPopover}
            className={classes.tagsAction}
          >
            {tagsList.length ? (
              tagsList.map((tag, index) => {
                return (
                  <TagsButton
                    tag={tag.label}
                    uuid={tag.uuid}
                    key={index}
                    onClick={handleOnClickButton(tag.uuid)}
                  />
                );
              })
            ) : (
              <Typography style={{ color: '#9e9e9e' }}> &nbsp; </Typography>
            )}
          </Box>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography className={classes.typography}>
              コメント権限がありません
            </Typography>
          </Popover>
        </>
      )}
      {Object.keys(dataInfo).length > 0 && (
        <SelectedTagDialog
          dataInfo={dataInfo}
          open={openSelectTag}
          setOpen={setOpenSelectTag}
        />
      )}
    </>
  );
}

TagsSelect.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    tags: PropTypes.array,
    parm: PropTypes.string,
    object_type: PropTypes.string,
  }),
};
