import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const TERMS_OF_SERVICE = "https://docs.google.com/document/d/e/2PACX-1vSu7WGkLFfOd-fSeQzRsWGT7PYmNMXhtg2vgjZB4iZGljBCYRjlFhv6NY3DIhoPybCY0af9QslNwPX1/pub";


const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: props => (props.size == 'large' ? '50vh' : '50vh'),
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

export default function TermsOfServiceDocument(props) {
  // size で長さを調整可能に
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <iframe
        className={classes.iframe}
        title="s9利用規約"
        src={TERMS_OF_SERVICE}
      ></iframe>
    </div>
  );
}

export { TERMS_OF_SERVICE };
