import './MeasurementTable.styl';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Grid from '@material-ui/core/Grid';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';

import { withTranslation } from '../../contextProviders';
import { UserAvatar } from './../avatar';
import { MeasurementTableItem } from './MeasurementTableItem.js';
import { ScrollableArea } from './../../ScrollableArea/ScrollableArea.js';
import { TableList } from './../tableList';
import LayoutSync from './LayoutSync';

const useStyles = makeStyles(theme => ({
  button: {},
  disabledButton: {
    color: 'gray',
  },
}));

const SyncLayoutButton = ({
  sender,
  canComment,
  startViewingSend,
  stopViewingSend,
}) => {
  const classes = useStyles();

  if (Object.keys(sender).length > 0) {
    return (
      <MuiTooltip
        title={canComment ? '画面同期を終了' : '読み取り権限では実行できません'}
      >
        <div>
          {canComment ? (
            <IconButton color="primary" onClick={stopViewingSend}>
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton>
              <StopIcon
                className={clsx(classes.button, {
                  [classes.disabledButton]: !canComment,
                })}
              />
            </IconButton>
          )}
        </div>
      </MuiTooltip>
    );
  } else {
    return (
      <MuiTooltip
        title={canComment ? '画面同期を開始' : '読み取り権限では実行できません'}
      >
        <div>
          {canComment ? (
            <IconButton color="primary" onClick={startViewingSend}>
              <PlayArrowIcon />
            </IconButton>
          ) : (
            <IconButton>
              <PlayArrowIcon
                className={clsx(classes.button, {
                  [classes.disabledButton]: !canComment,
                })}
              />
            </IconButton>
          )}
        </div>
      </MuiTooltip>
    );
  }
};

class MeasurementTable extends Component {
  static propTypes = {
    measurementApi: PropTypes.object,
    measurementCollection: PropTypes.array.isRequired,
    timepoints: PropTypes.array.isRequired,
    overallWarnings: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    onItemClick: PropTypes.func,
    onRelabelClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onEditDescriptionClick: PropTypes.func,
    selectedMeasurementNumber: PropTypes.number,
    t: PropTypes.func,
    saveFunction: PropTypes.func,
    onSaveComplete: PropTypes.func,
    viewports: PropTypes.object.isRequired,
    viewingUserManager: PropTypes.object.isRequired,
  };

  static defaultProps = {
    overallWarnings: {
      warningList: [],
    },
    readOnly: false,
  };

  state = {
    selectedKey: null,
    messageInputs: {},
  };

  componentDidUpdate(prevProps) {
    /* 画像レンダリング時のイベント発火により更新するように変更したので不要に
    if (this.props.measurementApi) {
      // 共有中のユーザーが存在 かつ それが自分
      if (
        Object.keys(this.props.viewingUserManager.sender).length > 0 &&
        this.props.viewingUserManager.sender.is_sender
      ) {
        // 変更がある場合にのみ送信
        const prevJSON = JSON.stringify(prevProps.viewports);
        const newJSON = JSON.stringify(this.props.viewports);
        if (prevJSON !== newJSON) {
          this.props.measurementApi.sendViewportState(this.props.viewports);
        }
      }
    }
    */
  }

  startViewingSend = () => {
    if (this.props.measurementApi) {
      this.props.measurementApi.startViewingSend(
        this.props.viewingUserManager,
        this.props.viewports
      );
    }
  };
  stopViewingSend = () => {
    if (this.props.measurementApi) {
      this.props.measurementApi.stopViewingSend(
        this.props.viewingUserManager,
        this.props.viewports
      );
    }
  };

  render() {
    const {
      measurementApi,
      viewingUserManager,
      //overallWarnings,
      //saveFunction,
      //t,
    } = this.props;
    //    const hasOverallWarnings = overallWarnings.warningList.length > 0;

    return (
      <div className="measurementTable">
        {/* 共有中ユーザー一覧、画面共有ボタン */}
        <LayoutSync
          measurementApi={measurementApi}
          viewports={this.props.viewports}
          viewingUserManager={this.props.viewingUserManager}
        />
        {/* {measurementApi ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p>レイアウト 同期 / 閲覧ユーザー</p>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <SyncLayoutButton
                    sender={viewingUserManager.sender}
                    canComment={measurementApi.canComment}
                    startViewingSend={this.startViewingSend}
                    stopViewingSend={this.stopViewingSend}
                  />
                </Grid>
                {Object.keys(viewingUserManager.sender).length > 0 && (
                  <Grid item>
                    <UserAvatar
                      user={viewingUserManager.sender}
                      isSender={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              {Array.isArray(viewingUserManager.users) && (
                <AvatarGroup max={4}>
                  {viewingUserManager.users.map((user, index) => {
                    if (viewingUserManager.sender.uuid !== user.uuid) {
                      return <UserAvatar user={user} key={index} />;
                    }
                  })}
                </AvatarGroup>
              )}
            </Grid>
            {!measurementApi.canComment && (
              <Grid item xs={12}>
                <p>読み取り権限はアノテーションの保存はできません</p>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p>プライベートモード</p>
            </Grid>
          </Grid>
        )} */}

        {/*
        <div className="measurementTableHeader">
          {hasOverallWarnings && (
            <OverlayTrigger
              key={'overwall-warning'}
              placement="left"
              overlay={
                <Tooltip
                  placement="left"
                  className="in tooltip-warning"
                  id="tooltip-left"
                  style={{}}
                >
                  <div className="warningTitle">
                    {t('Criteria nonconformities')}
                  </div>
                  <div className="warningContent">
                    {this.getWarningContent()}
                  </div>
                </Tooltip>
              }
            >
              <span className="warning-status">
                <span className="warning-border">
                  <Icon name="exclamation-triangle" />
                </span>
              </span>
            </OverlayTrigger>
          )}
          {this.getTimepointsHeader()}
        </div>
        */}
        <ScrollableArea>
          <div>{this.getMeasurementsGroups()}</div>
        </ScrollableArea>
        {/*
        <div className="measurementTableFooter">
          {saveFunction && (
            <button
              onClick={this.saveFunction}
              className="saveBtn"
              data-cy="save-measurements-btn"
            >
              <Icon name="save" width="14px" height="14px" />
              Save measurements
            </button>
          )}
        </div>
          */}
      </div>
    );
  }

  saveFunction = async event => {
    const { saveFunction, onSaveComplete } = this.props;
    if (saveFunction) {
      try {
        const result = await saveFunction();
        if (onSaveComplete) {
          onSaveComplete({
            title: 'STOW SR',
            message: result.message,
            type: 'success',
          });
        }
      } catch (error) {
        if (onSaveComplete) {
          onSaveComplete({
            title: 'STOW SR',
            message: error.message,
            type: 'error',
          });
        }
      }
    }
  };

  getMeasurementsGroups = () => {
    return this.props.measurementCollection.map((measureGroup, index) => {
      return (
        <TableList
          key={index}
          customHeader={this.getCustomHeader(measureGroup)}
        >
          {this.getMeasurements(measureGroup)}
        </TableList>
      );
    });
  };

  getMeasurements = measureGroup => {
    const selectedKey = this.props.selectedMeasurementNumber
      ? this.props.selectedMeasurementNumber
      : this.state.selectedKey;
    return measureGroup.measurements.map((measurement, index) => {
      const key = measurement.uuid;
      const itemIndex = measurement.itemNumber || index + 1;
      const itemClass =
        selectedKey === key && !this.props.readOnly ? 'selected' : '';

      return (
        <MeasurementTableItem
          key={key}
          itemIndex={itemIndex}
          itemClass={itemClass}
          measurementData={measurement}
          onItemClick={this.onItemClick}
          onRelabel={this.props.onRelabelClick}
          onDelete={this.props.onDeleteClick}
          onEditDescription={this.props.onEditDescriptionClick}
          onItemSideClick={this.onItemSideClick}
          messageInput={
            key in this.state.messageInputs ? this.state.messageInputs[key] : ''
          }
          messageInputChange={this.messageInputChange}
        />
      );
    });
  };

  onItemClick = (event, measurementData) => {
    if (this.props.readOnly) return;
    this.setState({
      selectedKey: measurementData.uuid,
    });

    if (this.props.onItemClick) {
      this.props.onItemClick(event, measurementData);
    }
  };

  onItemSideClick = (event, measurementData) => {
    if (this.props.readOnly) return;
    if (this.state.selectedKey === measurementData.uuid) {
      // 閉じる時
      this.setState({
        selectedKey: '',
      });
    } else {
      // 開く時
      this.setState({
        selectedKey: measurementData.uuid,
      });
      if (this.props.onItemClick) {
        this.props.onItemClick(event, measurementData);
      }
    }
  };

  messageInputChange = (uuid, text) => {
    // messageInput の uuid をkeyとして、それに対応する message を更新する
    const { messageInputs } = this.state;
    const newMessageInputs = { ...messageInputs };
    newMessageInputs[uuid] = text;
    this.setState({
      messageInputs: newMessageInputs,
    });
    console.log(uuid, text);
  };

  getCustomHeader = measureGroup => {
    return (
      <React.Fragment>
        <div className="tableListHeaderTitle">
          {this.props.t(measureGroup.groupName)}
        </div>
        {measureGroup.maxMeasurements && (
          <div className="maxMeasurements">
            {this.props.t('MAX')} {measureGroup.maxMeasurements}
          </div>
        )}
        <div className="numberOfItems">{measureGroup.measurements.length}</div>
      </React.Fragment>
    );
  };

  getTimepointsHeader = () => {
    const { timepoints, t } = this.props;
    return timepoints.map((timepoint, index) => {
      return (
        <div key={index} className="measurementTableHeaderItem">
          <div className="timepointLabel">{t(timepoint.key)}</div>
          <div className="timepointDate">{timepoint.date}</div>
        </div>
      );
    });
  };

  getWarningContent = () => {
    const { warningList = '' } = this.props.overallWarnings;

    if (Array.isArray(warningList)) {
      const listedWarnings = warningList.map((warn, index) => {
        return <li key={index}>{warn}</li>;
      });

      return <ol>{listedWarnings}</ol>;
    } else {
      return <React.Fragment>{warningList}</React.Fragment>;
    }
  };
}

const connectedComponent = withTranslation(['MeasurementTable', 'Common'])(
  MeasurementTable
);
export { connectedComponent as MeasurementTable };
export default connectedComponent;
