/*
ダウンロードの参考
Viewers/extensions/cornerstone/src/toolbarModule.js
Viewers/platform/viewer/src/connectedComponents/ToolbarRow.js
Viewers/extensions/cornerstone/src/commandsModule.js
Viewers/platform/ui/src/components/content/viewportDownloadForm/ViewportDownloadForm.js

*/
const cornerstoneViewportCopyClip = activeViewport => {
  const downloadBlob = activeViewport => {
    activeViewport.querySelector('canvas').toBlob(blob => {
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
    });
  };

  downloadBlob(activeViewport);
};

export default cornerstoneViewportCopyClip;
