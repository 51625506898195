import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  circular: {
    margin: theme.spacing(5),
  },
}));
const Loading = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          {/* <Loader type="Oval" color="#00BFFF" height={20} width={20} /> */}
          <CircularProgress disableShrink className={classes.circular} />
        </Grid>
      </Grid>
    </>
  );
};

Loading.propTypes = {};

export default Loading;
