import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import lookrec_logo from '../../../../../../viewer/public/assets/lookrec_logo.png';
import lookrec_logo from '../../../../../../viewer/public/assets/lookrec-CE-full-text.png';

const CONFERENCE_APP_TITLE = 'LOOKREC Conference Edition';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    display: 'flex', // Ensure items are displayed in a flex container
    alignItems: 'center', // Align items vertically
    color: theme.palette.primary.main,
    marginLeft: `2rem`,
  },
  logo: {
    marginRight: '1rem', // Add margin to separate logo from title
    height: '3rem', // Adjust the height of the logo as needed
    display: 'block',
  },
}));

export default function ConferenceTitle() {
  const classes = useStyles();

  /**
   * Conference title was remove below, was previously on the
   * same line, immediately after <img> (but now only in the logo)
   * { CONFERENCE_APP_TITLE }
   */
  return (
    <Typography variant="h6" className={classes.title} noWrap>
      <img src={lookrec_logo} alt="Logo" className={classes.logo} />
    </Typography>
  );
}

export { CONFERENCE_APP_TITLE };
