export default class FileLoader {
  fileType;
  loadFile(file, imageId) {}
  getDataset(
    image,
    imageId,
    anonymizedStudies = [],
    isEdit = false,
    isAnonymize = false
  ) {}
  getStudies(dataset, imageId) {}
}
