/**
 * A group of thumbnails corresponding to a single study.
 * Each study contains exactly one thumbnail group.
 * Each thumbnail group may contain 1 or more series.
 * Each series contains one or more instances (slices)
 * and each series is represented by 1 thumbnail
 *
 */

import React from 'react';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';

function ThumbnailGroup(props) {
  const {
    studyMetadata,
    studyIndex,
    thumbnails,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
  } = props;

  return (
    // <div>{studyMetadata.PatientName}</div>

    thumbnails.map((thumb, thumbIndex) => {
      // TODO: Thumb has more props than we care about?
      const {
        active,
        altImageText,
        displaySetInstanceUID,
        imageId,
        derivedDisplaySetsNumber,
        numImageFrames,
        SeriesDescription,
        SeriesNumber,
        hasWarnings,
        hasDerivedDisplaySets,
      } = thumb;

      console.debug('Series', thumb);

      return (

        <div
          key={thumb.displaySetInstanceUID}
          className="thumbnail-container"
          data-cy="thumbnail-list"
        >
          {/* <div>Series {thumbIndex}.</div> */}
          <Thumbnail
            active={active}
            supportsDrag={supportsDrag}
            key={`${studyIndex}_${thumbIndex}`}
            id={`${studyIndex}_${thumbIndex}`} // Unused?
            // Study
            StudyInstanceUID={studyMetadata.StudyInstanceUID} // used by drop
            // Thumb
            altImageText={altImageText}
            imageId={imageId}
            derivedDisplaySetsNumber={derivedDisplaySetsNumber}
            displaySetInstanceUID={displaySetInstanceUID} // used by drop
            numImageFrames={numImageFrames}
            SeriesDescription={SeriesDescription}
            SeriesNumber={SeriesNumber}
            hasWarnings={hasWarnings}
            hasDerivedDisplaySets={hasDerivedDisplaySets}
            // Events
            onClick={onThumbnailClick.bind(
              undefined,
              displaySetInstanceUID
            )}
            onDoubleClick={onThumbnailDoubleClick}
            showProgressBar={showThumbnailProgressBar}
          />
        </div>
      );
      })

  )
}

export { ThumbnailGroup };
