import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  studyData: {},
  anonymizedStudyData: [],
  anonymizedRadio: { isEdit: false, isAnonymize: false },
};

const servers = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STUDY_DATA': {
      const updatedStudyData = cloneDeep(state.studyData);
      updatedStudyData[action.StudyInstanceUID] = cloneDeep(action.data);

      return Object.assign({}, state, { studyData: updatedStudyData });
    }
    case 'SET_ANONYMIZED_STUDY_DATA': {
      return Object.assign({}, state, { anonymizedStudyData: action.data });
    }
    case 'SET_ANONYMIZED_RADIO': {
      return Object.assign({}, state, { anonymizedRadio: action.state });
    }
    default:
      return state;
  }
};

export default servers;
