import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { setOrgLastUrl, getOrgLastUrl, getOrgIdFromUrl } from '../../../../utils/orgLastUrl';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { getOrgList, createOrg } from '../../../../api/rest';
import { getUserInfo, userLoggedIn, logoutUser } from '../../../../api/auth';

import { useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  Typography, Button, Link, Breadcrumbs,
  TextField, Switch, FormControlLabel, Toolbar
} from '@material-ui/core';

import {
  createConference, getConferenceList, editConference
} from '../../../../api/rest';
import ConferenceHeader from './ConferenceHeader';
import { CONFERENCE_APP_TITLE } from './ConferenceTitle';
import { AccessDenied } from '../../../../../../ui/src/components/errorBoundary/AccessDenied';
import { UserContext } from '../../../../providers/UserContext';


const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '10px',
    // margin: '0.4rem',
    margin: theme.spacing(1),
  },
  conferenceContainer: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  conferenceListText: {
    margin: theme.spacing(2),
    // marginLeft: '16px',
  },
  conferenceOrgHeader: {
    marginBottom: theme.spacing(2),
  },
  flexLeft: {
    flex: 1,
  },
  breadcrumb: {
    marginLeft: theme.spacing(2),
  },

}));

export function checkConferenceUser() {
  // Set the page title when the component mounts
  document.title = CONFERENCE_APP_TITLE;

  // Check if user is logged in, if not redirect to login page
  async function checkUser() {
    const isLoggedIn = await userLoggedIn();
    if (!isLoggedIn) {
      console.log('No user at Conference page, redirect to login...');
      logoutUser().then();
      window.location.href = `/login?continueUrl=${location.pathname}`;
    }
  }
  checkUser();
}

/**
 * Top-level component to display the list of Conference events in an Organization.
 */
const ConferenceList = () => {
  const classes = useStyles();
  const history = useHistory();

  // useParams, useContext, and other hooks, must be used inside a component.
  // Not at module level!
  const { initialOrgId } = useParams();
  const location = useLocation();
  const { data: orgDetails } = useOrgDetail();
  if (orgDetails && orgDetails.name !== undefined) {
    console.log('ConferenceList for Org', orgDetails.name, 'location', location);
  }
  const { user, loginStatus, isUserLoggedIn } = useContext(UserContext);

  // URLs for Breadcrumbs component
  const conferenceListUrl = `/conference/org/${orgDetails.uuid}`;
  const conferenceNewUrl = `${conferenceListUrl}/new`;
  const [conferenceList, setConferenceList] = useState([]);
  const [conferenceListLabel, setConferenceListLabel] = useState('学会データ読み込み中');

  // Flag for a new conference (this org has never created a conference)
  // TODO: is this even needed? Can we just check length of conferenceList?
  const [newConf, setNewConf ] = useState(false);
  // Initialize to null so that nothing is displayed until API call completes
  const [accessDenied, setAccessDenied] = useState(null);

  // this useEffect runs once, when component mounts
  useEffect(() => {
    checkConferenceUser();
    if (initialOrgId) {
      // Set last org URL, if current org is set
      setOrgLastUrl();
    }
  }, []);

  // useEffect runs when user login status has changed
  useEffect(() => {

    const setConferenceOrg = async () => {
      /**
       * Adapted from StoragePrev.js:.
       * This is used when a user accesses a URL /conference without the
       * /org defined in the URL. In this case, we must decide which Org should
       * be displayed. There are several possibilities:
       *
       * If user has newly registered and has no orgs, create their "home" org,
       * and jump to that org right away.
       *
       * Otherwise, if there is no orgLastUrl, and no continueUrl, get the full
       * list of orgs that the user has access to, and jump to first one on the list.
       *
       * BETTER OPTION: show a Home page with a list of orgs?
       */

      if (loginStatus === 'logout') {
        // ログインしていなければログインページにジャンプ
        // Logout user and go to login page for Conference
        console.log('No user at Conference page, logging out...');
        await logoutUser();
        console.log('Redirect to Conference login...');
        history.push(`/login?continueUrl=${location.pathname}`);
      }
      else if (loginStatus === 'login') {
        // ログインしている場合はorgに所属しているか確認
        const response = await getOrgList();
        const orgList = response.data;
        if (orgList.length > 0) {
          // Redirect to last org if it was defined in org_last_url
          const org_last_url = await getOrgLastUrl();
          if (org_last_url !== null) {
            /**
             * org_last_url is not null if user has access to this org
             *
             * If the URL starts with /conference, then org_last_url was a CE
             * page, and we can go directly to that URL.
             *
             * Otherwise, get the orgId from the URL and redirect to the
             * appropriate CE page.
             *
             * For Conference Edition, a user requires Admin access to an org,
             * not just org member. In a rare case where a user has org_last_url
             * set to an org from S9 File manager where they are only a member,
             * this will show Access Denied in CE.
             *
             * This is OK since 3rd party users of a CE event need to have admin
             * rights anyway (will be adding, removing, renaming events,
             * uploading data).
             */
            if (org_last_url.startsWith('/conference')) {
              // Redirect to last URL if it is a conference URL
              console.log('USING LAST Conference URL', org_last_url);
              history.push(org_last_url);
            } else {
              const orgId = getOrgIdFromUrl(org_last_url);
              // Build the CE page URL.
              const confUrl = `/conference/org/${orgId}`;
              console.log('Conference URL', confUrl, 'from org_last_url', org_last_url);
              history.push(confUrl);
            }

          // if (org_last_url !== null
          //     && org_last_url !== undefined
          //     && org_last_url !== '/conference'
          //     && org_last_url !== '/conference/'
          //     && org_last_url.startsWith('/conference')
          //   ) {
          //     // Only redirect to last URL if it is a conference URL
          //     console.log('USING LAST URL', org_last_url);
          //     history.push(org_last_url);
          } else {
            /**
             * TODO: Add homepage here (not yet implemented)
             * TODO: THIS BEHAVIOR IS NOT USER FRIENDLY.
             * Check if orgList[0] = S9_test_Organization1
             * For now, redirect to org at index 0. It is not obvious to the
             * user how this is being done, or why.
             */
            //
            const org0Name = orgList[0].name;
            console.log('No org defined, jump to', org0Name);
            history.push(`/conference/org/${orgList[0].uuid}`);
          }
        } else {
          // 所属しているorgがないなら作成してジャンプ
          const username = user.username;
          const orgName =
            username !== ''
              ? `${username}の組織`
              : `${user.email.split('@')[0]}の組織`;
          const response = await createOrg(orgName);
          const org = response.data;
          // Go to conference page for this org
          history.push(`/conference/org/${org.uuid}`);
        }
      }
    }

    // Set the org if not yet defined in URL
    const path = window.location.pathname;
    if (path == '/conference' || path == '/conference/') {
      setConferenceOrg();
    }

  }, [loginStatus, user]);

  // useEffect runs when the org has been set / changed
  useEffect(() => {

    // Get all existing Conferences for this org, only if user is logged in
    const getConferences = async () => {
      if (initialOrgId && isUserLoggedIn){
        try {
          const conferenceData = await getConferenceList(initialOrgId);
          console.debug('conferenceData', conferenceData);
          if (conferenceData.length === 0) {
            console.warn('No conference found!');
            // Show the no conference message
            setNewConf(true);
            setConferenceListLabel('この組織はまだ学会が作成されていません。');
            setAccessDenied(false);
          } else {
            // Set conference details from backend data
            setConferenceList(conferenceData);
            setConferenceListLabel('この組織が以下の学会イベントを管理しています。');
            setAccessDenied(false);
          }
          setOrgLastUrl();
        } catch (error) {
          const statusCode = error.response.status;
          console.error('ERROR conferenceData', statusCode);
          // Check if the status code is in the 40x or 50x range
          if (statusCode >= 400 && statusCode < 600) {
            // Show access denied page
            setAccessDenied(true);
            if (statusCode == 403 || statusCode == 401){
              // TODO: AccessDenied should really only be these two error codes.
              // for other codes, display some status message.
            }
          }
        }
      }
    }
    getConferences();
  }, [initialOrgId, isUserLoggedIn, orgDetails]);

  const createNewConference = () => {
    console.log('conferenceNewUrl', conferenceNewUrl);
    history.push(conferenceNewUrl);
  }

  return (
    <div>
      <ConferenceHeader/>
        {/* Render Conference details if access is allowed */}
        {accessDenied === null ? null : accessDenied ? (
          <AccessDenied />
        ) : (
          <div className={classes.conferenceContainer}>
            <div className={classes.flexLeft}>
              {/* Breadcrumbs component at top of Conference List */}
              <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Link color="inherit" href={conferenceListUrl}>
                  {orgDetails.name}
                </Link>
              </Breadcrumbs>

              <div className={classes.conferenceListText}>
                  {/* Iterate conference list and show a link to each conference */}
                  <Typography>
                    <Typography>{conferenceListLabel}</Typography>
                    {
                      conferenceList.map((conference, index) => (
                        <Typography key={index}>
                          {/* Link to conference detail at event URL */}
                          <Link href={'/conference/org/' + initialOrgId + '/event/' + conference.uuid}>
                            {conference.name}
                          </Link>
                        </Typography>
                      ))
                    }
                  </Typography>
                {/* End of Conference list */}
              </div>
            </div>

            {/* Add Conference button */}
            <Button
              variant="contained"
              color="primary"
              onClick={createNewConference}
              className={classes.button}
            >
              新規の学会を作成
            </Button>
          </div>
        )}
    </div>
  );
}

export default ConferenceList;
