export function DateExtract(date, detail = false, locales = 'ja-JP') {
  try {
    let dateObj = new Date(date);
    if (detail) {
      return dateObj.toLocaleString(locales);
    } else {
      return dateObj.toLocaleDateString(locales);
    }
  } catch {
    return '';
  }
}

export function bite2XB(bite, fixed = 1) {
  if (bite < 1e3) {
    return bite + 'B';
  } else if (bite < 1e6) {
    return (bite / 1e3).toFixed(fixed) + 'KB';
  } else if (bite < 1e9) {
    return (bite / 1e6).toFixed(fixed) + 'MB';
  } else if (bite < 1e12) {
    return (bite / 1e9).toFixed(fixed) + 'GB';
  } else if (bite < 1e15) {
    return (bite / 1e12).toFixed(fixed) + 'TB';
  } else {
    return bite;
  }
}

export function getDataType(data) {
  if (data) {
    if (data.object_type === 'folder') {
      return data.foldertype;
    } else if (data.object_type === 'file') {
      return data.filetype;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function filetypeExtract(filetype) {
  if (typeof filetype === 'string' && filetype.indexOf('/') > -1) {
    return filetype.split('/')[1];
  }
  return filetype;
}
