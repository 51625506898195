import './OHIFLogo.css';
import React from 'react';

import Typography from '@material-ui/core/Typography';

function OHIFLogo() {
  return (
    <a
      //target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="/"
    >
      {/* <Icon name="ohif-logo" className="header-logo-image" /> */}
      {/* Logo text would fit smaller displays at two lines:
       *
       * Open Health
       * Imaging Foundation
       *
       * Or as `OHIF` on really small displays
       */}
      {/* <Icon name="ohif-text-logo" className="header-logo-text" /> */}
      <Typography>S9</Typography>
    </a>
  );
}

export default OHIFLogo;
