import React from "react";
import { useState, useEffect } from "react";

import {
  Button, Modal, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, FormControlLabel, Typography, Box, Grid, Checkbox,
  TextField,
  RadioGroup, Radio
} from '@material-ui/core';
import { SnackbarContext } from "../../context/SnackbarContext";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(2),
  },
  anonContents: {
    position: 'relative',
    // width: 600,
    width: '80vw', // Make modal wider, 80% of viewport width
    maxHeight: '90vh', // Limit modal height to 90% of the viewport height
    overflow: 'auto', // Ensure modal content can scroll
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    borderRadius: '6px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
    overflowY: 'auto', // Enable vertical scrolling within the modal content
  },
  button: {
    margin: theme.spacing(2),
  },
  paragraph: {
    paddingTop: theme.spacing(2),
  }
}));


const AnonymizeSimple = ({open, handleAnonOptions, handleAnonClose}) => {

  const classes = useStyles();

  // Anon options that will be passed to uploader
  const defaultAnonState = {
    StudyID: { checked: true, value: '' },
    StudyDate: { checked: true, value: '' },
    StudyDescription: { checked: true, value: '' },
    PatientName: { checked: true, value: '' },
    PatientBirthDate: { checked: true, value: '' },
    PatientAge: { checked: true, value: '' },
    PatientSex: { checked: true, value: '' },
    PatientSize: { checked: true, value: '' },
    PatientWeight: { checked: true, value: '' },
    InstitutionName: { checked: true, value: '' },
    InstitutionalDepartmentName: { checked: true, value: '' },
    ReferringPhysicianName: { checked: true, value: '' },
    PerformingPhysicianName: { checked: true, value: '' }
  };

  const [anonMaster, setAnonMaster] = useState(true);

  // Initial state for all properties with checkboxes set to true and empty text fields
  const [properties, setProperties] = useState(defaultAnonState);

  // Function to handle checkbox toggling
  const handleCheckboxChange = (property) => (event) => {
    setProperties({
      ...properties,
      [property]: {
        ...properties[property],
        checked: event.target.checked,
      },
    });
  };

  // Handle anon master checkbox
  const handleAnonMasterChange = (event) => {
    const isChecked = event.target.checked;
    setAnonMaster(isChecked);
    if (!isChecked) {
      // If master checkbox is unchecked, reset the properties to empty
      setProperties({});
    } else {
      setProperties(defaultAnonState);
    }
  }

  // Function to handle text field changes
  const handleTextFieldChange = (property) => (event) => {
    setProperties({
      ...properties,
      [property]: {
        ...properties[property],
        value: event.target.value,
      },
    });
  };

  const handleStartUpload = () => {
    // Return anon options to the parent component
    handleAnonOptions(properties);
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleAnonClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >

    <div className={classes.anonContents}>
    <DialogTitle>匿名化の設定</DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <FormControl component="fieldset">
        <Typography>
          以下のように、DICOMの匿名化を行います。デフォルトで、全ての個人情報などがある項目は自動で匿名化されます。匿名化したくない項目のチェエクをほずすとそのままにします。何も匿名化しない場合、「匿名化する」のチェックを外してください。
        </Typography>
        <Typography className={classes.paragraph}>
          ※空白のままではなく、各フィールドに値を入力して指定することが可能です。
        </Typography>
        <Typography className={classes.paragraph}>
          ※日付のあるフィールドについては、元の日付からのオフセットを日単位で指定することができる。 例えば、元の日付より1年早くオフセットするには、「-365 」と入力する。
        </Typography>

        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid key="0" item xs={12} container alignItems="center" spacing={2}>
              {/* Master anon setting */}
              <FormControlLabel
                className={classes.paragraph}
                label="匿名化する"
                spacing={2}
                control={
                  <Checkbox
                    checked={anonMaster}
                    onChange={handleAnonMasterChange}
                    size="small"
                  />
                }
              />
            </Grid>

            {Object.keys(properties).map((property, index) => (
              <Grid key={index} item xs={12} container alignItems="center" spacing={2}>
                {/* Checkbox with label */}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={properties[property].checked}
                        onChange={handleCheckboxChange(property)}
                        size="small"
                      />
                    }
                    label={property}
                  />
                </Grid>

                {/* Text field */}
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={!properties[property].checked}
                    value={properties[property].value}
                    onChange={handleTextFieldChange(property)}
                    placeholder={`Enter ${property} value`}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* For Debugging
            <pre>{JSON.stringify(properties, null, 2)}</pre>
          */}
        </Box>

        {/* Submit and close buttons */}
        <DialogActions>
          <Button
            onClick={handleStartUpload}
            color="primary"
            variant="contained">アップロードを実行</Button>
          <Button
            onClick={handleAnonClose}
            color="secondary"
            variant="contained">キャンセル</Button>
        </DialogActions>
      </FormControl>
      </DialogContent>
    {/* </Dialog> */}
    </div>
    </Modal>
  );
}

export default AnonymizeSimple;
