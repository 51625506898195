import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import ShareCard from './ShareCard';

const useStyles = makeStyles(theme => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
}));

const useShareModal = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [funcWhenCompleted, setFuncWhenCompleted] = React.useState(
    () => async () => {}
  );

  const openShareModal = ({ data, funcWhenCompleted }) => {
    setOpen(open => true);
    setData(data);
    setFuncWhenCompleted(funcWhenCompleted);
  };
  const closeShareModal = () => {
    funcWhenCompleted();
    setOpen(open => false);
  };

  function ShareModal() {
    return (
      <>
        <Dialog
          open={open}
          onClose={closeShareModal}
          className={classes.modal}
          fullWidth
          maxWidth="sm"
        >
          {Object.keys(data).length !== 0 && (
            <ShareCard
              data={data}
              funcWhenCompleted={funcWhenCompleted}
              closeShareModal={closeShareModal}
            />
          )}
        </Dialog>
      </>
    );
  }

  return [ShareModal, openShareModal];
};

export default useShareModal;
