import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { UploadCancelError } from '../../../../utils/upload';
import { useIsCheck } from '../../utils/useParams';

const useStyles = makeStyles(theme => ({
  modal: {},
}));

const RADIO_VALUES = {
  studyAndFolderReplace: 'studyAndFolderReplace',
  fileReplace: 'fileReplace',
};

// const ConfirmDuplicateForm = () => {};

const useConfirmDuplicateModal = () => {
  const classes = useStyles();

  const { isRootPage } = useIsCheck();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    resolve: () => {},
    reject: () => {},
  });

  const openConfirmDuplicateModal = state => {
    setOpen(open => true);
    setState(state);
  };

  const cancelUpload = async () => {
    await state.reject(new UploadCancelError('cancel upload'));
    setOpen(open => false);
  };

  const resolveUpload = async ret => {
    await state.resolve(ret);
    setOpen(open => false);
  };

  function ConfirmDuplicateModal() {
    const [radioValue, setRadioValue] = useState(
      RADIO_VALUES['studyAndFolderReplace']
    );
    const [duplicateOptios, setDuplicateOptions] = React.useState({
      studyReplace: true,
      folderReplace: true,
    });
    const handleRadioChange = async event => {
      setRadioValue(event.target.value);
      if (event.target.value == RADIO_VALUES['studyAndFolderReplace']) {
        setDuplicateOptions({
          studyReplace: true,
          folderReplace: true,
        });
      } else {
        setDuplicateOptions({
          studyReplace: false,
          folderReplace: false,
        });
      }
    };

    const handleCancel = async () => {
      await cancelUpload();
    };

    const handleUpload = async () => {
      await resolveUpload(duplicateOptios);
    };

    return (
      <>
        <Dialog
          open={open}
          scroll="paper"
          className={classes.modal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            アップロードオプション
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                {isRootPage()
                  ? '「プライベート・共有・組織」の直下'
                  : 'この場所'}
              </>
              にはすでに同名のアイテム、もしくは同じStudyInstanceUIDのstudyが存在します。アップロード時に置き換えても問題ないですか？
            </DialogContentText>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={RADIO_VALUES['studyAndFolderReplace']}
                  control={<Radio />}
                  label="完全に置き換え（フォルダやstudyの重複は削除してアップロード）"
                />
                <FormControlLabel
                  value={RADIO_VALUES['fileReplace']}
                  control={<Radio />}
                  label="そのままアップロード（ファイルの重複のみがあれば置き換え）"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary" id="anonymizeCansel">
              キャンセル
            </Button>
            <Button onClick={handleUpload} color="primary" id="anonymizeButton">
              アップロードする
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return [openConfirmDuplicateModal, ConfirmDuplicateModal];
};

export default useConfirmDuplicateModal;
