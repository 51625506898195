import React, { createContext, useState, useCallback, useEffect } from 'react';
import propsTypes from 'prop-types';
import { useResetStorageManeger } from '../features/storage/utils/useParams';

import { logoutUser, getUserInfo } from '../api/auth';

const UserContext = createContext(null);

const UserProvider = props => {
  const [user, setUser] = useState(null);
  const [loginStatus, setLoginStatus] = useState('loading');
  const isUserSet = useCallback(() => user !== null, [user]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserLoggedOut, setIsUserLoggedOut] = useState(false);
  const resetStorageManeger = useResetStorageManeger();

  useEffect(() => {
    if (loginStatus == 'loading') {
      setIsUserLoggedIn(false);
      setIsUserLoggedOut(false);
    } else if (loginStatus == 'login') {
      console.log('User is LOGGED IN!');
      setIsUserLoggedIn(true);
      setIsUserLoggedOut(false);
    } else if (loginStatus === 'logout') {
      setIsUserLoggedIn(false);
      setIsUserLoggedOut(true);
    }
  }, [loginStatus]);

  const logout = async () => {
    setUser({});
    resetStorageManeger();
    await logoutUser();
  };

  useEffect(() => {
    console.log('UserContext init');
    /**
     * Using async here causes warnings elsewhere about Hooks being called
     * outside a functional component. This is correct, this context hook
     * MUST be called only from a functional component.
     */
    async function userContextInit() {
      await getUserInfo()
        .then(userInfo => setUser(userInfo))
        .catch(err => {
          setUser({});
        });
    }
    userContextInit();
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (user && Object.keys(user).length > 0) {
        setLoginStatus('login');
      } else {
        setLoginStatus('logout');
      }
    } else {
      setLoginStatus('loading');
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isUserSet,
        isUserLoggedIn,
        isUserLoggedOut,
        logout,
        loginStatus,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: propsTypes.node,
};

export { UserContext, UserProvider };
