/*
共有用カードの作成
*/

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';

import SelectPerm from './SelectPerm';
import InviteUserDialog from './inviteUserDialog';
import { isMobile } from '../../utils/detectDevice';
/*
user item
 */
function SharedUserListItem({ user, changeUserPerm, isDisabled }) {
  const useStyles = makeStyles(theme => ({
    root: {
      margin: 0,
      padding: 0,
    },
    multiline: {
      margin: 0,
    },
    primary: {
      fontSize: '1.2em',
    },
    secondary: {
      fontSize: '1.1em',
    },
    listItemAction: {
      width: 200,
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: theme.palette.getContrastText(theme.palette.grey[800]),
      backgroundColor: theme.palette.grey[800],
    },
  }));
  const classes = useStyles();

  const changePerm = async perm => {
    await changeUserPerm(user, perm);
  };

  let username = user.username;
  if (username.length == 0) {
    username = user.email;
  }

  return (
    <ListItem dense disableGutters>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{username[0].toUpperCase()}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={user.username}
        secondary={user.email}
        classes={{
          root: classes.root,
          multiline: classes.multiline,
          primary: classes.primary,
          secondary: classes.secondary,
        }}
      />
      <ListItemSecondaryAction className={classes.listItemAction}>
        <div>
          <SelectPerm
            perm={user.perm}
            changePerm={changePerm}
            withDelete={true}
            isDisabled={isDisabled}
          />
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
SharedUserListItem.propTypes = {
  user: PropTypes.object.isRequired,
  changeUserPerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

/*
org item
 */
function SharedOrgListItem({ org, perm, changeOrgPerm, isDisabled }) {
  const useStyles = makeStyles(theme => ({
    root: {
      margin: 0,
      padding: 0,
    },
    multiline: {
      margin: 0,
    },
    primary: {
      fontSize: '1.2em',
    },
    secondary: {
      fontSize: '1.1em',
    },
    listItem: {
      minWidth: '90%',
    },
    listItemAction: {
      width: 200,
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      backgroundColor: theme.palette.primary.dark,
    },
  }));
  const classes = useStyles();

  const changePerm = perm => {
    changeOrgPerm(org, perm);
  };

  return (
    <ListItem className={classes.listItem} dense disableGutters>
      <ListItemAvatar>
        <Avatar className={classes.avatar} variant="rounded">
          {org['name'][0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={org.name}
        //secondary={org.uuid}
        classes={{
          root: classes.root,
          multiline: classes.multiline,
          primary: classes.primary,
          secondary: classes.secondary,
        }}
      />
      <ListItemSecondaryAction className={classes.listItemAction}>
        <div>
          <SelectPerm
            perm={perm}
            changePerm={changePerm}
            withDelete={false}
            withNone={true}
            isDisabled={isDisabled}
          />
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
SharedOrgListItem.propTypes = {
  org: PropTypes.object.isRequired,
  perm: PropTypes.string.isRequired,
  changeOrgPerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

/*
全体
*/
export default function SharedList({
  data,
  users,
  own_org,
  changeUserPerm,
  changeOrgPerm,
  isDisabled,
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      maxHeight: isMobile() ? 200 : 300,
      minWidth: 500,
      overflow: 'auto',
    },
    list: {},
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          共有中のユーザー・組織：
          {(Object.keys(own_org).length > 0 ? 1 : 0) + users.length}
        </Typography>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          disabled={isDisabled}
        >
          ユーザーを招待
        </Button>
      </Box>
      <Box className={classes.root}>
        <List disablePadding>
          {/* 所有するOrgが存在する場合 */}
          {Object.keys(own_org).length > 0 && (
            <>
              <SharedOrgListItem
                org={own_org.org}
                perm={own_org.perm}
                changeOrgPerm={changeOrgPerm}
                isDisabled={isDisabled}
              />
            </>
          )}
          {users.map(user => (
            <React.Fragment key={user.id}>
              <SharedUserListItem
                user={user}
                changeUserPerm={changeUserPerm}
                isDisabled={isDisabled}
              />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <InviteUserDialog
        data={data}
        changeUserPerm={changeUserPerm}
        isDisabled={isDisabled}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

SharedList.propTypes = {
  data: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  own_org: PropTypes.object.isRequired,
  changeUserPerm: PropTypes.func.isRequired,
  changeOrgPerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
