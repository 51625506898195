// 各folderの設定を変更するための modal dialog
import React, { useEffect, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {
  RadioGroup,
  Radio,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Checkbox,
  TextField,
  Input,
  Button,
} from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import TimerIcon from '@material-ui/icons/Timer';

import Loading from '../../../components/Loading';
import { SnackbarContext } from '../../../context/SnackbarContext';

import { makeStyles } from '@material-ui/core/styles';

import { patchFolder, getFolder } from '../../../api/rest';
import { useFolderInfoReset } from '../api/getFolderInfo';
import { useAsyncError } from '../../../api/error';
import { useFolderPathReset } from '../api/getFolderPath';
import { useOrgRootListInvalidate } from '../../organization/api/getOrgRootList';
import { folder } from 'jszip';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 0),
    textTransform: 'none',
  },
  leftBox: {
    height: '80%',
    width: '150px',
    margin: theme.spacing(0, 2, 0, 0),
  },
  rightBox: {
    height: '400px',
  },
  dialog: {
    height: '80%',
    width: '100%',
  },
  dialogTitle: {},
  list: {
    height: '30%',
    margin: theme.spacing(0),
  },
  inputAfterDays: {
    marginTop: '0px !important',
    width: '50px',
    display: 'flex',
  },
  labelAfterDays: {
    marginLeft: '15px', marginTop: '10px'
  }
}));

function FolderSettingsDialog({data, open, setOpen}) {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [view, setView] = React.useState('viewAutoDeleteSetting');
  const isSelected = id => {
    return view == id;
  };

  const {
    setSnackbarMessage,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
  } = useContext(SnackbarContext);
  const throwError = useAsyncError();

  // 全てのフォルダ設定はこのstateに保存する
  const [folderSettings, setFolderSettings] = React.useState({});

  const saveFolderSettings = async () => {
    try {
      await patchFolder(data.uuid, {
        settings: folderSettings,
      });
    } catch (e) {
      console.log('Error', e);
      throwError(e);
    }
    console.debug('folderSettings updated in backend', folderSettings);
  }

  // useEffect(() => {
  //   // This is called every time a property of folderSettings is updated
  //   // Save folder settings ONLY IF folderSettings contains data
  //   if (Object.keys(folderSettings).length > 0) {
  //     // patch folder APIで保存する
  //     saveFolderSettings();
  //   }
  // }, [folderSettings]);

  const useHandleSave = () => {
    // Save folder settings on click 適用 only.
    // Save folder settings ONLY IF folderSettings contains data
    if (Object.keys(folderSettings).length > 0) {
      // patch folder APIで保存する
      console.log('Save settings', folderSettings);
      setOpenSnackbarCollapse(false);
      setOpenSnackbar(true);
      setSnackbarMessage('フォルダの設定を保存しています');
      saveFolderSettings()
      .then(() => {
        setOpen(false);
        setSnackbarMessage('設定を保存しました');
      });
    }
  }

  useEffect(() => {
    // first time component loads
    // Get initial data state here.
    console.debug('Initial data', data);
    const getFolderData = async () => {

      // Try to load data only if there is a uuid
      // and we are inside the FolderSettings dialog (open is true)
      // This component also gets called in many other places, so ignore completely if we
      // are outside of the FolderSettings dialog.
      if ((open) && ('uuid' in data)) {
        setLoading(true);
        const response = await getFolder(data.uuid);
        var initialState = response.data.settings;
        var parentSettings = response.data.parent_settings;
        // Override with parent settings for auto-delete, if applicable
        if (parentSettings){
          console.log('Parent settings', parentSettings.auto_delete === true);
          if (parentSettings.auto_delete === true){
            initialState.auto_delete = parentSettings.auto_delete;
            initialState.auto_delete_date = parentSettings.auto_delete_date;
            initialState.auto_delete_self = parentSettings.auto_delete_self;
            initialState.auto_delete_type = parentSettings.auto_delete_type;
            initialState.auto_delete_parent = true;
          }
        } else {
          // No auto-delete setting from parent, use this folder's own settings
          initialState.auto_delete_parent = false;
        }

        if (initialState === null){
          initialState = {}
        }
        console.log('FolderSettingsDialog initial state: ', initialState);
        setFolderSettings(initialState);
        setLoading(false);
      } else {
        console.debug('Not in FolderSettings dialog, or no folder selected');
      }
    }

    getFolderData();
    return () => {
      // this gets called when the component unmounts
    };
  }, []);

  const useHandleClose = () => {
    setOpen(false);
  };

  const handleChangeSettingsView = e => {
    console.log('Settings view', e.currentTarget.id);
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toJSON().split('T')[0];
  }

  const onChangeAutoDelete = async event => {
    const defaultAutoDeleteDate = addDays(new Date(), 7);
    if (event.target.checked) {
      console.log('AutoDelete checked, default date', defaultAutoDeleteDate);
      setFolderSettings({
        ...folderSettings,
        auto_delete: true,
        auto_delete_self: false,
        auto_delete_type: 'delete-on-date',
        auto_delete_date: defaultAutoDeleteDate,
        auto_delete_after: null,
      });
    } else {
      console.log('AutoDelete OFF')
      setFolderSettings({
        ...folderSettings,
        auto_delete: false,
        auto_delete_self: false,
        auto_delete_type: null,
        auto_delete_date: null,
        auto_delete_after: null,
      });
    }
  }

  const onChangeAutoDeleteSelf = async event => {
    if (event.target.checked) {
      setFolderSettings({
        ...folderSettings,
        auto_delete_self: true
      });
    } else {
      setFolderSettings({
        ...folderSettings,
        auto_delete_self: false
      });
    }
  };

  const onChangeDate = async date => {
    console.debug('Selected date', date);
    setFolderSettings({
      ...folderSettings,
      auto_delete_date: date.toJSON().split('T')[0]
    });
  };

  const onChangeAutoDeleteType = async event => {

    console.debug('Delete type: ', event.target.value);
    if (event.target.value == 'delete-on-date') {

      setFolderSettings({
        ...folderSettings,
        auto_delete_type: event.target.value,
        auto_delete_after: null,
        auto_delete_date: addDays(new Date(), 7)
      });
    } else if (event.target.value == 'delete-after-days') {

      setFolderSettings({
        ...folderSettings,
        auto_delete_type: event.target.value,
        auto_delete_date: null,
        auto_delete_after: 7,
      });
    } else {
      console.error('Invalid delete type');
    }
  }

  const useValidateAfterDays = () => {
    // Called when the after days input field loses focus
    // Set to 0 if empty string or less than 0
    if ((folderSettings.auto_delete_after === '') || (folderSettings.auto_delete_after < 0)) {
      setFolderSettings({
        ...folderSettings,
        auto_delete_after: 0,
      });
    }
  };

  const onChangeAfterDays = async event => {
    const afterDays =
      event.target.value === '' ? '' : Number(event.target.value);
    console.debug('After days', afterDays);

    // Only save if input is empty string or valid number
    if ((afterDays === '') || (Number.isInteger(afterDays) && afterDays >= 0)) {
      setFolderSettings({
        ...folderSettings,
        auto_delete_after: afterDays,
      });
    }
  }

  function getDeleteAfterValue() {
    // Get the value of the 「日後」input field
    // Either a number >= 0 or empty string.
    if (Number.isInteger(folderSettings.auto_delete_after)){
      return folderSettings.auto_delete_after;
    } else {
      // Allow last remaining digit to be erased with backspace
      // null input field causes errors in frontend
      return '';
    }
  }

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={useHandleClose}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle>フォルダの設定</DialogTitle>
      {/* {loading ? (
        <Loading />
      ) : ( */}
        <DialogContent className={classes.content} dividers>
          <Grid container>
            {/* フォルダ設定画面の左カラム */}
            <Grid item xs={3}>
              <Box className={classes.leftBox}>
                <List className={classes.list} dense disabled>
                  <ListItem
                    onClick={handleChangeSettingsView}
                    button
                    id="viewAutoDeleteSetting"
                    selected={isSelected('viewAutoDeleteSetting')}
                  >
                    <ListItemIcon>
                      <TimerIcon />
                    </ListItemIcon>
                    <ListItemText>自動削除</ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Grid>

            {/* フォルダ設定画面のメイン */}
            <Grid item xs={9}>
              <Box className={classes.rightBox}>
                <Typography>自動でフォルダを削除</Typography>
                { folderSettings.auto_delete_parent ? (
                  <Alert severity="info">以下の設定は親フォルダから継承されましたので、変更できません。</Alert>
                  ) : (<Typography></Typography>)
                }
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={folderSettings.auto_delete || false}
                        onChange={onChangeAutoDelete}
                        name="autoDeleteCheckbox"
                        color="secondary"
                        disabled={folderSettings.auto_delete_parent}
                      />
                    }
                    label="フォルダの自動削除設定を有効にする"
                  />
                  <Box style={{ paddingLeft: '40px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={folderSettings.auto_delete_self || false}
                        onChange={onChangeAutoDeleteSelf}
                        name="autoDeleteCheckbox"
                        color="secondary"
                        disabled={!folderSettings.auto_delete || folderSettings.auto_delete_parent}
                      />
                    }
                    label="フォルダ内のデータに加えてフォルダ自体も削除する"
                  />

                  <RadioGroup
                    aria-label="delete-type"
                    name="delete-type"
                    // If auto_delete_type is not set (e.g. auto-delete turned off),
                    // '' sets the Radio to unselected (none of the options are active).
                    // Don't use the `defaultValue` property, it causes 'uncontrolled component' warning
                    value={folderSettings.auto_delete_type || ''}
                    onChange={onChangeAutoDeleteType}
                  >
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="delete-on-date"
                        control={<Radio />}
                        label="削除日を指定する"
                        disabled={!folderSettings.auto_delete || folderSettings.auto_delete_parent}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          style={{ marginTop: '5px' }}
                          value={folderSettings.auto_delete_date || null}
                          format="yyyy-MM-dd"
                          emptyLabel=""
                          onChange={onChangeDate}
                          disabled={
                            !folderSettings.auto_delete ||
                            !folderSettings.auto_delete_date ||
                            folderSettings.auto_delete_parent
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel
                        id="input-after-days"
                        value="delete-after-days"
                        control={<Radio />}
                        label="アップロードの削除期間を指定する"
                        disabled={
                          !folderSettings.auto_delete ||
                          folderSettings.auto_delete_parent
                        }
                      />
                      <Input
                        className={classes.inputAfterDays}
                        value={getDeleteAfterValue()}
                        size="small"
                        onChange={onChangeAfterDays}
                        onBlur={useValidateAfterDays}
                        inputProps={{
                          step: 1,
                          min: 0,
                          type: 'number',
                          'aria-labelledby': 'input-after-days',
                        }}
                        disabled={
                          !folderSettings.auto_delete ||
                          folderSettings.auto_delete_type != 'delete-after-days' ||
                          folderSettings.auto_delete_parent
                        }
                      />
                      <Typography className={classes.labelAfterDays}>日後</Typography>
                    </div>
                  </RadioGroup>
                  </Box>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={useHandleClose}>キャンセル</Button>
          <Button
            onClick={useHandleSave}
            disabled={folderSettings.auto_delete_parent}
            color="primary"
            variant="contained">
              適用
          </Button>
        </DialogActions>
      {/* )} */}
    </Dialog>
  );
}

const useFolderSettingsDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openFolderSettingsDialog = data => {
    setData(data);
    setOpen(true);
  };

  function folderSettingsDialog() {
    return <FolderSettingsDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openFolderSettingsDialog, folderSettingsDialog];
};

export { FolderSettingsDialog, useFolderSettingsDialog };
