import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import { UserContext } from '../../../../providers/UserContext';
import { authRequest } from '../../../../api/auth';
import MembersPage from './MembersPage';
import AccountPage from './AccountPage';
import OrgSettingPage from './OrgSettingPage';
import OrgDataUsagePage from './OrgDataUsagePage';

import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { isMobileOrTablet } from '../../../../utils/detectDevice';
import {
  ORGANIZATION_ADMIN,
  ORGANIZATION_MEMBER,
  DELETE,
} from '../../../../utils/permissionSettings';
import { getCustomPermApi } from '../../../../api/rest';
import { CustomUserPermPage, UserApprovalPage } from './CustomUserPerm';
import useIsMounted from '../../../../utils/isMounted';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 0),
    textTransform: 'none',
  },
  leftBox: {
    height: '80%',
    margin: theme.spacing(0, 2, 0, 0),
  },
  rightBox: {
    height: '600px',
  },
  dialog: {
    height: '80%',
    width: '80%',
  },
  dialogTitle: {},
  list: {
    height: '30%',
    margin: theme.spacing(0),
  },
}));

function ScrollableTabsButtonForce({ handleChange }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const isMounted = useIsMounted();

  const handleChangeWrap = (e, newValue) => {
    handleChange(e);
    if (isMounted.current) {
      setValue(newValue);
    }
  };

  return (
    <div>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChangeWrap}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label="アカウント"
            icon={<AccountCircleOutlinedIcon />}
            id="account"
          />
          <Tab
            label="メンバー管理"
            icon={<PeopleAltOutlinedIcon />}
            id="members"
          />
          <Tab label="組織設定" icon={<SettingsOutlinedIcon />} id="setting" />
          <Tab label="組織データ" icon={<DataUsageIcon />} id="dataUsage" />
        </Tabs>
      </AppBar>
    </div>
  );
}
ScrollableTabsButtonForce.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

function SettingMembersDialog(props) {
  const classes = useStyles();
  const { data: orgDetails } = useOrgDetail();
  const { isUserLoggedIn, user } = useContext(UserContext);
  const [userList, setUserList] = React.useState({});

  // Admin flag is set true if user has global admin perms
  // if false, the ユーザ権限管理 section is not displayed
  const [admin, setAdmin] = React.useState(false);
  const [customPerms, setCustomPerms] = React.useState({});
  const [adminList, setAdminList] = React.useState([]);
  const [medSearchList, setMedSearchList] = React.useState([]);
  const [pendingUserList, setPendingUserList] = React.useState([]);
  const [allUserList, setAllUserList] = React.useState([]);
  const isMounted = useIsMounted();

  const refreshCustomUserPerm = async () => {
    try {
      const response = await getCustomPermApi();
      const status = response.status;
      const data = response.data;
      console.log('getCustomPermApi', status, data);
      if (isMounted.current && status === 200) {
        // User is authorized
        setAdmin(true);
        setAdminList(data.change_users);
        setMedSearchList(data.medsearch_users);
        setPendingUserList(data.pending_users);
        setAllUserList(data.all_users);
      }
    } catch (e) {
      // Do nothing
      console.debug('getCustomPermApi', e);
    }
  };

  // Runs once when component mounts
  useEffect(() => {
    refreshCustomUserPerm();
  }, []);

  const permTypeToStatus = {
    admin: ORGANIZATION_ADMIN,
    member: ORGANIZATION_MEMBER,
    delete: DELETE,
  };

  const fetchUserList = useCallback(async () => {
    const url = `/api/access/org/user-list/${orgDetails.uuid}`;
    await authRequest
      .get(url)
      .then(res => {
        setUserList(res.data);
      })
      .catch(err => console.log(err));
  }, [orgDetails.uuid]);

  const [view, setView] = React.useState('account');

  const handleChange = e => {
    setView(e.currentTarget.id);
  };

  const isSelected = id => {
    return view == id;
  };

  if (customPerms && Object.keys(customPerms).length > 0) {
    console.log('customPerms', customPerms);
  }

  const pages = (
    <>
      {view == 'account' ? (
        <AccountPage setOpen={props.setOpen} />
      ) : view == 'members' ? (
        <MembersPage
          userList={userList}
          fetchUserList={fetchUserList}
          permTypeToStatus={permTypeToStatus}
        />
      ) : view == 'setting' ? (
        <OrgSettingPage permTypeToStatus={permTypeToStatus} />
      ) : view == 'dataUsage' ? (
        <OrgDataUsagePage permTypeToStatus={permTypeToStatus} />
      ) : view == 'appPerms' ? (
        <CustomUserPermPage
          adminList={adminList}
          setAdminList={setAdminList}
          medSearchList={medSearchList}
          setMedSearchList={setMedSearchList}
          allUserList={allUserList}
        />
      ) : view == 'userApproval' ? (
        <UserApprovalPage
          pendingUserList={pendingUserList}
          refreshCustomUserPerm={refreshCustomUserPerm}
        />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      {isMobileOrTablet() ? (
        <Dialog
          open={props.open}
          setOpen={props.setOpen}
          onClose={props.onClose}
          maxWidth={'sm'}
        >
          <ScrollableTabsButtonForce value={view} handleChange={handleChange} />
          <DialogContent>{pages}</DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={props.open}
          setOpen={props.setOpen}
          fullWidth
          onClose={props.onClose}
          maxWidth={'md'}
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={3}>
                <Box className={classes.leftBox}>
                  <List className={classes.list} dense disabled>
                    {/* アカウント */}
                    <ListSubheader component="div" id="list-org">
                      {isUserLoggedIn && Object.keys(user).length > 0
                        ? user.email
                        : '未ログイン'}
                    </ListSubheader>
                    <ListItem
                      onClick={handleChange}
                      button
                      id="account"
                      selected={isSelected('account')}
                    >
                      <ListItemIcon>
                        <AccountCircleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>アカウント</ListItemText>
                    </ListItem>

                    <br />
                    {/* 組織設定 */}
                    <ListSubheader component="div" id="list-org">
                      {'組織'}
                    </ListSubheader>
                    <ListItem
                      onClick={handleChange}
                      button
                      id="setting"
                      selected={isSelected('setting')}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>設定</ListItemText>
                    </ListItem>
                    <ListItem
                      onClick={handleChange}
                      button
                      id="members"
                      selected={isSelected('members')}
                    >
                      <ListItemIcon>
                        <PeopleAltOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>メンバー</ListItemText>
                    </ListItem>
                    <ListItem
                      onClick={handleChange}
                      button
                      id="dataUsage"
                      selected={isSelected('dataUsage')}
                    >
                      <ListItemIcon>
                        <DataUsageIcon />
                      </ListItemIcon>
                      <ListItemText>データ使用量</ListItemText>
                    </ListItem>

                    {/*
                      Custom user permissions, if access is allowed.
                      Display content only if admin flag is true
                    */}
                    {admin && (
                      <div>
                        <ListSubheader component="div" id="custom=perm">
                          {'ユーザ権限管理'}
                        </ListSubheader>
                        <ListItem
                          onClick={handleChange}
                          button
                          id="appPerms"
                          selected={isSelected('appPerms')}
                        >
                          <ListItemIcon>
                            <LibraryAddCheckOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText>アプリ権限</ListItemText>
                        </ListItem>
                        <ListItem
                          onClick={handleChange}
                          button
                          id="userApproval"
                          selected={isSelected('userApproval')}
                        >
                          <ListItemIcon>
                            <PersonAddIcon />
                          </ListItemIcon>
                          <ListItemText>ユーザの有効</ListItemText>
                        </ListItem>
                      </div>
                    )}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.rightBox}>{pages}</Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
SettingMembersDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export const useSettingMembers = () => {
  // Show or hide the Settings & Members modal
  const [open, setOpen] = React.useState(false);

  const openSettingMembers = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  function SettingMembers() {
    return (
      <SettingMembersDialog
        open={open}
        setOpen={setOpen}
        onClose={closeDialog}
      />
    );
  }

  return [openSettingMembers, SettingMembers];
};
