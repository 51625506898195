import React from 'react';
import OHIF from '@ohif/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import AnonymizeForm from './AnonymizeForm';
import { UploadCancelError } from '../../utils/upload';
import { digestMessage } from './anonymizeUtils';
const useStyles = makeStyles(theme => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
}));

const useAnonymizeDialog = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [studies, setStudies] = React.useState([]);
  const { setAnonymizedStudyData } = OHIF.redux.actions;
  const [state, setState] = React.useState({
    resolve: () => {},
    reject: () => {},
  });

  const openAnonymizeDialog = (studies, state) => {
    const removeSeries = obj => {
      const { series, ...res } = obj;
      // StudyInstanceUIDはkeyに使うので匿名化するときも残しておきたい
      res['HashedStudyInstanceUID'] = digestMessage(res['StudyInstanceUID']);
      return res;
    };
    const studiesWithoutSeries = studies.map(removeSeries);
    setStudies(studiesWithoutSeries);
    window.store.dispatch(setAnonymizedStudyData(studiesWithoutSeries));
    setOpen(open => true);
    setState(state);
  };
  const closeDialog = async () => {
    const newStudyData = window.store.getState().studies.anonymizedStudyData;
    const {
      isEdit,
      isAnonymize,
    } = window.store.getState().studies.anonymizedRadio;
    await state.resolve([newStudyData, isAnonymize]);
    setOpen(open => false);
  };

  const cancelUpload = async () => {
    await state.reject(new UploadCancelError('cancel upload'));
    setOpen(open => false);
    // return state.resolve;
  };

  function AnonymizeDialog() {
    return (
      <>
        <Dialog
          open={open}
          scroll="paper"
          className={classes.modal}
          fullWidth
          maxWidth="sm"
        >
          <AnonymizeForm
            studies={studies}
            closeDialog={closeDialog}
            cancelUpload={cancelUpload}
          />
        </Dialog>
      </>
    );
  }

  return [openAnonymizeDialog, AnonymizeDialog];
};

export default useAnonymizeDialog;
