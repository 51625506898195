import { useMemo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedOrgUUID } from './currentOrgUUID';
import {
  checkAdminPerm,
  checkMemberPerm,
} from '../../../utils/checkAccessPerm';

const getOrgUserPerm = async ({ orgUUID }) => {
  const pathUrl = `/api/access/org/my-perm/${orgUUID}`;
  const res = await authRequest.get(pathUrl);
  // If request has completed, access token should be valid
  if (res && res.data) {
    return res.data;
  } else {
    console.warn('getOrgUserPerm, no response', res);
    return {};
  }
  // return await authRequest.get(pathUrl).then(res => res.data);
};

export const useOrgUserPerm = ({
  orgUUID = '',
  select = data => data,
} = {}) => {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const placeholderData = useMemo(() => {
    return { perm: '' };
  }, []);

  return useQuery({
    queryKey: ['orgUserPerm', usedOrgUUID],
    queryFn: () => getOrgUserPerm({ orgUUID: usedOrgUUID }),
    enabled: !!usedOrgUUID && usedOrgUUID !== '', // org uuid が存在するまで待機
    placeholderData,
    select,
  });
};

export const useCheckOrgPerm = ({ orgUUID = '' } = {}) => {
  return useOrgUserPerm({
    orgUUID,
    select: data => {
      return {
        adminPerm: checkAdminPerm(data.perm),
        memberPerm: checkMemberPerm(data.perm),
      };
    },
  });
};
