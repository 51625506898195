import { authRequest } from './auth.js';
import { getFileUrl } from '../utils/getUrls.js';
import { filetypeGroup } from '../utils/checkData';
import { generateUniviewerUrl } from '../features/univiewer/UniversalViewerContainer.js';
import { BASE_URL } from './auth.js';
import axios from 'axios';

const patchUsername = async username => {
  const response = await authRequest.patch(`/api/dj-rest-auth/user/`, {
    username,
  });

  console.log(response);
  return response;
};

const patchEmail = async newEmail => {
  const url = `/api/users/emailupdate`;
  const response = await authRequest.patch(url, {
    new_email: newEmail,
  });
  console.log(response);
  return response;
};

const getFileSignedUrl = async uuid => {
  const fileResponse = await authRequest({
    method: 'GET',
    url: `/api/datamanage/detail/file/${uuid}`,
  });
  return fileResponse.data['signed_url'];
};

const searchOrgStorage = async (
  org_id,
  query,
  search_field = 'all',
  limit = 2
) => {
  const result = await authRequest({
    method: 'GET',
    url: `/api/search/org-storage/${org_id}?query=${query}&search_field=${search_field}&limit=${limit}`,
  });
  return result.data;
};

const searchOrgStorageCommand = async (
  org_id,
  query,
  search_field = 'all',
  limit = 2
) => {
  const result = await authRequest({
    method: 'GET',
    url: `/api/search/org-storage-command/${org_id}?query=${query}&search_field=${search_field}&limit=${limit}`,
  });
  return result.data;
};

export {
  patchUsername,
  patchEmail,
  getFileSignedUrl,
  searchOrgStorage,
  searchOrgStorageCommand,
};

// タグ
const getTagsInfo = async tagUUID => {
  const url = `/api/tags/info/${tagUUID}`;
  const response = await authRequest.get(url);
  return response.data;
};

const postFolderTag = async (folderUUID, name) => {
  const data = { name: name };
  const url = `/api/tags/folder-tag/${folderUUID}`;
  const response = await authRequest
    .post(url, data)
    .catch(err => console.log(err));
  return response.data;
};

const deleteFolderTag = async (folderUUID, tagUUID) => {
  const url = `/api/tags/folder-tag/${folderUUID}/${tagUUID}`;
  const response = await authRequest.delete(url).catch(err => console.log(err));
  return response.data;
};

const postFileTag = async (fileUUID, name) => {
  const url = `/api/tags/file-tag/${fileUUID}`;
  const data = { name: name };
  const response = await authRequest
    .post(url, data)
    .catch(err => console.log(err));
  return response.data;
};

const deleteFileTag = async (fileUUID, tagUUID) => {
  const url = `/api/tags/file-tag/${fileUUID}/${tagUUID}`;
  const response = await authRequest.delete(url).catch(err => console.log(err));
  return response.data;
};

const getOrgTagList = async orgUUID => {
  const url = `/api/tags/org-tag-list/${orgUUID}`;
  const response = await authRequest.get(url);
  return response.data;
};

export {
  getTagsInfo,
  postFolderTag,
  deleteFolderTag,
  postFileTag,
  deleteFileTag,
  getOrgTagList,
};

// datamanage
const getFolderPage = async (
  folderUUID,
  page = 1,
  per_page = 15,
  sort = ['type', 'name'],
  rsort = ['']
) => {
  const url = `/api/datamanage/info/folder-page/${folderUUID}?page=${page}&per_page=${per_page}&sort=${sort.join(
    ','
  )}&rsort=${rsort.join(',')}`;
  const response = await authRequest.get(url);
  return response.data;
};
const getRootFolderList = async orgId => {
  const url = `/api/datamanage/list/org-root-folder/${orgId}`;
  const response = await authRequest.get(url);
  return response;
};

const getRootList = async orgId => {
  const url = `/api/datamanage/list/org-root/${orgId}`;
  const response = await authRequest.get(url);
  return response;
};

const createRootFolder = async (orgId, folderName, hasOrgPerm = false) => {
  const url = `/api/datamanage/create/org-root-folder`;
  const data = {
    name: folderName,
    org: orgId,
    has_org_perm: hasOrgPerm,
  };
  const response = await authRequest.post(url, data);
  return response;
};

const getFile = async fileUUID => {
  const url = `/api/datamanage/detail/file/${fileUUID}`;
  const response = await authRequest.get(url);
  return response;
};

const deleteFile = async fileUUID => {
  const url = `/api/datamanage/detail/file/${fileUUID}`;
  const response = authRequest.delete(url);
  return response;
};

const patchFile = async (fileUUID, data) => {
  const url = `/api/datamanage/detail/file/${fileUUID}`;
  console.log(data);
  const response = authRequest
    .patch(url, data)
    .then(res => console.log(res, 'patchFile complete'))
    .catch(error => {
      console.log('patchFile failure');
    });
  return response;
};

const getFolder = async folderUUID => {
  console.log('getFolder ', folderUUID);
  const url = `/api/datamanage/detail/folder/${folderUUID}`;
  const response = await authRequest.get(url);
  return response;
};

const deleteFolder = async folderUUID => {
  const url = `/api/datamanage/detail/folder/${folderUUID}`;
  const response = authRequest
    .delete(url)
    .then(res => console.log(res, 'deletion complete'))
    .catch(error => {
      console.log('deletion failure');
    });
  return response;
};

const patchFolder = async (folderUUID, data) => {
  const url = `/api/datamanage/detail/folder/${folderUUID}`;
  const response = authRequest
    .patch(url, data)
    .then(res => console.debug(res, 'patchFolder complete'));
  return response;
};

const changeAnonymousPermApi = async (type, uuid, newPerm, expiryDate) => {
  let url = `/api/datamanage/perm/anonymous-${type}/${uuid}`;
  const response = authRequest.patch(url, {
    perm: newPerm,
    anon_link_expiry: expiryDate,
  });
  return response;
};

// Custom perm API is used to view or change custom permissions
// at the application level, e.g. access to Medical Search.
// Users of this API must be Admin or have change_user perm
const getCustomPermApi = async () => {
  let url = `/api/access/user/custom-perm`;
  const response = await authRequest.get(url);
  return response;
};

const changeCustomPermApi = async data => {
  let url = `/api/access/user/custom-perm`;
  const response = await authRequest.patch(url, data);
  return response;
};

const pendingUserApi = async (data, action) => {
  let url = `/api/access/user/user-approval`;
  if (action == 'approve') {
    const response = await authRequest.patch(url, data);
    return response;
  } else if (action == 'reject') {
    url += `?userId=${data.userId}`;
    // No data can be attached to a DELETE request, Id must be in URL params
    console.log('DELETE', url);
    const response = await authRequest.delete(url);
    return response;
  }
};

const changeUserPermApi = async (
  type,
  uuid,
  email,
  newPerm,
  isSend = false,
  data = {},
  message = ''
) => {
  console.log(data);
  if (isSend) {
    if (type == 'folder') {
      if (
        data.foldertype === 'study' ||
        data.foldertype === 'series' ||
        data.foldertype === 'imgSeries'
      ) {
        // Use Universal Viewer URL
        const univiewerUrl = generateUniviewerUrl(data);
        const downloadURL = `${window.location.origin}/download/${data.uuid}`;
        console.log('univiewerUrl', univiewerUrl);
        // const dcmUrl = `${window.location.origin}/viewer?url=/api/datamanage/dcm/folder/${data.uuid}`;
        message += `-----\n`;
        message += `ビューアのURL: ${univiewerUrl}\n`;
        message += `-----\n`;
        message += `データのダウンロードURL: ${downloadURL}\n`;
        message += `-----\n`;
      } else {
        const storageUrl = `${window.location.origin}/storage/${data.uuid}`;
        message += `-----\n`;
        message += `ストレージのURL: ${storageUrl}\n`;
        message += `-----\n`;
      }
    } else if (type == 'file') {
      if (filetypeGroup(data.filetype) == 'url') {
        message += `-----\n`;
        message += `リンクファイルのURL: ${data.url}\n`;
        message += `-----\n`;
      } else {
        message += `-----\n`;
        message += `ファイルのURL: ${getFileUrl(
          data.filetype,
          data.uuid,
          true
        )}\n`;
        message += `-----\n`;
      }
    }
  }
  let url = `/api/datamanage/perm/user-${type}/${uuid}`;
  const response = authRequest.patch(url, {
    email: email,
    perm: newPerm,
    isSend: isSend,
    message: message,
  });
  return response;
};

const changeOrgPermApi = async (type, uuid, org_id, newPerm) => {
  let url = `/api/datamanage/perm/org-${type}/${uuid}`;
  const response = authRequest.patch(url, { org_id: org_id, perm: newPerm });
  return response;
};

const createConference = async data => {
  const apiURL = `/api/datamanage/create/conference`;
  const response = await authRequest.post(apiURL, data);
  console.debug('createConference', response);
  return response.data;
};

// List all Conferences in a specific org
const getConferenceList = async org_id => {
  const apiURL = `/api/datamanage/list/conference/${org_id}`;
  const response = await authRequest.get(apiURL);
  return response.data;
};

// Get details on a specific Conference
const getConference = async folderUUID => {
  const apiURL = `/api/datamanage/detail/conference/${folderUUID}`;
  const response = await authRequest.get(apiURL);
  return response.data;
};

// Edit details on a specific Conference
const editConference = async (data, folderUUID) => {
  const apiURL = `/api/datamanage/detail/conference/${folderUUID}`;
  console.log('editConference', folderUUID, data);
  const response = await authRequest.patch(apiURL, data);
  return response.data;
};

// HTTP Basic auth for a Conference 一覧表 page
const confLandingPageLogin = async (eventName, password) => {
  // Use event name as username for Basic Auth
  const confLandingCreds = `${eventName}:${password}`;
  // console.log('confLandingPageLogin', confLandingCreds);
  const authBase64 = window.btoa(confLandingCreds);
  const authHeader = {
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + authBase64,
  };
  const httpBasicRequest = axios.create({
    baseURL: BASE_URL,
    timeout: 60 * 1000,
    headers: authHeader,
  });
  const data = { eventName: eventName };
  const apiURL = `/api/datamanage/landing/conference`;
  const response = await httpBasicRequest.post(apiURL, data);
  console.log('confLandingPageLogin', response);
  return response;
};

const createCaseStudy = async data => {
  const apiURL = `/api/datamanage/create/folder`;
  const response = await authRequest.post(apiURL, data);
  console.debug('createCaseStudy', response);
  return response.data;
};

const editCaseStudy = async (folderUUID, data) => {
  const apiURL = `/api/datamanage/detail/folder/${folderUUID}`;
  const response = await authRequest.patch(apiURL, data);
  console.debug('editCaseStudy', response);
  return response.data;
};

const createFile = async data => {
  const apiURL = `/api/datamanage/create/file`;
  const response = await authRequest
    .post(apiURL, data)
    .then(res => console.log('complete createFile'));
  return response;
};

const createFolder = async (parentFolderUUID, name) => {
  const url = `/api/datamanage/create/folder`;
  const data = { name: name, parent_folder: parentFolderUUID };
  const response = authRequest
    .post(url, data)
    .then(res => console.log(res, 'createFolder complete'));
  return response;
};

const getPermList = async (type, uuid) => {
  let url = `/api/datamanage/perm/${type}-auth-list/${uuid}`;
  const response = await authRequest.get(url);
  return response;
};

const folder2series = async folderUUID => {
  const url = `/api/datamanage/folder2series`;
  const response = await authRequest.post(url, { uuid: folderUUID });
  return response;
};

const series2folder = async seriesUUID => {
  const url = `/api/datamanage/series2folder`;
  const response = await authRequest.post(url, { uuid: seriesUUID });
  return response;
};

const orgDetailData = async orgId => {
  const url = `/api/datamanage/calculation/organization/${orgId}`;
  const response = await authRequest.get(url);
  return response;
};

// upload-pydicom-file
const uploadPyDicomFile = async ({ dcmBatch, options, signal }) => {
  // Prepare form data
  // Must be let, not const, or the formData will never be updated!
  let formData = new FormData();
  // console.log('selectedFile', selectedFile);
  // formData.append('file', dcmFile);  // Add file to formData

  // Add batch of files
  dcmBatch.forEach((file, index) => {
    // Append each file to the FormData
    formData.append(`file${index}`, file);
  });

  // Add JSON metadata as a string
  formData.append('options', JSON.stringify(options));

  const url = `/api/datamanage/upload-pydicom-file`;
  const response = await authRequest.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 600000, // 10 minutes in milliseconds
    signal: signal,
  });
  return response;
};

// Mark studies as upload completed
const setStudiesCompleted = async (studyList, target_folder) => {
  const url = `/api/datamanage/upload-pydicom-completed`;
  const data = {
    study_id_list: studyList,
    target_folder: target_folder,
  };
  const response = await authRequest.post(url, data);

  return response;
};

// Ude a PATCH request to to indicate that the user has cancelled upload
const abortUpload = async (studyList, target_folder) => {
  const url = `/api/datamanage/upload-pydicom-completed`;
  const data = {
    study_id_list: studyList,
    target_folder: target_folder,
  };
  console.info('abortUpload', data);
  const response = await authRequest.patch(url, data);

  return response;
};

const uploadOneDicom = async ({
  file_data,
  target_folder,
  target_org,
  is_org_perm,
}) => {
  const url = `/api/datamanage/upload-one-dicom`;
  const response = await authRequest.post(url, {
    file_data,
    target_folder,
    target_org,
    is_org_perm,
  });
  return response;
};

const uploadOneFile = async ({
  file_data,
  target_folder,
  target_org,
  is_org_perm,
}) => {
  const url = `/api/datamanage/upload-one-file`;
  const response = await authRequest.post(url, {
    file_data,
    target_folder,
    target_org,
    is_org_perm,
  });
  return response;
};

export {
  getFolderPage,
  getRootFolderList,
  getRootList,
  createRootFolder,
  getFile,
  deleteFile,
  patchFile,
  getFolder,
  deleteFolder,
  patchFolder,
  changeAnonymousPermApi,
  getCustomPermApi,
  changeCustomPermApi,
  pendingUserApi,
  changeUserPermApi,
  changeOrgPermApi,
  createCaseStudy,
  editCaseStudy,
  createFile,
  createFolder,
  getPermList,
  folder2series,
  series2folder,
  orgDetailData,
  uploadPyDicomFile,
  setStudiesCompleted,
  uploadOneDicom,
  uploadOneFile,
  createConference,
  getConferenceList,
  getConference,
  editConference,
  confLandingPageLogin,
  abortUpload,
};

// Request to get data for Universal Viewer
const getDataUniversalViewer = async studyUId => {
  const url = `/api-v1/univiewer?studyUId=${studyUId}`;
  console.log('getDataUniversalViewer:', url);
  const response = await authRequest.get(url);
  console.log(response);
  return response;
};

// A request that simply passes the search params to the API
// Can search based on multiple params, not just Study UID
const getDataUniversal = async searchParams => {
  // Search params is already prepended with ?
  // e.g. ?studyUId=ef307a9c-59de-44c0-8a51-7082b8dc2f8d
  const url = `/api-v1/univiewer${searchParams}`;
  console.log('getDataUniversal:', url);
  const response = await authRequest.get(url);
  return response.data;
};

export { getDataUniversalViewer, getDataUniversal };

const getTargetDataList = async ({ targetFolderUUID, targetOrgUUID } = {}) => {
  console.log(
    'getTargetDataList folder',
    targetFolderUUID,
    'org',
    targetOrgUUID
  );
  if (targetFolderUUID == '') {
    // root
    const pathUrl = `/api/datamanage/list/org-root/${targetOrgUUID}`;
    const data = await authRequest.get(pathUrl).then(res => res.data);
    let arr = [];
    Object.keys(data).forEach(key => {
      arr = arr.concat(data[key]);
    });
    return arr;
  } else {
    let url = `/api/datamanage/info/folder/${targetFolderUUID}?noUrl=true&combine=true`;
    const data = await authRequest.get(url).then(res => res.data);
    return data.data;
  }
};

const getFolderInfo = async (
  folderUUID,
  noFile = false,
  noUrl = false,
  combine = false
) => {
  console.log('DATA FOR ', folderUUID);
  let url = `/api/datamanage/info/folder/${folderUUID}`;
  let params = [];
  if (noFile) {
    params.push('noFile=true');
  }
  if (noUrl) {
    params.push('noUrl=true');
  }
  if (combine) {
    params.push('combine=true');
  }
  if (params.length > 0) {
    url += '?' + params.join('&');
  }
  const response = await authRequest.get(url);
  return response;
};

const getRecursiveFolderInfo = async (folderUUID, noFile = false) => {
  let url = `/api/datamanage/recursive/folder/${folderUUID}`;
  if (noFile) {
    url += '?noFile=true';
  }
  const response = await authRequest.get(url);
  return response;
};

export { getTargetDataList, getFolderInfo, getRecursiveFolderInfo };

// org
const getOrgDetail = async orgId => {
  const url = `/api/access/org/detail/${orgId}`;
  const response = await authRequest.get(url);
  return response;
};
const patchOrgName = async (orgId, name) => {
  const url = `/api/access/org/detail/${orgId}`;
  const response = await authRequest.patch(url, { name: name });
  return response;
};

const getOrgPerm = async orgId => {
  const url = `/api/access/org/my-perm/${orgId}`;
  const response = await authRequest.get(url);
  return response.data;
};

const getOrgPermBool = async orgId => {
  // Simpler form of this API, return true if user has any accces to the org
  const orgPerm = await getOrgPerm(orgId);
  if (orgPerm && orgPerm.hasOwnProperty('perm')) {
    const perm = orgPerm.perm;
    console.log('OrgPerm:', perm, 'for orgId:', orgId);
    return perm == 'org_admin' || perm == 'org_member';
  }
  return false;
};

const getOrgList = async () => {
  const url = `/api/access/org/list`;
  const response = await authRequest.get(url);
  return response;
};

const createOrg = async name => {
  const url = `/api/access/org/create`;
  const response = await authRequest.post(url, { name: name });
  return response;
};

export {
  getOrgDetail,
  patchOrgName,
  getOrgPerm,
  getOrgPermBool,
  getOrgList,
  createOrg,
};

// Concierge アクセス権限があるかどうか
const checkConciergeAccess = async () => {
  try {
    const response = await authRequest({
      method: 'GET',
      url: `/api/medical-search/`,
    });
    console.debug('checkConciergeAccess response', response);
    const status = response.status;
    if (status === 200) {
      // User is authorized
      return status;
    }
    // If status 401, user is not logged in (no refresh token). Redirect to login
    if (status === 401) {
      console.log('Redirect to login');
      window.location.href = '/login?continueUrl=/medical-search';
    } else if (status === 403) {
      // User is logged in (authenticated with access token),
      // but not authorized for this app. Show page not found.
      return status;
    } else {
      // Unexpected
      return status;
    }
  } catch (error) {
    console.error('checkConciergeAccess', error);
    return 403;
  }
};

// /concierge/search?modality=&method=&locations=&ageMin=0&ageMax=120&sex=&positiveKeywords=&negativeKeywords=&excludeKeywords=陳旧性
// url: `/concierge/search?modality=${modality}&method=${method}&locations=${locations}&ageMin=${ageMin}&ageMax=${ageMax}&sex=${sex}&positiveKeywords=${positiveKeywords}&negativeKeywords${negativeKeywords}=&excludeKeywords=${excludeKeywords}`,
/**
 * API request to medical search.
 * Prepend /api to start of url
 */
const searchConciergeUrl = async url => {
  const apiUrl = '/api' + url;
  try {
    const result = await authRequest({
      method: 'GET',
      url: apiUrl,
    });
    return result;
  } catch (error) {
    console.error(error);
  }
};

const medSearchQueryString = searchJSON => {
  // Destructure the properties from the input object
  let {
    modality,
    method,
    locations,
    ageMin,
    ageMax,
    sex,
    positiveKeywords,
    negativeKeywords,
    excludeKeywords,
    startDate,
    endDate,
    dicomFilters,
    currentPage,
    pageSize,
  } = searchJSON;

  // Return the URL query string for these params
  // Check if each param has a value
  let query = '';
  if (modality) {
    query += `modality=${modality}&`;
  }
  if (method) {
    query += `method=${method}&`;
  }
  if (locations) {
    query += `locations=${locations}&`;
  }
  if (Number.isInteger(ageMin)) {
    query += `ageMin=${ageMin}&`;
  }
  if (Number.isInteger(ageMax)) {
    query += `ageMax=${ageMax}&`;
  }
  if (sex) {
    query += `sex=${sex}&`;
  }
  if (positiveKeywords) {
    query += `positiveKeywords=${positiveKeywords}&`;
  }
  if (negativeKeywords) {
    query += `negativeKeywords=${negativeKeywords}&`;
  }
  if (excludeKeywords) {
    query += `excludeKeywords=${excludeKeywords}&`;
  }
  if (startDate) {
    query += `startDate=${startDate}&`;
  }
  if (endDate) {
    query += `endDate=${endDate}&`;
  }
  if (Number.isInteger(currentPage)) {
    query += `currentPage=${currentPage}&`;
  }
  if (Number.isInteger(pageSize)) {
    query += `pageSize=${pageSize}&`;
  }

  // Iterate over dicomFilters and append to query
  for (const name in dicomFilters) {
    const val = dicomFilters[name];
    if (val) {
      query += `${name}=${val}&`;
    }
  }
  return query;
};

const medSearchCsv = async searchJSON => {
  // Get the usual search query string, add a csv param
  let query = medSearchQueryString(searchJSON);
  query += 'csv=1&';
  const url = `/api/medical-search/search?${query}`;
  console.log('medSearchCsv', url);

  const response = await authRequest({
    method: 'GET',
    url: url,
    // modify to include responseType: 'blob'
    responseType: 'blob',
  })
    .then(response => {
      console.log('medSearchCsv', response);

      // https://bobbyhadz.com/blog/download-file-using-axios
      // Handle the response as a file download
      const href = window.URL.createObjectURL(response.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = 'データ出力.csv';

      document.body.appendChild(anchorElement);
      console.log('Starting download...');
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);

      // return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const searchConcierge = async searchJSON => {
  const query = medSearchQueryString(searchJSON);
  const url = `/medical-search/search?${query}`;

  // Update the URL in the browser
  if (window.history && window.history.pushState) {
    const newUrl = window.location.origin + url;
    window.history.pushState(null, null, newUrl);
  }

  const response = await searchConciergeUrl(url);
  console.log(response.data);
  return response.data;
};

export {
  medSearchQueryString,
  medSearchCsv,
  searchConcierge,
  searchConciergeUrl,
  checkConciergeAccess,
};
