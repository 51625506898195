import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';

import html2canvas from 'html2canvas';

var QRCode = require('qrcode.react');

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  div: {
    padding: theme.spacing(2),
  },
}));

const useQRCodeModal = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState('');

  const openQRCodeModal = url => {
    setUrl(url);
    setOpen(open => true);
  };
  const closeQRCodeModal = () => {
    setOpen(open => false);
  };

  const saveAsImage = uri => {
    const downloadLink = document.createElement('a');

    if (typeof downloadLink.download === 'string') {
      downloadLink.href = uri;

      // ファイル名
      downloadLink.download = 'QRcode.png';

      // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
      document.body.appendChild(downloadLink);

      // ダウンロードリンクが設定された a タグをクリック
      downloadLink.click();

      // Firefox 対策で追加したリンクを削除しておく
      document.body.removeChild(downloadLink);
    } else {
      window.open(uri);
    }
  };

  const onClickExport = () => {
    // 画像に変換する component の id を指定
    const target = document.getElementById('QRcode-component');
    html2canvas(target).then(canvas => {
      const targetImgUri = canvas.toDataURL('img/png');
      saveAsImage(targetImgUri);
    });
  };

  const onClickCopy = () => {
    const target = document.getElementById('QRcode-component');
    html2canvas(target).then(canvas => {
      canvas.toBlob(function(blob) {
        navigator.clipboard.write([
          new ClipboardItem(
            Object.defineProperty({}, blob.type, {
              value: blob,
              enumerable: true,
            })
          ),
        ]);
      });
    });
  };

  function QRCodeModal() {
    return (
      <>
        <Dialog
          open={open}
          onClose={closeQRCodeModal}
          className={classes.modal}
        >
          <div className={classes.div} id="QRcode-component">
            <QRCode value={url} />
          </div>
          <Button onClick={onClickCopy}>画像をコピー</Button>
          <Button onClick={onClickExport}>画像をダウンロード</Button>
        </Dialog>
      </>
    );
  }

  return [QRCodeModal, openQRCodeModal];
};

export default useQRCodeModal;
