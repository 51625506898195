import React, { useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
// import './ErrorFallback.css';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { removeOrgLastUrl } from '../../../../viewer/src/utils/orgLastUrl';
import { AccessDenied } from './AccessDenied';
import { DataNotFound } from './DataNotFound';
import AppThemeProvider from '@ohif/viewer/src/providers/AppThemeProvider';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log(error);
  console.log(error.response);
  const classes = useStyles();

  useEffect(() => {
    removeOrgLastUrl();
    return () => {};
  }, []);

  return (
    <AppThemeProvider>
      <div className="ErrorFallback" role="alert">
        {error && error.response ? (
          error.response.status == 403 ? (
            <AccessDenied />
          ) : error.response.status == 404 ? (
            <DataNotFound />
          ) : (
            <>
              <p>通信でエラーが発生しました。</p>
              <Button
                variant="outlined"
                color="primary"
                onClick={resetErrorBoundary}
              >
                ページを再読み込み{' '}
              </Button>
              <Button variant="outlined" color="primary" href="/">
                トップに戻る
              </Button>
            </>
          )
        ) : (
          <>
            <p>アプリ内でエラーが発生しました。</p>
            <Button
              variant="outlined"
              color="primary"
              onClick={resetErrorBoundary}
            >
              ページを再読み込み{' '}
            </Button>
            <Button variant="outlined" color="primary" href="/">
              トップに戻る
            </Button>
          </>
        )}
      </div>
    </AppThemeProvider>
  );
};

const AppErrorBoundary = ({
  context = 'App',
  onReset = () => {
    window.location.reload();
  },
  onError = () => {},
  fallbackComponent,
  children,
}) => {
  const { reset } = useQueryErrorResetBoundary();

  const onErrorHandler = (error, componentStack) => {
    console.error(`${context} Error Boundary`, error, componentStack);
    onError(error);
  };

  const onResetHandler = () => {
    reset();
    onReset();
  };

  return (
    <ErrorBoundary
      FallbackComponent={fallbackComponent || ErrorFallback}
      onReset={onResetHandler}
      onError={onErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};

AppErrorBoundary.propTypes = {
  context: PropTypes.string,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
  ]),
};

export default AppErrorBoundary;
