import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useOrgRootList } from '../../organization/api/getOrgRootList';
import { useFolderInfo } from './getFolderInfo';
import { useIsCheck } from '../utils/useParams';

export const useCurrentDataList = ({ allPerm = false } = {}) => {
  // 現在地のテーブルに表示するためのデータ一覧を取得する。読込中はundefined
  const folderInfo = useFolderInfo({ useErrorBoundary: true });
  const rootList = useOrgRootList({ useErrorBoundary: true });
  const { isRootPage } = useIsCheck();
  const rootPermName = useSelector(state => state.storageManager.rootPermName);

  if (isRootPage()) {
    if (rootList.isLoading) {
      return undefined;
    } else {
      if (allPerm) {
        let arr = [];
        Object.keys(rootList.data).forEach(key => {
          arr = arr.concat(rootList.data[key]);
        });
        console.log(arr);
        return arr;
      } else {
        return rootList.data[rootPermName];
      }
    }
  } else {
    if (folderInfo.isLoading || folderInfo.data === undefined) {
      return undefined;
    } else {
      console.debug('Got folderInfo', folderInfo.data);
      return folderInfo.data.data;
    }
  }
};
