import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';

import { grey, indigo } from '@material-ui/core/colors';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const useStyles = makeStyles(theme => ({
  grey: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
  },
  indigo: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

function UserAvatarNoBadge({ user, isSender = false }) {
  const classes = useStyles();
  return (
    <>
      {user['username'] &&
      typeof user['username'] === 'string' &&
      user['username'].length > 0 ? (
        <MuiTooltip title={user['username']}>
          <Avatar className={isSender ? classes.indigo : classes.grey}>
            {user['username'][0].toUpperCase()}
          </Avatar>
        </MuiTooltip>
      ) : user['email'] &&
        typeof user['email'] === 'string' &&
        user['email'].length > 0 ? (
        <MuiTooltip title={user['email']}>
          <Avatar className={isSender ? classes.indigo : classes.grey}>
            {user['email'][0].toUpperCase()}
          </Avatar>
        </MuiTooltip>
      ) : (
        <MuiTooltip title="匿名ユーザー">
          <Avatar className={isSender ? classes.indigo : classes.grey}>
            <PermIdentityIcon />
          </Avatar>
        </MuiTooltip>
      )}
    </>
  );
}
UserAvatarNoBadge.propTypes = {
  user: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
};

export function UserAvatar({ user, isSender = false }) {
  return (
    <>
      {user && (
        <>
          {user.is_sender ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              <UserAvatarNoBadge user={user} isSender={isSender} />
            </StyledBadge>
          ) : (
            <UserAvatarNoBadge user={user} isSender={isSender} />
          )}
        </>
      )}
    </>
  );
}
UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
};
