import React from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import GifIcon from '@material-ui/icons/Gif';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import MovieCreationOutlinedIcon from '@material-ui/icons/MovieCreationOutlined';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import { filetypeGroup } from './checkData';

// import DICOM from './icons';
import {
  DicomIcon,
  FolderIcon,
  PDFIcon,
  ImageIcon,
  OtherIcon,
  UnknownIcon,
  CircleInfoIcon,
  ColumnSettingsIcon,
  CRIcon,
  CTIcon,
  LinkIcon,
  MRIcon,
  VideoIcon,
} from './icons/CustomIcons';


/**
 * This file provides icons used in the S9 file manager.
 * It does NOT include icons used in the OHIF viewer.
 * OHIF icons are at Viewers/platform/ui/src/elements/Icon/getIcon.js
 */

function getIconSVG(type) {
  const icon = '';
  switch (type) {
    case 'study':
      icon = DICOM;
  }
  return (
    <img src={icon} />
  );
}

export function getIcon(data) {
  // console.log('getIcon', data);
  switch (data.object_type) {
    case 'folder':
      switch (data.foldertype) {
        case 'study':
          // return <AssignmentIndOutlinedIcon />;
          // return getIconSVG('study');
          return <DicomIcon />;
        case 'series':
          return <DicomIcon />;
        case 'imgSeries':
          return <DicomIcon />;
        default:
          // return <FolderOutlinedIcon />;
          return <FolderIcon />;
      }
    case 'file':
      switch (filetypeGroup(data.filetype)) {
        case 'image':
          return <ImageIcon />;
        case 'pdf':
          return <PDFIcon />;
        case 'gif':
          return <GifIcon />;
        case 'video':
          return <VideoIcon />;
        case 'audio':
          return <AudiotrackOutlinedIcon />;
        case 'text':
          return <TextFieldsIcon />;
        case 'url':
          return <LinkIcon />;
        case 'other':
          return <OtherIcon />;
        default:
          return <UnknownIcon />;
      }
    default:
      return <UnknownIcon />;
  }
}
