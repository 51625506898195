import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../../../../providers/UserContext';
import { FormDialog } from '../../../../components/FormDialog';
import { useAsyncError } from '../../../../api/error';
import { patchUsername, patchEmail } from '../../../../api/rest';
import { SnackbarContext } from '../../../../context/SnackbarContext';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  section: {
    margin: theme.spacing(2, 0),
  },
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
  },
}));



function AccountInfo() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  return (
    <>
      <Box className={classes.section}>
        <Typography>アカウント情報</Typography>
        <Typography color="textSecondary">
          ユーザー名：{user.username}
        </Typography>
        <Typography color="textSecondary">
          メールアドレス：{user.email}
        </Typography>
      </Box>
    </>
  );
}

// Userの名前変更のセクション
function RenameUserSection({ isDisabled }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const [open, setOpen] = React.useState(false);
  const { user, setUser } = useContext(UserContext);

  const handleClick = useCallback(
    name => async () => {
      try {
        const response = await patchUsername(name);
        // response.then(res => {
          console.debug(response.data);
          setUser(response.data);
          setOpen(false);
        // })
        // response.catch(err => {
        //   console.log(response.data);
        // })

      } catch (e) {
        console.log('Error in patchUsername');
        throwError(e);
      }
    },
    [setOpen, setUser, throwError]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Box className={classes.section}>
        <Typography>ユーザー名を変更する</Typography>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isDisabled}
        >
          変更する
        </Button>
      </Box>
      <FormDialog
        open={open}
        setOpen={setOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        title="ユーザー名を変更"
        initialState={user.username}
      />
    </>
  );
}
RenameUserSection.propTypes = {
  isDisabled: PropTypes.bool,
};


// Userのメールアドレスを変更のセクション
function ChangeEmailSection({ isDisabled }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const [open, setOpen] = React.useState(false);
  const { user, setUser } = useContext(UserContext);

  const {
    setSnackbarMessage,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
    setProgressValue,
  } = useContext(SnackbarContext);

  const handleClick = useCallback(
    newEmail => async () => {
      try {
        setProgressValue(-1); // progress は表示しない
        setOpenSnackbar(true);
        setSnackbarMessage(newEmail+'に確認ようのメールを送ります...');
        const response = await patchEmail(newEmail)
        // Email update was successful
        .then(res => {
          console.debug(res.data);
          // Update display of user information
          setUser(res.data);
          setSnackbarMessage('メールを送りしました');
          setOpen(false);
        })
        // Error in email update
        .catch(err => {
          console.log('Outer', err);
          console.log(err.response.data);
          setOpenSnackbar(false);
          if ('detail' in err.response.data) {
            toast.error(
              err.response.data['detail']
            );
            // alert(err.response.data['detail']);
          }
        });

      } catch (e) {
        console.error('error in patchEmail');
        console.error(e);
        throwError(e);
      }
    },
    [setOpen, setUser, throwError]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Box className={classes.section}>
        <Typography>メールアドレスを変更する</Typography>

        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isDisabled}
        >
          変更する
        </Button>
      </Box>
      <FormDialog
        open={open}
        setOpen={setOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        title="メールアドレスを変更する"
        initialState={user.email}
      />
    </>
  );
}
ChangeEmailSection.propTypes = {
  isDisabled: PropTypes.bool,
};

const ChangePasswordSection = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.section}>
      <Typography>パスワードを変更する</Typography>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={() => {
          props.setOpen(false);
          history.push('/pw-reset');
        }}
      >
        変更する
      </Button>
    </Box>
  );
};

function AccountPage(props) {
  return (
    <>
      <Typography>アカウント</Typography>
      <Typography color="textSecondary">アカウントの設定を行います</Typography>
      <AccountInfo />
      <RenameUserSection />
      <ChangeEmailSection />
      <ChangePasswordSection setOpen={props.setOpen}/>
    </>
  );
}

export default AccountPage;
