import React, { createContext, useContext, useState } from 'react';

/**
 * A Provider component to manage the state of checked items
 * in the file manager (AG grid rows). Allow the creation of a
 * single URL that loads multiple studies (any checked
 * studies).
 */

const MultiUrlContext =  createContext();

export const MultiUrlProvider = ({ children }) => {
  const [checkedStudyIDs, setCheckedStudyIDs] = useState(new Set());

  return (
    <MultiUrlContext.Provider value={
        { checkedStudyIDs, setCheckedStudyIDs }
      }>
      {children}
    </MultiUrlContext.Provider>
  );
};

export const useMultiUrl = () => useContext(MultiUrlContext);
