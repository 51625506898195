import { useSelector } from 'react-redux';

export const useUsedFolderUUID = (folderUUID = '') => {
  // folderUUID が空なら currentFolderUUID, そうでなければfolderUUIDを返す
  const currentFolderUUID = useSelector(
    state => state.storageManager.currentFolderUUID
  );

  let usedFolderUUID;
  if (folderUUID == undefined || folderUUID == null || folderUUID == '') {
    usedFolderUUID = currentFolderUUID;
  } else {
    usedFolderUUID = folderUUID;
  }
  return usedFolderUUID;
};
