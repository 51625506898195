import { filetypeGroup } from './checkData';

const getFileUrl = (filetype, fileUUID, withBase = false) => {
  const groupName = filetypeGroup(filetype);

  let url = '';
  if (groupName == 'dicom') {
    url = `/viewer?url=/api/datamanage/dcm/file/${fileUUID}`;
  } else if (groupName == 'pdf') {
    url = `/pdf/${fileUUID}`;
  } else if (groupName == 'image') {
    url = `/img/${fileUUID}`;
  } else if (groupName == 'video' || groupName == 'audio') {
    url = `/video/${fileUUID}`;
  } else if (groupName == 'text') {
    url = `/text/${fileUUID}`;
  } else {
    url = `/text/${fileUUID}`;
  }
  if (withBase) {
    return `${window.location.origin}` + url;
  } else {
    return url;
  }
};

export { getFileUrl };
