/**
 * Annotation element displayed in the right drawer in OHIF viewer.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '../../contextProviders';

import { Icon } from './../../elements/Icon';
import { TableListItem } from './../tableList/TableListItem.js';
import CommentForm from './../comment/CommentForm.js';
import { DateExtract } from './../../../../viewer/src/utils/dataProcess.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import './MeasurementTableItem.styl';

class MeasurementTableItem extends Component {
  static propTypes = {
    measurementData: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onRelabel: PropTypes.func,
    onDelete: PropTypes.func,
    onEditDescription: PropTypes.func,
    itemClass: PropTypes.string,
    itemIndex: PropTypes.number,
    onItemSideClick: PropTypes.func,
    messageInput: PropTypes.string,
    messageInputChange: PropTypes.func,
    t: PropTypes.func,
  };

  state = {
    showDetails: false,
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    })
    console.debug('showDetails was set:', this.state.showDetails);
  }

  render() {
    //const { warningTitle = '', hasWarnings } = this.props.measurementData;

    return (
      <React.Fragment>
        {/*hasWarnings ? (
          <OverlayTrigger
            key={this.props.itemIndex}
            placement="left"
            overlay={
              <Tooltip
                placement="left"
                className="in tooltip-warning"
                id="tooltip-left"
              >
                <div className="warningTitle">{this.props.t(warningTitle)}</div>
                <div className="warningContent">{this.getWarningContent()}</div>
              </Tooltip>
            }
          >
            <div>{this.getTableListItem()}</div>
          </OverlayTrigger>
        ) : (
          <React.Fragment>{this.getTableListItem()}</React.Fragment>
        )*/}
        <React.Fragment>{this.getTableListItem()}</React.Fragment>
      </React.Fragment>
    );
  }

  getActionButton = (btnLabel, onClickCallback) => {
    return (
      <button key={btnLabel} className="btnAction" onClick={onClickCallback}>
        <span style={{ marginRight: '4px' }}>
          <Icon name="edit" width="14px" height="14px" />
        </span>
        {this.props.t(btnLabel)}
      </button>
    );
  };

  getTableListItem = () => {
    const hasWarningClass = this.props.measurementData.hasWarnings
      ? 'hasWarnings'
      : '';

    const actionButtons = [];

    if (typeof this.props.onRelabel === 'function') {
      const relabelButton = this.getActionButton(
        'Relabel',
        this.onRelabelClick
      );
      // relabel Button を表示しないように
      // actionButtons.push(relabelButton);
    }
    if (typeof this.props.onEditDescription === 'function') {
      const descriptionButton = this.getActionButton(
        '編集',
        this.onEditDescriptionClick
      );
      actionButtons.push(descriptionButton);
    }
    if (typeof this.props.onDelete === 'function') {
      const deleteButton = this.getActionButton(
        '削除', this.onDeleteClick
      );
      actionButtons.push(deleteButton);
    }

    // console.log(this.props);
    const annoMain = this.props.measurementData.data[0].displayText;
    console.debug(annoMain);

    return (
      // Detailed annotation item (shown in right drawer)
      <TableListItem
        itemKey={this.props.measurementData.measurementNumber}
        itemClass={`measurementItem ${this.props.itemClass} ${hasWarningClass}`}
        // Index of the annotation (1, 2, 3...)
        itemIndex={this.props.itemIndex}
        onItemClick={this.onItemClick}
        onItemSideClick={this.onItemSideClick}
      >
        <div>
          {/* Main annotation label (created when the annotation was added) */}
          <div
            className="displayTexts"
            onDoubleClick={
              console.log(

            )}
          >
            {this.getDataDisplayText()}
          <button
            onClick={this.toggleDetails}
            className="toggle-anno-btn pull-right"
            >{ this.state.showDetails ? (
              <ExpandLessIcon style={{ fontSize: '16px' }}/>
            ):(
              <ExpandMoreIcon style={{ fontSize: '16px' }}/>
            )}</button>
          </div>
          { this.state.showDetails ? (
            <div>
              {/* Secondary annotation description (label, メモ) displayed just below
              the main annotation label  */}
              <div className="measurementLocation">
                {this.props.t(this.props.measurementData.label, {
                  keySeparator: '>',
                  nsSeparator: '|',
                })}
              </div>
              {/* Date and time the annotation was created & updated  */}
              <div className="measurementLocation">{this.getUserDisplayText()}</div>

              {/* Actions at bottom of each annotation (メモ、削除) */}
              <div className="rowActions">{actionButtons}</div>
            </div>
            ) : (<div></div>)
          }
          {/* Additional comments for each annotation (not used in S9) */}
          {/* <div className="comment">
              <CommentForm
                messageInput={this.props.messageInput}
                messageInputChange={this.messageInputChange}
              />
            </div> */}

        </div>
      </TableListItem>
    );
  };

  onItemClick = event => {
    this.props.onItemClick(event, this.props.measurementData);
  };

  onItemSideClick = event => {
    this.props.onItemSideClick(event, this.props.measurementData);
  };

  messageInputChange = message => {
    this.props.messageInputChange(this.props.measurementData.uuid, message);
  };

  onRelabelClick = event => {
    // Prevent onItemClick from firing
    event.stopPropagation();

    this.props.onRelabel(event, this.props.measurementData);
  };

  onEditDescriptionClick = event => {
    // Prevent onItemClick from firing
    event.stopPropagation();

    this.props.onEditDescription(event, this.props.measurementData);
  };

  onDeleteClick = event => {
    // Prevent onItemClick from firing
    event.stopPropagation();

    this.props.onDelete(event, this.props.measurementData);
  };

  getDataDisplayText = () => {
    return this.props.measurementData.data.map((data, index) => {
      console.debug('Anno data', data);
      return (
        <div key={`displayText_${index}`} className="measurementDisplayText">
          {data.displayText ? data.displayText : '...'}
        </div>
      );
    });
  };

  getUserDisplayText = () => {
    const getUserName = user => {
      if (typeof user === 'undefined') {
        return '';
      }
      if (user.id) {
        return user.username;
      } else {
        return 'anonymous';
      }
    };
    return (
      <>
        <div>
          作成日: {DateExtract(this.props.measurementData.addedAt, true)}
        </div>
        <div>作成者: {getUserName(this.props.measurementData.addedBy)}</div>
        <div>
          修正日: {DateExtract(this.props.measurementData.updatedAt, true)}
        </div>
        <div>修正者: {getUserName(this.props.measurementData.updatedBy)}</div>
      </>
    );
  };

  getWarningContent = () => {
    const { warningList = '' } = this.props.measurementData;

    if (Array.isArray(warningList)) {
      const listedWarnings = warningList.map((warn, index) => {
        return <li key={index}>{warn}</li>;
      });

      return <ol>{listedWarnings}</ol>;
    } else {
      return <React.Fragment>{warningList}</React.Fragment>;
    }
  };
}

const connectedComponent = withTranslation('MeasurementTable')(
  MeasurementTableItem
);
export { connectedComponent as MeasurementTableItem };
export default connectedComponent;
