import { addMimeTypeToFiles } from './addMimeTypeToFiles';
import { extractStudyMetadataFromPaths } from './extractStudyMetadataFromPaths';
import { extractDuplication } from './extractDuplication';
import { UploadProgressManager } from './UploadProgressManager';
import { UploadService } from './Uploadservice';

class UploadCancelError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UploadCancelError';
  }
}

export {
  addMimeTypeToFiles,
  extractStudyMetadataFromPaths,
  UploadCancelError,
  extractDuplication,
  UploadProgressManager,
  UploadService,
};
