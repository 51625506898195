import { useCallback, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';

export const useCurrentFolderUUID = () => {
  return useSelector(state => state.storageManager.currentFolderUUID);
};

export const useSetCurrentFolderUUID = () => {
  const dispatch = useDispatch();
  return useCallback(
    uuid => {
      dispatch({ type: 'SET_CURRENT_FOLDER_UUID', state: uuid });
    },
    [dispatch]
  );
};

export const useSetCurrentOrgUUID = () => {
  const dispatch = useDispatch();
  return useCallback(
    uuid => {
      dispatch({ type: 'SET_CURRENT_ORG_UUID', state: uuid });
    },
    [dispatch]
  );
};

export const useSetTutorialId = () => {
  const dispatch = useDispatch();
  return useCallback(
    tutorial_id => {
      dispatch({ type: 'SET_TUTORIAL_ID', state: tutorial_id });
    },
    [dispatch]
  );
};

export const useSetRootPermName = () => {
  const dispatch = useDispatch();
  return useCallback(
    rootPermName => {
      dispatch({ type: 'SET_ROOT_PERM_NAME', state: rootPermName });
    },
    [dispatch]
  );
};

export const useResetStorageManeger = () => {
  const dispatch = useDispatch();
  return useCallback(
    rootPermName => {
      dispatch({ type: 'RESET_STORAGE_MANEGER' });
    },
    [dispatch]
  );
};

export const useIsCheck = () => {
  const currentOrgUUID = useSelector(
    state => state.storageManager.currentOrgUUID
  );
  const tutorialId = useSelector(state => state.storageManager.tutorialId);
  const rootPermName = useSelector(state => state.storageManager.rootPermName);

  const isTutorial = useCallback(() => {
    // /storage/org/:orgId/tutorial/:tutorialId
    return currentOrgUUID !== '' && tutorialId !== -1;
  }, [currentOrgUUID, tutorialId]);

  const isRootPage = useCallback(() => {
    // /storage/org/:orgId/:rootPermName
    return (
      currentOrgUUID !== '' &&
      (rootPermName === 'private' ||
        rootPermName === 'share' ||
        rootPermName === 'org')
    );
  }, [currentOrgUUID, rootPermName]);

  const isFolderPage = useCallback(() => {
    // /storage/org/:orgId/:rootPermName
    return !isRootPage();
  }, [isRootPage]);

  return {
    isTutorial,
    isRootPage,
    isFolderPage,
  };
};
