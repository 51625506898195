import React from 'react';

export const useAsyncError = () => {
  const [_, setError] = React.useState();
  return React.useCallback(
    e => {
      console.log('useAsyncError');
      if (e.response && e.response.status === 403) {
        console.log('403 error');
      } else {
        setError(() => {
          throw e;
        });
      }
    },
    [setError]
  );
};
