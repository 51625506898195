import React, { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import propsTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Loading from '../../../../components/Loading';

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: 0,
  },
  errorMsg: {
    overflow: 'auto',
    color: '#0f0f0f',
    margin: theme.spacing(2),
  },
}));

export const DataLoadingComponent = () => {
  const classes = useStyles();
  return (
    <>
      <Toolbar className={classes.toolbar} />
      <Loading />
    </>
  );
};

export function DataViewerErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();

  useEffect(() => {
    console.log(error);
    console.log(error.message);
    console.log(error.response);
    console.log(error.response.data);
  }, [error]);

  const AccessErrorComponent = (
    <>
      <Toolbar className={classes.toolbar} />
      <Box className={classes.errorMsg}>
        <Typography variant="h6">
          このデータのアクセス権限がありません
        </Typography>
      </Box>
    </>
  );

  const Error404Component = (
    <>
      <Toolbar className={classes.toolbar} />
      <Box className={classes.errorMsg}>
        {' '}
        <Typography variant="h6">このデータは存在しません</Typography>
      </Box>
    </>
  );

  if (error && error.response) {
    if (error.response.status == 403) {
      return AccessErrorComponent;
    } else if (error.response.status == 404) {
      return Error404Component;
    }
  }
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export const DataViewerErrorBoundary = props => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset()}
          FallbackComponent={DataViewerErrorFallback}
        >
          {props.children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

function ErrorFallback({ error, resetErrorBoundary }) {
  console.log(error);
  console.log(error.message);
  console.log(error.response);

  return <></>;
}
export const ApiErrorBoundary = props => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={reset}>
          {props.children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

ApiErrorBoundary.propTypes = {
  children: propsTypes.node,
};
