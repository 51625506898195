const defaultState = {
  users: [],
  sender: {},
  isSender: false,
};

const viewingUserManager = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_VIEWING_USERS':
      return Object.assign({}, state, { users: action.state });
    case 'SET_VIEWING_SENDER':
      return Object.assign({}, state, { sender: action.state });
    case 'SET_VIEWING_IS_SENDER':
      return Object.assign({}, state, { isSender: action.state });
    default:
      return state;
  }
};

export default viewingUserManager;
