import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import LinkIcon from '@material-ui/icons/Link';
import { AiOutlineQrcode } from 'react-icons/ai';

import CopyToClipBoard from 'react-copy-to-clipboard';
import useQRCodeModal from './QRCode';
import { getFileUrl } from '../../utils/getUrls';
import { isMobile } from '../../utils/detectDevice';
import { filetypeGroup } from '../../utils/checkData';
import { generateUniviewerUrl } from '../univiewer/UniversalViewerContainer';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    padding: theme.spacing(1),
  },
  flexLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function ShareLink({ type, data }) {
  const classes = useStyles();

  const [openTipViewer, setOpenTipViewer] = useState(false);
  const [openTipStorage, setOpenTipStorage] = useState(false);

  const [usedUrl, setUsedUrl] = useState('');
  const [QRCodeModal, openQRCodeModal] = useQRCodeModal();

  useEffect(() => {
    if (type == 'folder') {
      if (data.object_type === 'folder') {
        if (
          data.foldertype === 'study' ||
          data.foldertype === 'series' ||
          data.foldertype === 'imgSeries'
        ) {
          const univiewerUrl = generateUniviewerUrl(data);
          console.log('Shared univiewerUrl', univiewerUrl);
          setUsedUrl(
            univiewerUrl
          );
        } else {
          setUsedUrl(`${window.location.origin}/storage/${data.uuid}`);
        }
      }
    } else if (type == 'file') {
      if (filetypeGroup(data.filetype) == 'url') {
        setUsedUrl(data.url);
      } else {
        setUsedUrl(getFileUrl(data.filetype, data.uuid, true));
      }
    }
  }, [data, data.filetype, data.uuid, type]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box className={classes.flexLeft}>
        <EmailShareButton url={usedUrl} className={classes.button}>
          <EmailIcon className="shareIcon" round size={32} />
        </EmailShareButton>

        <FacebookShareButton url={usedUrl} className={classes.button}>
          <FacebookIcon className="shareIcon" round size={32} />
        </FacebookShareButton>

        <LineShareButton url={usedUrl} className={classes.button}>
          <LineIcon className="shareIcon" round size={32} />
        </LineShareButton>
      </Box>
      <Box className={classes.flexRight}>
        <Button
          className={classes.button}
          startIcon={<AiOutlineQrcode />}
          onClick={() => openQRCodeModal(usedUrl)}
        >
          {!isMobile() && 'QRコードを表示'}
        </Button>
        <Tooltip
          arrow
          open={openTipStorage}
          onClose={() => setOpenTipStorage(false)}
          disableHoverListener
          placement="top"
          title="Copied!"
        >
          <CopyToClipBoard text={usedUrl}>
            <Button
              className={classes.button}
              startIcon={<LinkIcon />}
              onClick={() => setOpenTipStorage(true)}
            >
              {!isMobile() && 'リンクをコピー'}
            </Button>
          </CopyToClipBoard>
        </Tooltip>
      </Box>
      <QRCodeModal />
    </Box>
  );
}

ShareLink.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
