import dcmjs from 'dcmjs';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import FileLoader from './fileLoader';
import OHIF from '@ohif/core';
import Encoding from 'encoding-japanese';
import {
  digestMessage,
  replaceZeroLength,
  replaceANONYMIZED,
  replaceANON,
  replaceHash,
  elementDrop,
} from '../../features/annonymization/anonymizeUtils';
import { DICOM_METADATA_KEYS } from '../../utils/constants';
const metadataProvider = OHIF.cornerstone.metadataProvider;

function toBinaryArray(tagData) {
  // tagData is guaranteed to be a string
  const binArray = tagData.split('').map(function(c) {
    return c.charCodeAt(0);
  });
  // console.debug('toBinaryArray', binArray);
  return binArray;
}

const DICOMFileLoader = new (class extends FileLoader {
  fileType = 'application/dicom';
  loadFile(file, imageId) {
    return cornerstoneWADOImageLoader.wadouri.loadFileRequest(imageId);
  }

  getDataset(
    image,
    imageId,
    anonymizedStudies = [],
    isEdit = false,
    isAnonymize = false
  ) {
    let dataset = {};
    try {
      const dicomData = dcmjs.data.DicomMessage.readFile(image, {
        ignoreErrors: true,
      });
      // console.log('FileLoader dicomData', dicomData);
      // TODO: delete all private tags (as Javascript properties)

      if (isAnonymize) {
        // private tagの削除
        for (const key of Object.entries(dicomData.dict)) {
          // 8桁の数字
          const tagNumber = key[0];
          console.debug('tagNumber', tagNumber);
          // タグ番号の1桁目を16進数から10進数に変換し偶奇を判別
          if (parseInt(tagNumber[3], 16) % 2 != 0) {
            delete dicomData['dict'][tagNumber];
          }
        }
      }

      dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
        dicomData.dict
      );
      console.debug('FileLoader dataset', dataset);
      for (var prop in dataset) {
        if (prop.length >= 4) {
          var numCode = prop.substring(0, 4);
          // Ignore tags that are NaN such as PixelData, we need to keep these
          if (isNaN(parseInt(numCode))) {
            // console.debug('Ignore non-private tag', prop);
            continue;
          } else {
            // This is a numbered (private) tag, should be deleted
            console.debug('Private tag', prop);
            delete dataset[prop];
          }
        } else {
          console.log('Unexpected tag', prop);
        }
        // console.debug(prop, typeof(prop));
      }

      // 文字コードを自動チェック
      const checkEncodingsList = [
        'PatientName', // PN
        'NameOfPhysiciansReadingStudy', // PN
        'ReferringPhysicianName', // PN
        'PerformingPhysicianName', // PN
        'InstitutionName', // LO
        'ProtocolName', // LO
        'RequestingPhysician',
        'StudyDescription', // LO
        'SeriesDescription', // LO
      ];

      // Check encoding of Dicom metadata field
      // Try to convert everything to Unicode, then to String
      // 日本語の場合はそれぞれの encodings はあります。
      checkEncodingsList.forEach(key => {
        let tagData = dataset[key];
        if (tagData) {
          /**
           * Handle cases where the metadata field may be an array, insted of string
           * Have seen this with PatientName: ['±ÍÞ^ÉÌÞÕ·=\x1B$B0BG', '\x1B(J^\x1B$B?-@,\x1B(J']
           * Decodes to: ｱﾍﾞ^ﾉﾌﾞﾕｷ= $B0BG, (J^ $B?-@, (J
           */

          // console.debug('Decode', key, tagData);
          if (Array.isArray(tagData)) {
            console.warn(key, 'IS AN ARRAY OF VALUES, not str');
            tagData = tagData.toString();
          }
          // WIP here under - testing
          console.log(
            'ISO 2022 IR 87 present ? ',
            dataset['SpecificCharacterSet'].includes('ISO 2022 IR 87')
          );

          // if (
          //   dataset[key] instanceof String &&
          //   dataset['SpecificCharacterSet'].includes('ISO 2022 IR 87')
          // ) {
          //   console.log(
          //     'tagData==================================== 1',
          //     tagData
          //   );
          //   const unicodeArray = Encoding.stringToCode(tagData);
          //   // example of weird encoding of 脳神経　向日葵 in ISO 2022 JP
          //   // "è\u0084³ç¥\u009eçµ\u008cã\u0080\u0080å\u0090\u0091æ\u0097¥è\u0091µ"
          //   // ); // Convert string to code array

          //   const unicodeTagData = Encoding.convert(unicodeArray, {
          //     to: 'UNICODE',
          //     from: 'ISO-2022-JP',
          //   });
          //   const encodedStr = Encoding.codeToString(unicodeTagData);
          //   console.log(
          //     'encodedStr====================================',
          //     encodedStr
          //   );
          //   dataset[key] = encodedStr;
          // } else {
          console.log('tagData==================================== 2', tagData);
          const array = toBinaryArray(tagData);
          var detectedEncoding = Encoding.detect(array);
          const unicodeArray = Encoding.convert(array, {
            to: 'UNICODE',
            from: detectedEncoding,
          });
          const encodedStr = Encoding.codeToString(unicodeArray);
          console.log(
            'encodedStr====================================2 ',
            encodedStr
          );
          dataset[key] = encodedStr;
          // }
        }
      });

      // 匿名化
      if (isAnonymize) {
        // zero length で置き換える
        for (const key of replaceZeroLength) {
          dataset[key] = '';
        }
        // 指定された文字 で置き換える
        for (const key of replaceANONYMIZED) {
          dataset[key] = 'ANONYMIZED';
        }
        for (const key of replaceANON) {
          dataset[key] = `ANON${dataset.StudyID}`;
        }
        // Hash値で置き換える
        // 衝突の可能性や、一部ハッシュ変換など、検討の余地はあり
        for (const key of replaceHash) {
          if (key !== 'StudyInstanceUID') {
            dataset[key] = digestMessage(dataset[key]);
          }
        }
        // プロパティを削除する
        for (const key of elementDrop) {
          delete dataset[key];
        }
      }

      // 編集したタグに上書き
      const studyIndex = anonymizedStudies.findIndex(element => {
        return element['StudyInstanceUID'] == dataset['StudyInstanceUID'];
      });
      if (studyIndex >= 0) {
        Object.entries(anonymizedStudies[studyIndex]).forEach(
          ([key, value]) => {
            if (isAnonymize && key === 'HashedStudyInstanceUID') {
              dataset['StudyInstanceUID'] = value;
            } else if (isAnonymize && key === 'StudyInstanceUID') {
              return;
            } else if (isEdit) {
              dataset[key] = value;
            }
          }
        );
      }

      metadataProvider.addInstance(dataset);

      dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
        dicomData.meta
      );
    } catch (e) {
      console.error('Error reading dicom file', e);
    }
    // Set imageId on dataset to be consumed later on
    dataset.imageId = imageId;
    console.log('isAnonymize', isAnonymize, 'getDataset', dataset);
    return dataset;
  }

  getStudies(dataset, imageId) {
    return this.getStudyFromDataset(dataset);
  }

  getStudyFromDataset(dataset = {}) {
    const {
      StudyInstanceUID,
      StudyDate,
      StudyTime,
      AccessionNumber,
      ReferringPhysicianName,
      PatientName,
      PatientID,
      PatientBirthDate,
      PatientSex,
      StudyID,
      StudyDescription,
      SeriesInstanceUID,
      SeriesDescription,
      SeriesNumber,
      imageId,
    } = dataset;

    const instance = {
      metadata: dataset,
      url: imageId,
    };

    const series = {
      SeriesInstanceUID: SeriesInstanceUID,
      SeriesDescription: SeriesDescription,
      SeriesNumber: SeriesNumber,
      instances: [instance],
    };

    const study = {
      StudyInstanceUID,
      StudyDate,
      StudyTime,
      AccessionNumber,
      ReferringPhysicianName,
      PatientName,
      PatientID,
      PatientBirthDate,
      PatientSex,
      StudyID,
      StudyDescription,
      /*
      TODO: in case necessary to uncomment this block, double check every property
      numberOfStudyRelatedSeries: NumberOfStudyRelatedSeries || DICOMWeb.getString(dataset['00201206']),
      numberOfStudyRelatedInstances: NumberOfStudyRelatedInstances || DICOMWeb.getString(dataset['00201208']),
      Modality: Modality || DICOMWeb.getString(dataset['00080060']),
      ModalitiesInStudy: ModalitiesInStudy || DICOMWeb.getString(dataset['00080061']),
      modalities:
      */
      series: [series],
    };

    return study;
  }

  getStudiesForUpload(dataset, path, filetype, size) {
    return this.getStudyForUpload(dataset, path, filetype, size);
  }

  getStudyForUpload(dataset = {}, path, filetype, size) {
    const {
      StudyInstanceUID,
      StudyDate,
      StudyTime,
      AccessionNumber,
      ReferringPhysicianName,
      PatientName,
      PatientID,
      InstitutionName,
      PatientBirthDate,
      PatientSex,
      StudyID,
      StudyDescription,
      SeriesDescription,
      SeriesInstanceUID,
      SeriesNumber,
      SeriesDate,
      SeriesTime,
      Modality,
      BodyPartExamined,
      ProtocolName,
    } = dataset;

    const use_keys = DICOM_METADATA_KEYS;
    const metadata = {};
    const keys = Object.keys(dataset);
    use_keys.forEach(key => {
      if (keys.includes(key)) {
        metadata[key] = dataset[key];
      }
    });

    const instance = {
      path,
      filetype,
      size,
      metadata,
    };

    const series = {
      SeriesInstanceUID: SeriesInstanceUID,
      SeriesDescription:
        SeriesDescription !== undefined ? SeriesDescription : '',
      SeriesNumber: SeriesNumber !== undefined ? SeriesNumber : '',
      SeriesDate: SeriesDate !== undefined ? SeriesDate : '',
      SeriesTime: SeriesTime !== undefined ? SeriesTime : '',
      Modality: Modality !== undefined ? Modality : '',
      BodyPartExamined: BodyPartExamined !== undefined ? BodyPartExamined : '',
      ProtocolName: ProtocolName !== undefined ? ProtocolName : '',
      instances: [instance],
    };

    const study = {
      StudyInstanceUID,
      StudyDescription: StudyDescription !== undefined ? StudyDescription : '',
      StudyDate: StudyDate !== undefined ? StudyDate : '',
      StudyTime: StudyTime !== undefined ? StudyTime : '',
      StudyID: StudyID !== undefined ? StudyID : '',
      InstitutionName: InstitutionName !== undefined ? InstitutionName : '',
      PatientName: PatientName !== undefined ? PatientName : '',
      PatientID: PatientID !== undefined ? PatientID : '',
      PatientBirthDate: PatientBirthDate !== undefined ? PatientBirthDate : '',
      PatientSex: PatientSex !== undefined ? PatientSex : '',
      AccessionNumber: AccessionNumber !== undefined ? AccessionNumber : '',
      ReferringPhysicianName:
        ReferringPhysicianName !== undefined ? ReferringPhysicianName : '',
      series: [series],
    };

    return study;
  }
})();

export default DICOMFileLoader;
