import './MeasurementTable.styl';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Grid from '@material-ui/core/Grid';
import StopIcon from '@material-ui/icons/Stop';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { UserAvatar } from './../avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {},
  disabledButton: {
    color: 'gray',
  },
}));

const SyncLayoutButton = ({
  sender,
  canComment,
  startViewingSend,
  stopViewingSend,
}) => {
  const classes = useStyles();

  if (Object.keys(sender).length > 0) {
    return (
      <MuiTooltip
        title={canComment ? '画面同期を終了' : '読み取り権限では実行できません'}
      >
        <div>
          {canComment ? (
            <IconButton color="primary" onClick={stopViewingSend}>
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton>
              <StopIcon
                className={clsx(classes.button, {
                  [classes.disabledButton]: !canComment,
                })}
              />
            </IconButton>
          )}
        </div>
      </MuiTooltip>
    );
  } else {
    return (
      <MuiTooltip
        title={canComment ? '画面同期を開始' : '読み取り権限では実行できません'}
      >
        <div>
          {canComment ? (
            <IconButton color="primary" onClick={startViewingSend}>
              <PlayArrowIcon />
            </IconButton>
          ) : (
            <IconButton>
              <PlayArrowIcon
                className={clsx(classes.button, {
                  [classes.disabledButton]: !canComment,
                })}
              />
            </IconButton>
          )}
        </div>
      </MuiTooltip>
    );
  }
};

class LayoutSync extends Component {
  static propTypes = {
    measurementApi: PropTypes.object,
    viewports: PropTypes.object.isRequired,
    viewingUserManager: PropTypes.object.isRequired,
  }

  startViewingSend = () => {
    if (this.props.measurementApi) {
      this.props.measurementApi.startViewingSend(
        this.props.viewingUserManager,
        this.props.viewports
      );
    }
  };
  stopViewingSend = () => {
    if (this.props.measurementApi) {
      this.props.measurementApi.stopViewingSend(
        this.props.viewingUserManager,
        this.props.viewports
      );
    }
  };

  render() {
    // Unwrap the props that were passed
    const {
      measurementApi,
      viewports,
      viewingUserManager,
    } = this.props;
    return (
      <div>
        {measurementApi ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <p>レイアウト 同期 / 閲覧ユーザー</p>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <SyncLayoutButton
                  sender={viewingUserManager.sender}
                  canComment={measurementApi.canComment}
                  startViewingSend={this.startViewingSend}
                  stopViewingSend={this.stopViewingSend}
                />
              </Grid>
              {Object.keys(viewingUserManager.sender).length > 0 && (
                <Grid item>
                  <UserAvatar
                    user={viewingUserManager.sender}
                    isSender={true}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            {Array.isArray(viewingUserManager.users) && (
              <AvatarGroup max={4}>
                {viewingUserManager.users.map((user, index) => {
                  if (viewingUserManager.sender.uuid !== user.uuid) {
                    return <UserAvatar user={user} key={index} />;
                  }
                })}
              </AvatarGroup>
            )}
          </Grid>
          {!measurementApi.canComment && (
            <Grid item xs={12}>
              <p>読み取り権限はアノテーションの保存はできません</p>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <p>プライベートモード</p>
          </Grid>
        </Grid>
      )}
      </div>

    );
  }

}

export default LayoutSync;
