import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CloseIcon from '@material-ui/icons/Close';

import ShareWeb from './ShareWeb';
import ShareLink from './ShareLink.js';
import SharedUser from './SharedUser';
import Loading from '../../components/Loading.js';
import DialogTitle from '../../components/DialogTitle';

import { useAsyncError } from '../../api/error.js';
import {
  changeAnonymousPermApi,
  changeUserPermApi,
  changeOrgPermApi,
  getPermList,
} from '../../api/rest.js';
import { checkFullAccess } from '../../utils/checkAccessPerm';

const useStyles = makeStyles(theme => ({
  card: {
    width: 600,
    // padding: theme.spacing(2),
    // margin: 'auto',
    // textAlign: 'center',
    color: 'black',
    backgroundColor: '#F0F0F0',
    borderRadius: '10px',
  },
  title: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
  grey: {
    color: theme.palette.grey[700],
  },
  flexLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

/*
 data: folderかfileのデータ
*/
export default function ShareCard({ data, closeShareModal }) {
  const classes = useStyles();
  const throwError = useAsyncError();

  const uuid = data.uuid;

  const [isLoading, setIsLoading] = React.useState(false);
  const [cannotView, setCannotView] = React.useState(false);
  const [permData, setPermData] = React.useState({
    anonymous_perm: '',
    expiry: '', // 公開リンクの有効期限
    is_permanent: false, // 無期限リンクのboolean
    your_perm: '',
    own_org: {},
    users: [],
    orgs: [],
  });
  const [fullAccess, setFullAccess] = React.useState(false);

  const fetchPermData = useCallback(async () => {
    if (uuid !== '') {
      try {
        const res = await getPermList(data.object_type, uuid);
        console.log(res.data);
        setPermData(res.data);
      } catch (e) {
        throwError(e);
      }
    }
  }, [data.object_type, throwError, uuid]);

  useEffect(() => {
    fetchPermData();
  }, [fetchPermData]);

  useEffect(() => {
    setFullAccess(
      checkFullAccess(permData.your_perm) ||
        checkFullAccess(permData.anonymous_perm)
    );
  }, [permData.your_perm, permData.anonymous_perm]);

  const changeWebPerm = useCallback(
    async (newPerm, expiryDate) => {
      try {
        console.log('newPerm', newPerm, 'expiryDate', expiryDate);
        setIsLoading(true);
        await changeAnonymousPermApi(
          data.object_type,
          uuid,
          newPerm,
          expiryDate
        );
        await fetchPermData();
        setIsLoading(false);
      } catch (err) {
        if ('detail' in err.response.data) {
          setIsLoading(false);
          alert(err.response.data['detail']);
        } else {
          throwError(err);
        }
      }
    },
    [data.object_type, fetchPermData, throwError, uuid]
  );

  const changeUserPerm = useCallback(
    // userの権限を変更して更新
    async (user, newPerm, isSend = false, message = '') => {
      try {
        setIsLoading(true);
        await changeUserPermApi(
          data.object_type,
          uuid,
          user.email,
          newPerm,
          isSend,
          data,
          message
        );
        await fetchPermData();
        setIsLoading(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          'detail' in err.response.data
        ) {
          setIsLoading(false);
          alert(err.response.data['detail']);
        } else {
          throwError(err);
        }
      }
    },
    [data, fetchPermData, throwError, uuid]
  );

  const changeOrgPerm = useCallback(
    // orgの権限を変更して更新
    async (org, newPerm) => {
      try {
        setIsLoading(true);
        await changeOrgPermApi(data.object_type, uuid, org.uuid, newPerm);
        await fetchPermData();
        setIsLoading(false);
      } catch (err) {
        if ('detail' in err.response.data) {
          setIsLoading(false);
          alert(err.response.data['detail']);
        } else {
          throwError(err);
        }
      }
    },
    [data.object_type, fetchPermData, throwError, uuid]
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {cannotView ? (
            <>
              <Typography variant={'h6'}>閲覧権限がありません</Typography>
            </>
          ) : (
            <>
              <DialogTitle onClose={closeShareModal}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                >
                  <Tab label="招待" />
                  <Tab label="Webで公開" />
                </Tabs>
              </DialogTitle>

              <DialogContent dividers>
                {value === 0 ? (
                  <SharedUser
                    data={data}
                    permData={permData}
                    changeUserPerm={changeUserPerm}
                    changeOrgPerm={changeOrgPerm}
                    isDisabled={!fullAccess}
                  />
                ) : (
                  <ShareWeb
                    perm={permData.anonymous_perm}
                    expiry={permData.expiry}
                    isPermanent={permData.is_permanent}
                    changePerm={changeWebPerm}
                    isDisabled={!fullAccess}
                  />
                )}
              </DialogContent>
              <DialogContent>
                <ShareLink type={data.object_type} data={data} />
              </DialogContent>
            </>
          )}
        </>
      )}
    </>
  );
}

ShareCard.propTypes = {
  data: PropTypes.object.isRequired,
  closeShareModal: PropTypes.func.isRequired,
};
