/* データベース上のdicomメタデータを編集するモーダル（GCS上のデータは編集されない）
 */

import React, { useEffect, useCallback } from 'react';
import { authRequest } from '../../../../api/auth';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// メタデータ編集画面コンポーネント、openにする関数を返す
const useEditMetaModal = () => {
  {
    /*
    11/20
    現時点で実装できていること
    uuidで指定したのfolderの情報を一部書き換える

    未実装
    folder内の各DICOMfileにあるmetadataを一斉に書き換える
    メタデータを編集した際、画面が再描画されるようにする
  */
  }
  const [open, setOpen] = React.useState(false);
  const [folderUUID, setFolderUUID] = React.useState('');
  const openEditMeta = uuid => {
    setFolderUUID(uuid);
    setOpen(open => true);
  };
  const closeEditMeta = () => setOpen(open => false);

  function EditMetaModal() {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    // 取得したfolderデータを保存
    const [folderData, setFolderData] = React.useState({});

    // 入力したデータを保存
    const [name, setName] = React.useState('');
    const handleChangeName = event => {
      setName(event.target.value);
    };

    const [date, setDate] = React.useState('');
    const handleChangeDate = event => {
      setDate(event.target.value);
    };

    const [modality, setModality] = React.useState('');
    const handleChangeModality = event => {
      setModality(event.target.value);
    };

    const [sex, setSex] = React.useState('');
    const handleChangeSex = event => {
      setSex(event.target.value);
    };

    // 取得データの保存+初期値の設定
    const setDefault = data => {
      setFolderData(data);
      setName(data.name);
      if (data.foldertype == 'study') {
        setDate(data.StudyDate);
      } else if (data.foldertype == 'series') {
        setDate(data.SeriesDate);
      }
      setModality(data.Modality);
      setSex(data.PatientSex);
    };

    // フォルダの情報を取得する
    const fetchFolderInfo = useCallback(uuid => {
      if (uuid == '') return;
      const url = `/api/datamanage/detail/folder/${uuid}`;
      authRequest
        .get(url)
        .then(res => {
          setDefault(res.data);
        })
        .catch(error => console.error(error));
    }, []);

    useEffect(() => {
      fetchFolderInfo(folderUUID);
    }, [fetchFolderInfo]);

    // submitボタンのハンドラ
    // 取得データを上書きしてそのままpatch
    const submitData = () => {
      const url = `/api/datamanage/detail/folder/${folderUUID}`;
      let newData;
      if (folderData.foldertype == 'study') {
        newData = {
          ...folderData,
          name: `${name}`,
          StudyDate: `${date}`,
          Modality: `${modality}`,
        };
      } else if (folderData.foldertype == 'series') {
        newData = {
          ...folderData,
          name: `${name}`,
          SeriesDate: `${date}`,
          Modality: `${modality}`,
        };
      }
      authRequest
        .patch(url, newData)
        .then(() => closeEditMeta())
        .then(console.log('complete patch folerInfo'))
        .catch(error => console.error(error));
    };

    // メタデータ編集用modal
    const body = (
      <Container component="main">
        <div style={modalStyle} className={classes.paper}>
          <Typography component="h1" variant="h5">
            メタデータの編集
          </Typography>
          <form className={classes.form} noValidate>
            {/* 入力フィールド */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="name"
                  value={name}
                  onChange={handleChangeName}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="date"
                  variant="outlined"
                  fullWidth
                  id="date"
                  label="検査日"
                  value={date}
                  onChange={handleChangeDate}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="modality"
                  variant="outlined"
                  fullWidth
                  id="modality"
                  label="Modality"
                  value={modality}
                  onChange={handleChangeModality}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="sex"
                  variant="outlined"
                  fullWidth
                  id="sex"
                  label="sex"
                  value={sex}
                  onChange={handleChangeSex}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>etc...</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  ※この機能はβ版です。実際のdicomファイルのメタデータは変更されません。
                </Typography>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            spacing={5}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={closeEditMeta}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitData}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          {/* <Typography>{date}</Typography> */}
        </div>
      </Container>
    );
    return (
      <>
        <Modal open={open} onClose={closeEditMeta}>
          {body}
        </Modal>
      </>
    );
  }

  EditMetaModal.propTypes = {
    folderUUID: PropTypes.string,
  };

  return [EditMetaModal, openEditMeta];
};

export default useEditMetaModal;
