import React, { useCallback, useEffect, useContext } from 'react';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles, ListItem as MuiListItem } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';

import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import DialogTitle from '../../../components/DialogTitle';
import { getIcon } from '../../../utils/getIcon';
import {
  getFolderInfo,
  patchFile,
  patchFolder,
  getFile,
  getFolder,
  getRootFolderList,
} from '../../../api/rest.js';
import { useAsyncError } from '../../../api/error';
import { checkFullAccess } from '../../../utils/checkAccessPerm';
import { useOrgDetail } from '../../organization/api/getOrgDetail';
import { useUpdateMoveData } from '../api/fetchUpdateInfo';

const ListItem = withStyles({
  root: {
    '&$selected, &$selected:hover': { backgroundColor: '#ecf4fc' },
  },
  selected: {},
})(MuiListItem);

const useStyles = makeStyles(theme => ({
  popoverContent: {
    width: '300px',
    height: '200px',
    overflow: 'auto',
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
  grey: {
    color: theme.palette.grey[700],
  },
  helpPopover: {
    padding: theme.spacing(2),
  },
  selected: {},
  flexLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export function MovePopover(props) {
  const classes = useStyles();
  const { open, anchorEl, handleClose } = props;
  const updateMoveData = useUpdateMoveData();
  const {
    data: { memberPerm },
  } = useCheckOrgPerm();
  const { data: orgDetails } = useOrgDetail();

  const throwError = useAsyncError();

  const [folderInfo, setFolderInfo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState({});
  const isSet = useCallback(() => folderInfo !== null, [folderInfo]);
  const isRoot = useCallback(
    () => folderInfo && folderInfo.current_folder.uuid == '',
    [folderInfo]
  );

  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const handleOpenHelp = useCallback(event => {
    event.stopPropagation();
    setAnchorElHelp(event.currentTarget);
  }, []);
  const handleCloseHelp = useCallback(event => {
    event.stopPropagation();
    setAnchorElHelp(null);
  }, []);
  const openHelp = Boolean(anchorElHelp);

  const getAndSetFolderInfo = useCallback(
    async uuid => {
      try {
        setIsLoading(true);
        await getFolderInfo(uuid, false, true, true).then(response => {
          setSelectedData(response.data.current_folder);
          setFolderInfo(response.data);
        });
        setIsLoading(false);
      } catch (error) {
        throwError(error);
        setIsLoading(false);
      }
    },
    [throwError]
  );

  const checkFile = useCallback(data => {
    if (data && data.object_type === 'file') {
      return true;
    }
    return false;
  }, []);

  const checkDicom = useCallback(data => {
    if (data && data.object_type === 'folder') {
      if (
        data.foldertype === 'study' ||
        data.foldertype === 'series' ||
        data.foldertype === 'imgSeries'
      ) {
        return true;
      }
    }
    return false;
  }, []);

  const getAndSetRootFolderInfo = useCallback(
    async currentData => {
      try {
        setIsLoading(true);
        const detailData = checkFile(currentData)
          ? await getFile(currentData.uuid).then(response => response.data)
          : await getFolder(currentData.uuid).then(response => response.data);
        await getRootFolderList(detailData.org).then(response => {
          let name = '';
          if (detailData.org_root === 'org') {
            name = orgDetails.name;
          } else if (detailData.org_root === 'share') {
            name = '共有';
          } else {
            name = 'プライベート';
          }
          setSelectedData({ name, uuid: '', object_type: 'root' }); // root を示す
          setFolderInfo({
            current_folder: {
              name: name,
              org: detailData.org,
              uuid: '',
            },
            data: response.data[detailData.org_root],
          });
        });
        setIsLoading(false);
      } catch (error) {
        throwError(error);
        setIsLoading(false);
      }
    },
    [checkFile, orgDetails.name, throwError]
  );

  const setCurrentFolderInfo = useCallback(
    async event => {
      if (props.data.parent_folder == null) {
        getAndSetRootFolderInfo(props.data);
      } else {
        getAndSetFolderInfo(props.data.parent_folder);
      }
    },
    [props.data, getAndSetRootFolderInfo, getAndSetFolderInfo]
  );

  const setPreviousFolderInfo = useCallback(
    async event => {
      if (isRoot()) {
        alert('This is the root folder.');
      } else {
        if (isSet() && folderInfo.current_folder.parent_folder == null) {
          getAndSetRootFolderInfo(folderInfo.current_folder);
        } else {
          getAndSetFolderInfo(folderInfo.current_folder.parent_folder);
        }
      }
    },
    [folderInfo, getAndSetFolderInfo, getAndSetRootFolderInfo, isRoot, isSet]
  );

  const setNextFolderInfo = useCallback(
    async nextData => {
      getAndSetFolderInfo(nextData.uuid);
    },
    [getAndSetFolderInfo]
  );

  useEffect(() => {
    if (open) {
      setCurrentFolderInfo();
    }
  }, [open, setCurrentFolderInfo]);

  const isDisabled = useCallback(
    data => {
      if (data.object_type === 'root') {
        return !memberPerm;
      } else {
        return (
          checkDicom(data) ||
          checkFile(data) ||
          !checkFullAccess(data.perm) ||
          props.data.uuid == data.uuid
        );
      }
    },
    [checkDicom, checkFile, memberPerm, props.data.uuid]
  );

  const moveFile = useCallback(
    async (data, parentUUID) => {
      try {
        setIsLoading(true);
        await patchFile(data.uuid, { parent_folder: parentUUID }).then(
          response => {
            setIsLoading(false);
            handleClose();
          }
        );
      } catch (error) {
        throwError(error);
        setIsLoading(false);
      }
    },
    [handleClose, throwError]
  );

  const moveFolder = useCallback(
    async (data, parentUUID) => {
      try {
        setIsLoading(true);
        await patchFolder(data.uuid, {
          parent_folder: parentUUID,
        }).then(response => {
          setIsLoading(false);
          handleClose();
        });
      } catch (error) {
        throwError(error);
        setIsLoading(false);
      }
    },
    [handleClose, throwError]
  );

  const moveData = useCallback(
    async event => {
      if (isDisabled(selectedData)) {
        alert('You can not move to this folder');
      } else {
        if (checkFile(props.data)) {
          await moveFile(props.data, selectedData.uuid);
        } else {
          await moveFolder(props.data, selectedData.uuid);
        }
        await updateMoveData(props.data, selectedData);
      }
    },
    [
      isDisabled,
      selectedData,
      checkFile,
      props.data,
      updateMoveData,
      moveFile,
      moveFolder,
    ]
  );

  return (
    <>
      <Popover
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>
          {!isRoot() && (
            <Box className={classes.backIcon}>
              <Tooltip title="戻る">
                <span>
                  <IconButton
                    aria-label="close"
                    onClick={setPreviousFolderInfo}
                    size="small"
                    edge="end"
                  >
                    <ArrowBackOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}
          {isSet() &&
            folderInfo.current_folder &&
            folderInfo.current_folder.name}
        </DialogTitle>
        <Divider />
        <Box className={classes.popoverContent}>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', height: '100%' }}
            >
              <CircularProgress disableShrink size={40} />
            </Box>
          ) : (
            <List component="nav" dense>
              {isSet() && folderInfo.data.length > 0 ? (
                folderInfo.data.map(item => (
                  <ListItem
                    button
                    disableRipple
                    key={item.uuid}
                    onClick={() => setSelectedData(item)}
                    onDoubleClick={() => setNextFolderInfo(item)}
                    selected={item.uuid === selectedData.uuid}
                    disabled={isDisabled(item)}
                  >
                    <ListItemIcon>{getIcon(item)}</ListItemIcon>
                    <ListItemText primary={item.name} />
                    {isDisabled(item) ? null : (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setNextFolderInfo(item)}
                        >
                          <ArrowForwardIosOutlinedIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary={'データがありません'} />
                </ListItem>
              )}
            </List>
          )}
        </Box>
        <Divider />
        <DialogActions>
          <IconButton size="small" edge="end" onClick={handleOpenHelp}>
            <HelpOutlineOutlinedIcon fontSize="small" />
          </IconButton>
          <Button onClick={handleClose}>キャンセル</Button>
          <Tooltip title={`${selectedData.name}に移動します`}>
            <span>
              <Button
                onClick={() => moveData()}
                color="primary"
                variant="contained"
                disabled={isDisabled(selectedData)}
              >
                移動
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Popover>
      <Popover
        open={openHelp}
        anchorEl={anchorElHelp}
        onClose={handleCloseHelp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.helpPopover}>
          <Typography gutterBottom>
            これらは移動先に選ぶことができません
          </Typography>
          <Typography>・現在属しているフォルダ</Typography>
          <Typography>・フルアクセス権限を持たないフォルダ</Typography>
          <Typography>・study、series、imgSeries</Typography>
          {!memberPerm && (
            <Typography>
              ・(組織のメンバー権限が無い時）組織のトップ階層
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
}

MovePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export const useMovePopover = () => {
  const [data, setData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [option, setOption] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMovePopover = (data, option) => {
    setData(data);
    setOption(option);
    setAnchorEl(option.anchorEl);
  };

  function movePopover() {
    return (
      <MovePopover
        open={open}
        anchorEl={anchorEl}
        data={data}
        option={option}
        handleClose={handleClose}
      />
    );
  }

  return [openMovePopover, movePopover];
};
