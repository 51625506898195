import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

const MEDICAL_SEARCH_TITLE = 'MNES Medical Search';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    marginLeft: `1rem`,
  },
}));

export default function ConciergeTitle() {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.title} noWrap>
      <Link component={RouterLink} to={'/medical-search'} color="primary">
      {MEDICAL_SEARCH_TITLE}
      </Link>
    </Typography>
  );
}

export { MEDICAL_SEARCH_TITLE };
