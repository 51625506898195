import React, { useState, useEffect, useContext }  from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import { ThumbnailGroup } from './ThumbnailGroup.js';
import './StudyBrowser.styl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function StudyBrowser(props) {
  const {
    studies,
    studyMetadataList,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
  } = props;


  return (
    <div className="study-browser">
      <div className="scrollable-study-thumbnails">
        {/* {studyMetadataList.map((study, studyIndex) => {
          console.log(`STUDY ${studyIndex}: ${study.PatientName}`);
        })} */}

        {studies
          .map((study, studyIndex) => {
            const { StudyInstanceUID } = study.StudyInstanceUID;
            const studyMetadata = studyMetadataList[studyIndex];

            const [thumnailsStatus, setThumnailsStatus] = useState({
              thumbsVisible: true,
            });

            const toggleThumbnails = () => {
              setThumnailsStatus({
                thumbsVisible: !thumnailsStatus.thumbsVisible,
              });
              console.log('thumnailsStatus', thumnailsStatus);
            }

            const getStudyLabel = () => {
              if (studyMetadata.StudyDescription) return studyMetadata.StudyDescription;
              if (studyMetadata.PatientName) return studyMetadata.PatientName;
              if (studyMetadata.StudyID) return studyMetadata.StudyID;
              else return 'Unnamed Study';
            }

            const studyLabel = getStudyLabel();
            console.log('studyLabel', studyLabel);

            // console.log(`STUDY ${studyIndex}: ${study}`);
            // console.log('study.thumbnails', study.thumbnails);
            console.debug('studyMetadata', studyMetadata);

            var modalitiesList = studyMetadata.series.map((series, seriesIndex) => {
              return series.Modality
            });
            const modalities = new Set(modalitiesList);

            return (
              <div
                key={studyIndex}
                className="study-thumbs-wrapper"
              >
                <div>
                  <button
                    onClick={toggleThumbnails}
                    className="toggle-thumbs-btn pull-right"
                  >{ thumnailsStatus.thumbsVisible ? (
                      <ExpandLessIcon style={{ fontSize: '16px' }}/>
                    ) : (
                      <ExpandMoreIcon style={{ fontSize: '16px' }}/>
                    )}</button>
                  <div className="study-label">
                    {studyLabel}
                  </div>
                  {studyMetadata.PatientName} | {studyMetadata.StudyDate} | {modalities}

                </div>

                {thumnailsStatus.thumbsVisible ? (
                    <ThumbnailGroup
                      studyMetadata={studyMetadataList[studyIndex]}
                      studyIndex={studyIndex}
                      thumbnails={study.thumbnails}
                      onThumbnailClick={onThumbnailClick}
                      onThumbnailDoubleClick={onThumbnailDoubleClick}
                      supportsDrag={supportsDrag}
                      showThumbnailProgressBar={supportsDrag}
                    />
                  ) : (<div></div>)
                }
              </div>

              // EVERYTHING BELOW HERE IS IN ThumbnailGroup
              // study.thumbnails.map((thumb, thumbIndex) => {
              // // TODO: Thumb has more props than we care about?
              // const {
              //   active,
              //   altImageText,
              //   displaySetInstanceUID,
              //   imageId,
              //   derivedDisplaySetsNumber,
              //   numImageFrames,
              //   SeriesDescription,
              //   SeriesNumber,
              //   hasWarnings,
              //   hasDerivedDisplaySets,
              // } = thumb;

              // return (

              //   <div
              //     key={thumb.displaySetInstanceUID}
              //     className="thumbnail-container"
              //     data-cy="thumbnail-list"
              //   >
              //     <div>Study here.</div>
              //     <Thumbnail
              //       active={active}
              //       supportsDrag={supportsDrag}
              //       key={`${studyIndex}_${thumbIndex}`}
              //       id={`${studyIndex}_${thumbIndex}`} // Unused?
              //       // Study
              //       StudyInstanceUID={StudyInstanceUID} // used by drop
              //       // Thumb
              //       altImageText={altImageText}
              //       imageId={imageId}
              //       derivedDisplaySetsNumber={derivedDisplaySetsNumber}
              //       displaySetInstanceUID={displaySetInstanceUID} // used by drop
              //       numImageFrames={numImageFrames}
              //       SeriesDescription={SeriesDescription}
              //       SeriesNumber={SeriesNumber}
              //       hasWarnings={hasWarnings}
              //       hasDerivedDisplaySets={hasDerivedDisplaySets}
              //       // Events
              //       onClick={onThumbnailClick.bind(
              //         undefined,
              //         displaySetInstanceUID
              //       )}
              //       onDoubleClick={onThumbnailDoubleClick}
              //       showProgressBar={showThumbnailProgressBar}
              //     />
              //   </div>
              // );
              // })

            );
          })
          .flat()}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          derivedDisplaySetsNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
