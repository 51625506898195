import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AgGridTable from '../DataViewer/AgGridTable';

function SelectedTagDialog({ dataInfo, open, setOpen }) {
  const handleDialogClick = e => {
    e.stopPropagation();
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onClick={handleDialogClick}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        「{dataInfo.tag.name || '"名前のないタグ"'} 」タグを持つデータ
      </DialogTitle>
      <DialogContent>
        {dataInfo.data.length > 0 ? (
          <AgGridTable dataList={dataInfo.data} folderPath={[]} />
        ) : (
          <DialogContentText>
            このタグを持つデータはありません。
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

SelectedTagDialog.propTypes = {
  dataInfo: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default SelectedTagDialog;
