import React, {
  useMemo, useContext, useCallback
} from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ModalContext } from '../../../context/ModalContext';
import { ContextMenuContext } from '../providers/ContextMenuContext';
import TopTitle from '../../../components/TopTitle';
import { ReactComponent as DoubleLeft } from '@ohif/viewer/src/assets/chevron-double-left.svg';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import AddIcon from '@material-ui/icons/Add';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import { isDicom } from '../../../utils/checkData';
import { bite2XB } from '../../../utils/dataProcess';

import { useFolderInfo } from '../api/getFolderInfo';
import { useOrgDetail, useIsOrgSet } from '../../organization/api/getOrgDetail';
import { useOrgRootList } from '../../organization/api/getOrgRootList';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';
import { useIsCheck } from '../utils/useParams';
import { isConferencePage } from './Conference/StorageConference';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
    height: '100%',
  },
  drawerPaper: {
    width: theme.drawerWidth,
    backgroundColor: theme.color.bgColorBase,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 0,
  },
  folders: {
    height: '100%',
    marginLeft: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `50%`,
    transform: `translate(-90%, -50%)`,
  },
  button: {
    margin: theme.spacing(1, 1, 1, 1),
    padding: theme.spacing(1, 5, 1, 2),
    borderRadius: '30px',
  },
  topLeftBox: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rootButton: {
    textTransform: 'none',
  },
  dialog: {
    width: '400px',
  },
  icon: {
    '&:hover': {
      background: '#D3D3D3',
      borderRadius: '5px',
    },
  },

  listPermRoot: {
    margin: '5px',
    color: theme.color.textColorDefault,
    fontSize: theme.font.fontSizeBaby,
  },
  listItemRoot: {
    fontSize: theme.font.fontSizeBaby,
    margin: 0,
    padding: 0,
  },

  listRoot: {
    padding: '0px',
  },

  listItemIconRoot: {
    minWidth: '0px',
    paddingRight: '5px',
  },

  multiline: {
    margin: 0,
  },
  primary: {
    fontSize: '1.0em',
  },

  typography: {
    padding: theme.spacing(2),
  },
  orgMenu: {
    maxHeight: 300,
  },
}));

/*
propsで渡されたfolderの情報から
/api/datamanage/info/folder/{id}
の結果を受け取り、current folderと下層のフォルダorファイルのlistを返す
*/
function ListFolders({ folder, depth }) {
  const classes = useStyles();
  // ListFoldersのopen状態を管理
  const [open, setOpen] = React.useState(false);

  const currentFolderUUID = useSelector(
    state => state.storageManager.currentFolderUUID
  );
  const { isFolderPage } = useIsCheck();

  // depthの数だけ左にpadding
  const nestStyle = depth => {
    return {
      paddingLeft: `${15 * depth}px`,
    };
  };

  const handleClickIcon = event => {
    event.stopPropagation();
    setOpen(open => !open);
  };

  // folderをクリックした時、urlを遷移させる
  const history = useHistory();
  const handleClickFolder = useCallback(
    folder => () => {
      const selectData = { type: 'folder', data: folder };
      if (folder.foldertype == 'folder') {
        const url = `/storage/${folder.uuid}${window.location.search}`;
        history.push(url, { selectData });
      } else if (folder.foldertype == 'study') {
        const url = `/storage/${folder.uuid}${window.location.search}`;
        history.push(url, { selectData });
      } else if (folder.foldertype == 'series') {
        console.log(folder.foldertype);
      } else {
        console.log(folder.foldertype);
      }
    },
    [history]
  );

  const {
    data: nowFolderInfo,
    isLoading: isLoadingNowFolderInfo,
  } = useFolderInfo({
    folderUUID: folder.uuid,
    updateCurrentOrgUUID: false,
    apiOptions: {
      noFile: true,
      noUrl: true,
      combine: false,
    },
  });

  return (
    <>
      <List className={classes.listRoot}>
        <ListItem
          dense
          button
          style={nestStyle(depth)}
          onClick={handleClickFolder(folder)}
          key={folder.uuid}
          selected={isFolderPage() && folder.uuid === currentFolderUUID}
        >
          <ListItemIcon className={classes.listItemIconRoot}>
            <>
              {open ? (
                <ArrowDropDownOutlinedIcon
                  onClick={handleClickIcon}
                  className={classes.icon}
                />
              ) : (
                <ArrowRightOutlinedIcon
                  onClick={handleClickIcon}
                  className={classes.icon}
                />
              )}
            </>
          </ListItemIcon>
          <ListItemText
            primary={folder.name}
            classes={{
              root: classes.listItemRoot,
              multiline: classes.multiline,
              primary: classes.primary,
            }}
          />
        </ListItem>
      </List>
      <Collapse in={open} timeout="auto">
        {open && (
          <List
            dense
            disablePadding
            component="div"
            className={classes.listRoot}
          >
            {!isLoadingNowFolderInfo && nowFolderInfo.folders && (
              <>
                {nowFolderInfo.folders
                  .filter(folder => !isDicom(folder))
                  .map(childFolder => (
                    // ListFolderを再帰
                    // TODO: series, imgSeries は表示させない（ただし、フォルダをimgSeriesに変換したときに表示させないようにしないといけないので要検討）
                    <React.Fragment key={childFolder.uuid}>
                      <ListFolders folder={childFolder} depth={depth + 1} />
                    </React.Fragment>
                  ))}
              </>
            )}
          </List>
        )}
      </Collapse>
    </>
  );
}

ListFolders.propTypes = {
  folder: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
};

export const NewStorageMenuButton = () => {
  const classes = useStyles();
  const { openContextMenu } = useContext(ContextMenuContext);

  const isConference = isConferencePage();
  const buttonLabel = isConference ? 'アップロード' : '新規';

  const rootPermName = useSelector(state => state.storageManager.rootPermName);
  const { isRootPage, isTutorial, isFolderPage } = useIsCheck();
  const {
    data: folderInfo,
    isLoading: isLoadingFolderInfo,
    isError: isErrorFolderInfo,
  } = useFolderInfo();

  const handleContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    if (isRootPage()) {
      console.log('NewStorageMenuButton isRootPage');
      const data = {};
      const option = {
        position: {
          left: rect.left - 2,
          top: rect.bottom - 4,
        },
        isRoot: true,
        rootPermName: rootPermName,
        isNewButton: true,
      };
      openContextMenu(data, option);
    } else if (isTutorial()) {
      // rootのprivate扱い
      const data = {};
      const option = {
        position: {
          left: rect.left - 2,
          top: rect.bottom - 4,
        },
        rootPermName: 'private',
        isTutorial: true,
        isNewButton: true,
      };
      openContextMenu(data, option);
    } else {
      const data = folderInfo.current_folder;
      const option = {
        position: {
          left: rect.left - 2,
          top: rect.bottom - 4,
        },
        isNewButton: true,
      };
      openContextMenu(data, option);
    }
  };

  return (
    <Box
      component="span"
      m={1} //margin
      className={`${classes.topLeftBox} ${classes.box}`}
    >
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        size="medium"
        startIcon={<AddIcon />}
        onClick={handleContextMenu}
        //disabled={isFolderPage() && (isLoadingFolderInfo || isErrorFolderInfo)}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
}
NewStorageMenuButton.propTypes = {};

function DrawerRootFolderList() {
  const classes = useStyles();
  const history = useHistory();
  const rootPermName = useSelector(state => state.storageManager.rootPermName);

  const { data: rootList } = useOrgRootList();
  const { data: orgDetails } = useOrgDetail();
  const { data: isOrgIdSet } = useIsOrgSet();
  const {
    data: { memberPerm },
  } = useCheckOrgPerm();

  const openRoot = useCallback(
    rootPermName => {
      const url =
        '/storage/org/' +
        orgDetails.uuid +
        '/root/' +
        rootPermName +
        `${window.location.search}`;
      const selectData = {
        type: 'folder',
        data: {},
      };
      history.push(url, { selectData });
    },
    [history, orgDetails.uuid]
  );

  return (
    <>
      {isOrgIdSet && (
        <Box className={classes.folders}>
          {/* public, share, private */}
          {Object.keys(rootList).map((key, index) => {
            // orgに所属しない場合はprivate表示しない
            if (key === 'private' && !memberPerm) {
              return <React.Fragment key={key}></React.Fragment>;
            }

            return (
              <React.Fragment key={key}>
                {/* org, share, private のどれなのかを表示 */}

                <List className={classes.listPermRoot}>
                  <ListItem
                    dense
                    button
                    onClick={() => openRoot(key)}
                    key={key}
                    selected={rootPermName === key}
                    className={classes.listItemRoot}
                  >
                    <Typography>
                      {key == 'org'
                        ? orgDetails.name
                        : key == 'share'
                        ? '共有'
                        : 'プライベート'}
                    </Typography>
                  </ListItem>
                  {/*
                    <Typography variant={'h5'} className={classes.typography}>
                    {key == 'org' ? orgDetails.name : key}
                      </Typography>
                    */}

                  {rootList[key]
                    .filter(obj => obj.object_type == 'folder')
                    .filter(obj => !isDicom(obj))
                    .map(childFolder => (
                      <React.Fragment key={childFolder.uuid}>
                        <ListFolders
                          key={childFolder.uuid}
                          folder={childFolder}
                          depth={0}
                        />
                      </React.Fragment>
                    ))}
                </List>
              </React.Fragment>
            );
          })}
          <br />
          <Typography>
            {orgDetails.used_size ? bite2XB(orgDetails.used_size) : 0}/100GB
            利用中
          </Typography>
        </Box>
      )}
    </>
  );
}

function DrawerRootFolder(props) {
  const classes = useStyles();
  const { openDrawer, setOpenDrawer } = useContext(ModalContext);

  const handleDrawerToggle = () => {
    setOpenDrawer(open => !openDrawer);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={props.variant}
        classes={{
          paper: classes.drawerPaper,
        }}
        overflow="scroll"
        onClose={handleDrawerToggle}
        open={openDrawer}
      >
        <Toolbar className={classes.toolbar}>
          <TopTitle />
          <IconButton
            color="inherit"
            aria-label="Close drawer"
            onClick={handleDrawerToggle}
          >
            <SvgIcon fontSize="small">
              <DoubleLeft />
            </SvgIcon>
          </IconButton>
        </Toolbar>

        <NewStorageMenuButton />

        <Divider />

        <DrawerRootFolderList />
      </Drawer>
    </>
  );
}

DrawerRootFolder.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default DrawerRootFolder;
