import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ModalContext } from '../../../../context/ModalContext';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isMobile } from '../../../../utils/detectDevice';
import { useIsCheck } from '../../utils/useParams';
import { useSetSelectData } from '../../utils/useSelectData';
import {
  makeDropZoneIDforRoot,
  makeDropZoneIDforFolder,
} from '../../utils/aggridUtils';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  link: {
    fontSize: '1.4em',
  },
  button: {
    textTransform: 'none',
    fontSize: theme.font.fontSizeBaby,
  },
  buttonInfo: { marginRight: 0, padding: 0 },
}));

function CurrentFolderOrRootButton({ name, handleContextMenu }) {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      onClick={handleContextMenu}
      onContextMenu={handleContextMenu}
      endIcon={<ArrowDropDownOutlinedIcon />}
    >
      {name}
    </Button>
  );
}

const useStylesPath = makeStyles(theme => ({
  link: {
    fontSize: '1.4em',
  },
  button: {
    textTransform: 'none',
    fontSize: theme.font.fontSizeBaby,
  },
}));

function PathButton({ folderOrRoot, orgUUID }) {
  const setSelectData = useSetSelectData();

  const classes = useStylesPath();
  return (
    <div>
      {!orgUUID && folderOrRoot && folderOrRoot.type === 'no_perm' ? (
        <>
          <Typography className={classes.link}>...</Typography>
          &nbsp;
          <Typography variant="body1"> &gt; </Typography>
          &nbsp;
        </>
      ) : folderOrRoot.type === 'private' ||
        folderOrRoot.type === 'share' ||
        folderOrRoot.type === 'org' ? (
        <div id={makeDropZoneIDforRoot(folderOrRoot)}>
          <Button
            key={folderOrRoot.uuid}
            className={classes.button}
            component={Link}
            to={`/storage/org/${orgUUID}/root/${folderOrRoot.type}${window.location.search}`}
            onClick={e => {
              e.stopPropagation();
              setSelectData({
                type: 'folder',
                data: {},
              });
            }}
          >
            {folderOrRoot.name}
          </Button>
          &nbsp; &gt; &nbsp;
        </div>
      ) : (
        <div id={makeDropZoneIDforFolder(folderOrRoot)}>
          <Button
            key={folderOrRoot.uuid}
            className={classes.button}
            component={Link}
            to={`/storage/${folderOrRoot.uuid}${window.location.search}`}
            onClick={e => {
              e.stopPropagation();
              setSelectData({
                type: 'folder',
                data: folderOrRoot,
              });
            }}
          >
            {folderOrRoot.name}
          </Button>
          &nbsp; &gt; &nbsp;
        </div>
      )}
    </div>
  );
}
PathButton.propTypes = {
  folderOrRoot: PropTypes.object.isRequired,
  orgUUID: PropTypes.string,
};

function OptionnalPathList({ folderPath }) {
  const classes = useStyles();
  const refButton = React.createRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickButton = () => {
    setAnchorEl(refButton.current);
  };
  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Button
          className={classes.button}
          onClick={handleClickButton}
          ref={refButton}
        >
          ...
        </Button>
        &nbsp; &gt; &nbsp;
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseButton}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {!!folderPath &&
            folderPath.slice(1, -2).map((folderOrRoot, index) => {
              return (
                <OptionnalPathItem folderOrRoot={folderOrRoot} key={index} />
              );
            })}
        </Menu>
      </div>
    </>
  );
}
OptionnalPathList.propTypes = {
  folderPath: PropTypes.array.isRequired,
};

function OptionnalPathItem({ folderOrRoot }) {
  const classes = useStyles();
  return (
    <div id={makeDropZoneIDforFolder(folderOrRoot)}>
      <MenuItem
        className={classes.button}
        component={Link}
        to={`/storage/${folderOrRoot.uuid}${window.location.search}`}
      >
        <FolderOutlinedIcon fontSize="small" style={{ marginRight: 10 }} />
        {folderOrRoot.name}
      </MenuItem>
    </div>
  );
}
OptionnalPathItem.propTypes = {
  folderOrRoot: PropTypes.object.isRequired,
};

function DataViewerToolbar({ folderPath, openContextMenuAtPosition }) {
  /*
    フォルダのパスを表示する
    PC: root -> ... -> folder ->  現在地 (4つ以上だと省略してまとめて表示)
    モバイル: 一つ上に戻るボタン＋現在地
  */

  const classes = useStyles();
  const { data: orgDetails } = useOrgDetail({ useErrorBoundary: true });

  const { isRootPage } = useIsCheck();
  const rootPermName = useSelector(state => state.storageManager.rootPermName);
  const rootName =
    rootPermName == 'org'
      ? orgDetails.name
      : rootPermName == 'share'
      ? '共有'
      : 'プライベート';

  const { openDrawerInfo, setOpenDrawerInfo } = useContext(ModalContext);

  const handleContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    openContextMenuAtPosition(rect.left - 2, rect.bottom - 4);
  };

  const handleOpenInfo = event => {
    setOpenDrawerInfo(open => !open);
    event.stopPropagation();
  };

  return (

    <Toolbar className={classes.toolbar}>

      {isRootPage() ? (
        <CurrentFolderOrRootButton
          name={rootName}
          handleContextMenu={handleContextMenu}
        />
      ) : (
        <>
          {/* パンくずリスト */}
          {folderPath.length > 0 && (
            <Box display="flex" flexDirection="row" alignItems="center">
              {isMobile() ? (
                <>
                  {folderPath.length > 1 ? (
                    <IconButton
                      size="small"
                      className={classes.button}
                      component={Link}
                      to={
                        folderPath.length == 2
                          ? `/storage/org/${orgDetails.uuid}/root/${
                              folderPath.slice(-2)[0].type
                            }${window.location.search}`
                          : `/storage/${folderPath.slice(-2)[0].uuid}${
                              window.location.search
                            }`
                      }
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  ) : null}
                </>
              ) : (
                <>
                  {/* root */}
                  {folderPath.length > 1 ? (
                    <PathButton
                      folderOrRoot={folderPath[0]}
                      orgUUID={orgDetails.uuid}
                    />
                  ) : (
                    <></>
                  )}

                  {/* 省略可能なパスリスト */}
                  {folderPath.length > 3 ? (
                    <OptionnalPathList folderPath={folderPath} />
                  ) : (
                    <></>
                  )}

                  {/* 現在地の一つ前 */}
                  {folderPath.length > 2 ? (
                    <PathButton
                      folderOrRoot={folderPath.slice(-2)[0]}
                      orgUUID={orgDetails.uuid}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {/* 現在地 */}
              <CurrentFolderOrRootButton
                name={folderPath.slice(-1)[0].name}
                handleContextMenu={handleContextMenu}
              />
            </Box>
          )}
        </>
      )}

      {!openDrawerInfo && (
        <Tooltip title="詳細を表示" placement="bottom-start">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleOpenInfo}
            className={classes.buttonInfo}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default DataViewerToolbar;
