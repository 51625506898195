/*
全体のテーマ設定
 */

import OHIFStandaloneViewer from './OHIFStandaloneViewer';
import React from 'react';

import TermsOfServiceDialog from './components/termsOfService/TermsOfServiceDialog';

import { UploadProvider } from './features/storage/components/UploadContext';
import { ContextMenuProvider } from './features/storage/providers/ContextMenuContext';
import { ModalProvider } from './context/ModalContext';
import { SnackbarProvider } from './context/SnackbarContext';
import './OHIFStandaloneViewerWrap.css';
import AppThemeProvider from './providers/AppThemeProvider';

function OHIFStandaloneViewerWrap() {
  return (
    <>
      <AppThemeProvider>
        <TermsOfServiceDialog />
        <SnackbarProvider>
          <ModalProvider>
            <UploadProvider>
              <ContextMenuProvider>
                <OHIFStandaloneViewer />
              </ContextMenuProvider>
            </UploadProvider>
          </ModalProvider>
        </SnackbarProvider>
      </AppThemeProvider>
    </>
  );
}

export default OHIFStandaloneViewerWrap;
