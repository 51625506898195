import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ToolbarButton } from '@ohif/ui';
import { utils } from '@ohif/core';
import Tooltip from '@material-ui/core/Tooltip';
// import { isTablet, isMobile } from '@ohif/viewer/src/utils/detectDevice';
import { isTablet, isMobile, getDeviceType } from '../../../../platform/viewer/src/utils/detectDevice';

// Viewers/platform/viewer/src/utils/detectDevice.js

const { studyMetadataManager } = utils;

let isVisible = true;

const _isDisplaySetReconstructable = (
  viewportSpecificData = {},
  activeViewportIndex
) => {
  if (!viewportSpecificData[activeViewportIndex]) {
    return false;
  }

  const { displaySetInstanceUID, StudyInstanceUID } = viewportSpecificData[
    activeViewportIndex
  ];

  const studies = studyMetadataManager.all();

  const study = studies.find(
    study => study.studyInstanceUID === StudyInstanceUID
  );

  if (!study) {
    return false;
  }

  const displaySet = study._displaySets.find(
    set => set.displaySetInstanceUID === displaySetInstanceUID
  );

  if (!displaySet) {
    return false;
  }

  return displaySet.isReconstructable;
};

function VTKMPRToolbarButton({
  parentContext,
  toolbarClickCallback,
  button,
  activeButtons,
  isActive,
  className,
}) {
  const { id, label, icon } = button;
  const { viewportSpecificData, activeViewportIndex } = useSelector(state => {
    const { viewports = {} } = state;
    const { viewportSpecificData, activeViewportIndex } = viewports;

    return {
      viewportSpecificData,
      activeViewportIndex,
    };
  });

  isVisible = _isDisplaySetReconstructable(
    viewportSpecificData,
    activeViewportIndex
  );

  const deviceType = getDeviceType();
  console.debug('deviceType', deviceType);

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <ToolbarButton
          key={id}
          label={label}
          icon={icon}
          onClick={evt => toolbarClickCallback(button, evt)}
          isActive={isActive}
        />
      </div>
    );
  });

  return (

    <React.Fragment>

      {isVisible && deviceType !== 'Phone' && (
        <Tooltip title="α版では共有機能はご利用できません">
          <MyComponent />
        </Tooltip>
      )}

    </React.Fragment>

  );
}

VTKMPRToolbarButton.propTypes = {
  parentContext: PropTypes.object.isRequired,
  toolbarClickCallback: PropTypes.func.isRequired,
  button: PropTypes.object.isRequired,
  activeButtons: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default VTKMPRToolbarButton;
