import React, { useContext, useState } from 'react';
import { getTagsInfo } from '../../../../api/rest';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    zoom: 0.8,
  },
}));

export function TagsButton({ tag, uuid, canDelete = false, onClick }) {
  const classes = useStyles();
  const deleteTag = () => {
    console.log('delete tag');
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      size="small"
      onClick={onClick}
      endIcon={canDelete ? <ClearIcon onClick={deleteTag} /> : ''}
    >
      {tag}
    </Button>
  );
}

TagsButton.propTypes = {
  tag: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  canDelete: PropTypes.bool,
  onClick: PropTypes.func,
};
