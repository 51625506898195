// dicomデータに含まれる情報をタグに追加するためのDialog
import React, { useEffect, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  postFolderTag,
  deleteFolderTag,
  getRecursiveFolderInfo,
} from '../../../../api/rest';
import { useAsyncError } from '../../../../api/error';
import { checkCanComment } from '../../../../utils/checkAccessPerm';
import Loading from '../../../../components/Loading';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  DialogActions,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button';
import { useFetchUpdateInfo } from '../../api/fetchUpdateInfo';

const useStyles = makeStyles(theme => ({
  dialog: {
    zIndex: '10000',
  },
  form: {
    display: 'flex',
    // 中央揃え
    justifyContent: 'center',
  },
  content: {},
  formControl: {
    margin: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

// チェックボックスのグループを管理するリスト
const checkGroups = [
  {
    formLabel: 'studyに付与する情報',
    checkBoxes: [
      'PatientName',
      'PatientSex',
      'PatientAge',
      'PatientID',
      'InstitutionName',
      'StudyDate',
    ],
    formControlLabel: [
      '名前 (PatientName)',
      '性別 (PatientSex)',
      '年齢 (PatientAge)',
      'ID (PatientID)',
      '施設名 (InstitutionName)',
      '診察日 (StudyDate)',
    ],
  },
  /* // 2022/06/15 seriesの変更は必要がないため、tagsObjectからseriesを取り除く
  {
    formLabel: 'seriesに付与する情報',
    checkBoxes: ['Modality', 'BodyPartExamined'],
    formControlLabel: ['モダリティ (Modality)', '部位 (BodyPartExamined)'],
  },
  */
];

const prefixDict = {
  study: {
    PatientName: '名前',
    PatientAge: '年齢',
    PatientSex: '性別',
    PatientID: 'ID',
    InstitutionName: '施設名',
    StudyDate: '診察日',
  },
  /* // 2022/06/15 seriesの変更は必要がないため、tagsObjectからseriesを取り除く
  series: {
    Modality: '様式',
    BodyPartExamined: '部位',
  },
  */
};

function DicomTagsDialog({ data, open, setOpen }) {
  const throwError = useAsyncError();
  const classes = useStyles();
  //権限チェック
  const canComment = checkCanComment(data.perm);

  // folderInfoを取得する
  const fetchUpdateInfo = useFetchUpdateInfo();

  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // Dialogを閉じる関数
  const handleClose = () => {
    setOpen(false);
  };

  // チェックボックスのチェック状態を保持する
  const [state, setState] = React.useState({
    delete: false,
  });

  // チェックボックスの変更を反映する
  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  // stateを初期化する関数
  const handleReset = () => {
    checkGroups.forEach(group => {
      group.checkBoxes.forEach(checkBox => {
        setState(state => ({
          ...state,
          [checkBox]: true,
        }));
      });
    });
  };

  const handleSelectAll = () => {
    let checkBoxOptions = {};
    checkGroups.forEach(group => {
      group.checkBoxes.forEach(checkBox => {
        checkBoxOptions[checkBox] = selectAll;
      });
    });
    setState(state => ({
      ...state,
      ...checkBoxOptions,
    }));
    setSelectAll(selectAll => !selectAll);
  };

  // stateの初期化を初回だけ実行する
  useEffect(() => {
    handleReset();
  }, []);

  // yyyymmddをyyyy/mm/ddに変換する
  const getDate = useCallback(date => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    return `${year}/${month}/${day}`;
  }, []);

  // 二つの日付から、その経過年数を返す
  const getAge = useCallback(
    (birthday, today) => {
      if (birthday.length == 0 || today.length == 0) {
        return '';
      }
      const birthdayDate = new Date(getDate(birthday));
      const todayDate = new Date(getDate(today));
      const age = todayDate.getFullYear() - birthdayDate.getFullYear();
      const month = todayDate.getMonth() - birthdayDate.getMonth();
      if (
        month < 0 ||
        (month === 0 && todayDate.getDate() < birthdayDate.getDate())
      ) {
        return (age - 1).toString();
      }

      return age.toString();
    },
    [getDate]
  );

  // 2022/06/15 seriesの変更は必要がないため、tagsObjectからseriesを取り除く
  // 再帰的にstudy/seriesの情報をgetする関数
  const getTagsObject = useCallback(
    (folderInfo, current = false) => {
      // 一層目の場合はcurrent_folderを利用する
      const folders = current ? [folderInfo] : folderInfo.folders;

      const studyTags = folders
        .filter(folder => folder.foldertype == 'study')
        .map(study => ({
          uuid: study.uuid,
          PatientName: study.PatientName,
          PatientID: study.PatientID,
          PatientSex: study.PatientSex,
          PatientAge: getAge(study.PatientBirthDate, study.StudyDate),
          InstitutionName: study.InstitutionName,
          StudyDate: study.StudyDate,
          tags: study.tags,
          folders: getTagsObject(study, false),
        }));
      const seriesTags = [];
      // folders
      //   .filter(folder => folder.foldertype == 'series')
      //   .map(series => ({
      //     uuid: series.uuid,
      //     Modality: series.Modality,
      //     BodyPartExamined: series.BodyPartExamined,
      //     tags: series.tags,
      //   }));
      return { studyTags: studyTags, seriesTags: seriesTags };
    },
    [getAge]
  );

  // タグ変更をAPIに送信する関数
  const handleSubmit = async () => {
    //setOpen(false);
    setLoading(true);
    // folderInfoを取得する
    try {
      const folderInfoResponse = await getRecursiveFolderInfo(data.uuid, true);
      // コメント権限のある時のみ関数を実行する
      if (canComment) {
        const tagsObject = await getTagsObject(folderInfoResponse.data, true);
        if (state.delete) {
          await deleteStudySeriesTags(tagsObject);
        }
        await postStudySeriesTags(tagsObject);
        await setOpen(false);
        await setLoading(false);
        // リロード
        await fetchUpdateInfo();
      }
    } catch (e) {
      throwError(e);
    }
  };

  // 再起的にstudy/seriesのタグをpostする関数
  const postStudySeriesTags = useCallback(
    // studyタグをpostする関数
    async tagsObject => {
      const result_study = Promise.all(
        tagsObject.studyTags.map(async study => {
          Object.keys(prefixDict['study']).forEach(async key => {
            if (state[key] && study[key] && study[key].length > 0) {
              await postFolderTag(
                study.uuid,
                `${prefixDict['study'][key]}:${study[key]}`
              );
            }
          });
          if (study.folders) {
            await postStudySeriesTags(study.folders);
          }
        })
      );
      const result_series = Promise.all(
        tagsObject.seriesTags.map(async series => {
          Object.keys(prefixDict['series']).forEach(async key => {
            if (state[key] && series[key].length > 0) {
              await postFolderTag(
                series.uuid,
                `${prefixDict['series'][key]}:${series[key]}`
              );
            }
          });
        })
      );
      return Promise.all([result_study, result_series]);
    },
    [state]
  );

  // tagNameを持つタグを見つけて返す、なければundefinedを返す
  const getSelectedTags = useCallback((tags, tagName) => {
    if (tags.length == 0) {
      return undefined;
    }
    const selectedTag = tags.find(tag => tag.name == tagName);
    return selectedTag;
  }, []);

  // 再起的にstudy/seriesのタグをdeleteする関数
  const deleteStudySeriesTags = useCallback(
    async tagsObject => {
      // studyタグをdelete
      const result_study = Promise.all(
        tagsObject.studyTags.map(async study => {
          Object.keys(prefixDict['study']).forEach(async key => {
            if (!state[key] && state.delete) {
              const selectedTag = getSelectedTags(
                study.tags,
                `${prefixDict['study'][key]}:${study[key]}`
              );
              if (selectedTag) {
                await deleteFolderTag(study.uuid, selectedTag.uuid);
              }
            }
          });
          // studyタグに含まれるstudyやseriesタグを再帰的にdelete
          if (study.folders) {
            await deleteStudySeriesTags(study.folders);
          }
        })
      );
      // seriesタグをdelete
      const result_series = Promise.all(
        tagsObject.seriesTags.map(async series => {
          Object.keys(prefixDict['series']).forEach(async key => {
            if (!state[key] && state.delete) {
              const selectedTag = getSelectedTags(
                series.tags,
                `${prefixDict['series'][key]}:${series[key]}`
              );
              if (selectedTag) {
                await deleteFolderTag(series.uuid, selectedTag.uuid);
              }
            }
          });
        })
      );
      return Promise.all([result_study, result_series]);
    },
    [getSelectedTags, state]
  );

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      fullWidth={false}
      maxWidth="md"
    >
      <DialogTitle>Dicomデータのメタデータをタグ付け</DialogTitle>
      {loading ? (
        <Loading />
      ) : (
        <DialogContent className={classes.content} dividers>
          <DialogContentText>
            選択したフォルダ内に含まれる全てのstudy、seriesにタグを追加します。
          </DialogContentText>
          <Typography variant="body1" align="center" gutterBottom>
            {data.name || '"名前のないフォルダ"'}
          </Typography>
          <div className={classes.form}>
            {/* グループを展開する */}
            {checkGroups.map((group, groupIndex) => (
              <FormControl className={classes.formControl} key={groupIndex}>
                <FormLabel>{group.formLabel}</FormLabel>
                <FormGroup>
                  {/* チェックボックスを展開する */}
                  {group.checkBoxes.map((checkBox, index) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={state[checkBox]}
                          onChange={handleChange}
                          name={checkBox}
                        />
                      }
                      label={group.formControlLabel[index]}
                      key={index}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ))}
          </div>

          <Button onClick={handleSelectAll}>すべて選択</Button>

          <DialogActions>
            {/* チェックのないタグを削除するためのチェックボックス */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.delete}
                  onChange={handleChange}
                  name={'delete'}
                  color="primary"
                />
              }
              label={'チェックの付いていないタグを削除する'}
            />
          </DialogActions>

          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              タグ付けする
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
}

DicomTagsDialog.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    tags: PropTypes.array,
    perm: PropTypes.string,
    name: PropTypes.string,
  }),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

const useDicomTagsDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openDicomTagsDialog = data => {
    setData(data);
    setOpen(true);
  };

  function dicomTagsDialog() {
    return <DicomTagsDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openDicomTagsDialog, dicomTagsDialog];
};

export { DicomTagsDialog, useDicomTagsDialog };
