import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import './CommentForm.css';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';

const CommentForm = ({ messageInput, messageInputChange }) => {
  const { handleSubmit, watch, register, reset } = useForm({
    defaultValues: { message: messageInput },
  });
  const [isCompositionend, setIsCompositionend] = useState(false);
  const watchMessage = watch('message');
  const [isSend, setIsSend] = useState(false);
  const jaRegexp = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/;
  const submit = data => {
    // 送信ボタンを押したら入力欄をブランク状態に戻す
    reset({ message: '' });
    setIsSend(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsSend(false);
    }, 1000);
  }, [isSend, setIsSend]);
  useEffect(() => {
    messageInputChange(watchMessage);
  }, [watchMessage, messageInputChange]);

  const enterPost = keyEvent => {
    // { key: string, ctrlKey: any, metaKey: any }
    // 入力値がスペースのみ、又は入力値が日本語の場合でisCompositionendがfalseの場合は入力途中なので何もしない
    if (
      !watchMessage ||
      !watchMessage.trim() ||
      (watchMessage.match(jaRegexp) && !isCompositionend)
    ) {
      return;
    }
    if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
      submit({ message: watchMessage });
      setIsCompositionend(false);
    }
  };
  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit(submit)}>
        <textarea
          className="input"
          placeholder="メッセージ..."
          {...register('message', { required: true })}
          onKeyDown={enterPost}
          onCompositionEnd={() => {
            setIsCompositionend(true);
          }}
        />
        <IconButton color="primary">
          <SendIcon />
        </IconButton>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  messageInput: PropTypes.string,
  messageInputChange: PropTypes.func,
};

export default CommentForm;
