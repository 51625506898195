
// Custom check device type
export function getDeviceType() {
  if (isTablet()){
    return 'Tablet';
  }
  if (isMobile()){
    return 'Phone';
  }
  if (!isTablet() && (!isMobile())){
    return 'Desktop';
  }
  else{
    return 'Unknown device type';
  }
}

export function isMobileOrTablet() {
  return isMobile() || isTablet();
}

export function isMobile() {
  const ua = navigator.userAgent;
  return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua
  );
}

export function isTablet() {
  const ua = navigator.userAgent;
  var tab = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
  // console.debug('isTablet', tab);
  return tab;
}
