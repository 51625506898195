import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import SelectPerm from './SelectPerm';
import { authRequest } from '../../api/auth.js';
import { Typography } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { isMobile } from '../../utils/detectDevice';
import { CAN_VIEW } from '../../utils/permissionSettings';

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    fontSize: 14,
    // This line disable the blue border
    boxShadow: 'none',
    ':hover': {
      background: '#f0f0f0',
    },
    '.description': { display: 'none' },
  }),
};

function AccountSearch({ orgUUID, changeUserPerm, isDisabled, width }) {
  const [selectUsers, setSelectUsers] = React.useState([]);
  const [selectedPerm, setSelectedPerm] = React.useState(CAN_VIEW);
  /*
  const [inputValue, setInputValue] = React.useState('');
  const handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '');
    setInputValue(inputValue);
  };
  */

  const promiseOptions = async inputValue => {
    let url = `/api/access/user/search?query=${inputValue}`;
    if (orgUUID && orgUUID !== '') {
      url += `&org_id=${orgUUID}`;
    }
    const response = await authRequest.get(url);
    return response.data;
  };

  const handleOnClick = async () => {
    const changeUserPermPromiseList = selectUsers.map(user => {
      return changeUserPerm(user, selectedPerm);
    });
    await Promise.all(changeUserPermPromiseList);
  };

  return (
    <>
      <Grid container style={{ textAlign: 'left' }}>
        <Typography>ユーザーを招待する</Typography>
      </Grid>
      <Grid
        container
        justifyContent={'space-between'}
        style={{ border: '1px solid', borderRadius: '5px' }}
      >
        <Grid item xs={isWidthUp('sm', width) ? 6 : 12}>
          <AsyncSelect
            value={selectUsers}
            isMulti
            isClearable={false}
            placeholder="ユーザー名、メールアドレスから検索"
            loadOptions={promiseOptions} // inputのqueryを更新するたびに検索して、リストを更新
            //onInputChange={handleInputChange} // inputのqueryが変わるたびに検索をかけて、リストを更新
            onChange={setSelectUsers} // select したときに更新
            isDisabled={isDisabled}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            cacheOptions
            getOptionValue={option => option.id} // ここを指定しないと複数選択できない
            getOptionLabel={option => (
              <div>
                <Box
                  className="username"
                  sx={{
                    fontWeight: 'regular',
                    fontSize: 16,
                    textAlign: 'left',
                  }}
                >
                  {option.username}
                </Box>
                <Box
                  className="description"
                  sx={{
                    fontWeight: 'light',
                    fontSize: 14,
                    textAlign: 'left',
                  }}
                >
                  {option.email}
                </Box>
              </div>
            )}
            styles={selectStyle}
          />
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent={'flex-end'}>
            <Box width={200}>
              <SelectPerm
                perm={selectedPerm}
                changePerm={setSelectedPerm}
                withDelete={false}
                isDisabled={isDisabled}
              />
            </Box>
            <Button
              onClick={handleOnClick}
              variant="contained"
              color={'primary'}
              disabled={isDisabled}
            >
              招待
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
AccountSearch.propTypes = {
  orgUUID: PropTypes.string.isRequired,
  changeUserPerm: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default withWidth()(AccountSearch);
