const defaultState = {
  selectData: {
    type: 'folder',
    data: {},
  },
  currentFolderUUID: '',
  currentOrgUUID: '',
  tutorialId: -1,
  rootPermName: '',
};

const storageManager = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SELECTDATA':
      return Object.assign({}, state, { selectData: action.state });
    case 'SET_CURRENT_FOLDER_UUID':
      return Object.assign({}, state, { currentFolderUUID: action.state });
    case 'SET_CURRENT_ORG_UUID':
      return Object.assign({}, state, { currentOrgUUID: action.state });
    case 'SET_TUTORIAL_ID':
      return Object.assign({}, state, { tutorialId: action.state });
    case 'SET_ROOT_PERM_NAME':
      return Object.assign({}, state, { rootPermName: action.state });
    case 'RESET_STORAGE_MANEGER':
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
};

export default storageManager;
