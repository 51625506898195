import SlabThicknessToolbarComponent from './toolbarComponents/SlabThicknessToolbarComponent';
import VTKMPRToolbarButton from './toolbarComponents/VTKMPRToolbarButton';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
};

/*
* This is the 2D MPR toolbar which is displayed ONLY when 2D MPR is active.
*/
const definitions = [
  {
    id: 'Crosshairs',
    label: 'Crosshairs',
    icon: 'crosshairs',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'enableCrosshairsTool',
    commandOptions: {},
  },
  {
    id: 'WWWC',
    label: 'Levels',
    icon: 'level',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'enableLevelTool',
    commandOptions: {},
  },
  {
    id: 'Reset',
    label: 'Reset',
    icon: 'reset',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'resetMPRView',
    commandOptions: {},
  },
  {
    id: 'changeSlabThickness',
    label: 'Slab Thickness',
    icon: 'soft-tissue',
    CustomComponent: SlabThicknessToolbarComponent,
    commandName: 'setSlabThickness',
    actionButton: {
      id: 'setSlabThickness',
      label: 'slider',
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setSlabThickness',
      commandOptions: {},
    },
    deactivateButton: {
      id: 'setBlendModeToComposite',
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setBlendModeToComposite',
      commandOptions: {},
    },
    operationButtons: [
      {
        id: 'setBlendModeToMaximumIntensity',
        label: 'MIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToMaximumIntensity',
        commandOptions: {},
      },
      {
        id: 'setBlendModeToMinimumIntensity',
        label: 'MinIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToMinimumIntensity',
        commandOptions: {},
      },
      {
        id: 'setBlendModeToAverageIntensity',
        label: 'AvgIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToAverageIntensity',
        commandOptions: {},
      },
    ],
  },
  {
    id: '2DMPR',
    label: '2D MPR',
    icon: 'cube',
    //
    CustomComponent: VTKMPRToolbarButton,
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'mpr2d',
    // Display this button on the active viewport (if the viewport
    // is showing a 3D scan).
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
  },
];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::VTK',
};
