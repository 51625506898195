import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';

import {
  FULL_ACCESS,
  CAN_EDIT,
  CAN_COMMENT,
  CAN_VIEW,
  DELETE,
} from '../../utils/permissionSettings';

//import Box from '@material-ui/core/Box';

const ALL_OPTIONS = [
  {
    value: FULL_ACCESS,
    label: 'フルアクセス権限',
    description:
      '編集と他のユーザーへの共有やデータの移動を行うことができます。',
  },
  {
    value: CAN_EDIT,
    label: '編集権限',
    description:
      '編集は可能ですが、他のユーザーへの共有やデータの移動を行うことはできません。',
  },
  {
    value: CAN_COMMENT,
    label: 'コメント権限',
    description: 'コメントは可能ですが編集はできません',
  },
  {
    value: CAN_VIEW,
    label: '読み取り権限',
    description: '表示は可能ですが編集やコメントはできません',
  },
  {
    value: DELETE,
    label: '削除',
    description: '与えられた権限を削除します',
  },
  {
    value: '',
    label: '権限なし',
    description: '権限はありません',
  },
];

export default function SelectPerm({
  perm,
  changePerm,
  expiryDate,
  withDelete = true,
  withNone = false,
  isDisabled = false,
}) {
  const [options, setOptions] = React.useState(ALL_OPTIONS);
  const [option, setOption] = React.useState({});

  console.log('SelectPerm expiry date', expiryDate);

  useEffect(() => {
    let tmpOptions = ALL_OPTIONS;
    // deleteをoptionsに入れるかどうか処理
    if (!withDelete) {
      tmpOptions = tmpOptions.filter(o => o.value !== DELETE);
    }
    // noneをoptionsに入れるかどうか処理
    if (!withNone) {
      tmpOptions = tmpOptions.filter(o => o.value !== '');
    }
    setOptions(tmpOptions);
  }, [withDelete, withNone]);

  useEffect(() => {
    // optionを設定
    options.forEach(o => {
      if (o.value === perm) {
        setOption(o);
      }
    });
  }, [perm, options]);

  const onChange = async option => {
    console.log('Perm type changed:', option.value, 'expiry', expiryDate);
    if (option.value === '') {
      await changePerm(DELETE);
    } else {
      await changePerm(option.value, expiryDate);
    }
  };

  const selectStyle = {
    option: (base, state) => {
      if (state.isSelected) {
        //let backgroundColor = '#00ccff';
        let color = '#fff';
        return {
          ...base,
          height: '2em',
          color,
        };
      } else {
        return {
          ...base,
          height: '2em',
          color: '#000',
          '.description': {
            color: '#848484',
          },
        };
      }
    },
    control: base => ({
      ...base,
      border: 0,
      fontSize: 16,
      // This line disable the blue border
      boxShadow: 'none',
      ':hover': {
        background: '#f0f0f0',
      },
    }),
    menuList: base => ({
      ...base,
      //width: '150%',
      backgroundColor: '#fff',
      maxHeight: '600px', // your desired height
    }),
    input: styles => ({
      ...styles,
      '.description': { display: 'none' },
    }),
    placeholder: styles => ({
      ...styles,
      '.description': { display: 'none' },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      '.description': { display: 'none' },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: '9999 !important' }),
  };

  return (
    <Select
      value={option}
      isSearchable={false}
      options={options}
      onChange={onChange}
      styles={selectStyle}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      isDisabled={isDisabled}
      getOptionLabel={option => (
        <div
          className="label"
          style={{
            fontWeight: 'regular',
            fontSize: '1em',
            textAlign: 'left',
          }}
        >
          {option.value == DELETE ? (
            <div style={{ color: 'red' }}>{option.label}</div>
          ) : (
            <div>{option.label}</div>
          )}
          {/*
          <Box
            className="description"
            sx={{
              fontWeight: 'light',
              fontSize: 14,
              textAlign: 'left',
            }}
          >
            {option.description}
          </Box>
        */}
        </div>
      )}
    />
  );
}

SelectPerm.propTypes = {
  perm: propTypes.string,
  changePerm: propTypes.func,
  withDelete: propTypes.bool,
  withNone: propTypes.bool,
  isDisabled: propTypes.bool,
};
