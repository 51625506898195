import React, { useState, useContext, useCallback } from 'react';
import { createFolder, createCaseStudy } from '../../../../api/rest';
import { useFolderInfoReset } from '../../api/getFolderInfo';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formRoot: { zIndex: '2000 !important' },
}));

function CaseStudyDialog({ open, setOpen, conferenceUUID, editing }) {

  const refetchFolderInfo = useFolderInfoReset();

  const classes = useStyles();
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const handleClose = () => {
    setOpen(false);
  }

  const handleClick = async () => {
    console.log('Create 症例', name, 'Conference', conferenceUUID);
    setOpen(false);
    const data = { name: name, comment: comment, parent_folder: conferenceUUID };
    if (editing) {
      console.log('TODO: EDIT CaseStudy');
    } else {
      await createCaseStudy(data);
    }

    // Refresh Conference folder content after creating Case study
    await refetchFolderInfo();
    // Double-click link for any folder in Conference to go to Case study view
    // see openFolder() in AgGridCellRenderer.js
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      className={classes.formRoot}
    >
      <DialogTitle>
        { editing ? (
          '症例を編集する'
        ) : (
          '症例を追加する'
        )}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="症例の名前"
          placeholder="例：【出展】症例１中枢神経領域"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          fullWidth
          onFocus={event => event.target.select()}
        />
        <TextField
          label="症例の内容"
          placeholder="例：〇〇先生（〇〇病院）"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          onFocus={event => event.target.select()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClick} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CaseStudyDialog;
