import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import SelectPerm from './SelectPerm';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { CAN_VIEW, DELETE } from '../../utils/permissionSettings';

// https://material-ui-pickers.dev/getting-started/usage
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: '30%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },

  customAccordionSummary: {
    '&$expanded': {
      minHeight: 48,
    },
  },
  customAccordionSummaryContent: {
    padding: 0,
    margin: 0,
    '&$expanded': {
      padding: 0,
      margin: 0,
    },
  },
  expandIcon: {
    transform: 'None',
    transition: 'None',
    '&$expanded': {
      transform: 'None',
    },
    padding: 0,
    margin: 0,
  },
  customAccordionDetails: {
    justifyContent: 'flex-end',
    padding: 5,
  },
  expanded: {},
}));

function readExpiry(expiry){
  if (expiry){
    return useState(new Date(expiry).toJSON().split("T")[0]);
  } else {
    return useState(null);
  }
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString();
}

export default function ShareWeb({ perm, expiry, isPermanent, changePerm, isDisabled }) {
  console.log('Expiry from backend', expiry, 'permanent: ', isPermanent);
  const classes = useStyles();

  // selectedDateのデフォルトは一週間後（backendから読み取ったexpiry)
  // 日付のみ（時間は必要ない）　https://stackoverflow.com/a/58240892
  // const [expiryDate, setExpiryDate] = useState(new Date(expiry).toJSON().split("T")[0]);
  const [expiryDate, setExpiryDate] = readExpiry(expiry);
  const [currentPerm, setCurrentPerm] = useState('');
  const [permanentLink, setPermanentLink] = useState(isPermanent);

  const onDateChange = async (date) => {
    setExpiryDate(date);
    console.log('Selected date', date);
    // TODO: Save to backend along with permission (at least CAN_VIEW, but could also be higher perms)
    // await changePerm(CAN_VIEW, date);
    await changePerm(perm, date);
  };

  const handleChange = async event => {
    if (event.target.checked) {
      setCurrentPerm(CAN_VIEW);
      await changePerm(CAN_VIEW, expiryDate);
    } else {
      setCurrentPerm(DELETE);
      // expiryDateを削除する
      await changePerm(DELETE, null);
    }
  };

  const handlePermTypeChange = async event => {
    console.log('Perm type changed')
  }

  const handleNoExpiry = async event => {
    if (event.target.checked) {
      setExpiryDate(null);
      setPermanentLink(true);
      console.log('Permanent anon link');
      // TODO: fix this CAN_VIEWじゃなくて他の権限ある
      // await changePerm(CAN_VIEW, null);
      await changePerm(perm, null);
    } else {
      // set expiry date to default 7 days
      var newExpiry = addDays(new Date(), 7);
      console.log('Permanent anon link REMOVED, expiry', newExpiry);
      setPermanentLink(false);
      // TODO: fix this CAN_VIEWじゃなくて他の権限ある
      // await changePerm(CAN_VIEW, newExpiry);
      await changePerm(perm, newExpiry);
    }
  };

  return (
    <List disablePadding>
      <ListItem disableGutters>
        <ListItemIcon>
          <PublicIcon />
        </ListItemIcon>
        <ListItemText
          primary="webで公開する"
          secondary="誰でもアクセスできるようになります。"
        />
        <Switch
          edge="end"
          checked={perm != ''}
          onChange={handleChange}
          color="primary"
          disabled={isDisabled}
        />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText
          primary="有効期限を設定"
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPermanent}
                onChange={handleNoExpiry}
                name="isPermanentCheckbox"
                color="primary"
                disabled={perm == '' || isDisabled}
              />
            }
            label="無期限リンク"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={expiryDate}
            format="yyyy-MM-dd"
            emptyLabel="無期限"
            onChange={onDateChange}
            disabled={perm == '' || isDisabled || isPermanent}
          />
        </MuiPickersUtilsProvider>

      </ListItem>
      <ListItem>
        <ListItemSecondaryAction>
          <div style={{ width: '200px' }}>
            <SelectPerm
              perm={perm}
              changePerm={changePerm}
              // onChange={handlePermTypeChange}
              expiryDate={expiry}
              // changePerm={changePerm(perm, expiryDate)}
              withDelete={false}
              isDisabled={perm == '' || isDisabled}
            />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

ShareWeb.propTypes = {
  perm: PropTypes.string.isRequired,
  changePerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
