import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
  grey: {
    color: theme.palette.grey[700],
  },
  flexLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default function DialogTitle(props) {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className={classes.flexLeft}>{children}</Box>
        <Box className={classes.flexRight}>
          {onClose ? (
            <Tooltip title="閉じる">
              <IconButton
                aria-label="close"
                className={classes.grey}
                onClick={onClose}
                size="small"
                edge="end"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
    </MuiDialogTitle>
  );
}

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};
