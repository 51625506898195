import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedOrgUUID } from './currentOrgUUID';

const getOrgTagList = async ({ orgUUID }) => {
  const url = `/api/tags/org-tag-list/${orgUUID}`;
  return await authRequest.get(url).then(res => res.data);
};

export const useOrgTagList = ({ orgUUID = '' } = {}) => {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const placeholderData = useMemo(() => [], []);
  return useQuery({
    queryKey: ['orgTagList', usedOrgUUID],
    queryFn: () => getOrgTagList({ orgUUID: usedOrgUUID }),
    enabled: !!usedOrgUUID && usedOrgUUID !== '', // org uuid が存在するまで待機
    placeholderData,
  });
};

export function useOrgTagListInvalidate({ orgUUID = '' } = {}) {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const queryClient = useQueryClient();
  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['orgTagList', usedOrgUUID]);
  }, [queryClient, usedOrgUUID]);
  return invalidateQueries;
}
