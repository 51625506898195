const longPressDuration = 610;

/*
  iosで長押しがonContextMenuにならないのでハンドリングする。
  onTouchStartはstopPropagationなどが利用できないので、2重に用いないようにする。
*/
export default class ContextMenuHandler {
  constructor(callback, printLine = input => {}) {
    this.callback = callback;
    this.longPressCountdown = null;
    this.contextMenuPossible = false;
    this.printLine = printLine;
  }

  onTouchStart = e => {
    this.printLine('onTouchStart');
    this.contextMenuPossible = true;
    const touch = e.touches[0];
    this.longPressCountdown = setTimeout(() => {
      this.contextMenuPossible = false;
      this.callback(touch);
    }, longPressDuration);
  };

  onTouchMove = e => {
    this.printLine('onTouchMove');
    clearTimeout(this.longPressCountdown);
  };

  onTouchCancel = e => {
    this.printLine('onTouchCancel');
    this.contextMenuPossible = false;
    clearTimeout(this.longPressCountdown);
  };

  onTouchEnd = e => {
    this.printLine('onTouchEnd');
    this.contextMenuPossible = false;
    clearTimeout(this.longPressCountdown);
  };

  onContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    this.printLine('onContextMenu');
    this.contextMenuPossible = false;
    clearTimeout(this.longPressCountdown);
    this.callback(e);
  };
}
