import dcmjs from 'dcmjs';
import FileLoaderService from './localFileLoaders/fileLoaderService';
import { DICOM_METADATA_KEYS } from '../utils/constants';

export const processFile = async file => {
  try {
    const fileLoaderService = new FileLoaderService(file);
    const imageId = fileLoaderService.addFile(file);
    const image = await fileLoaderService.loadFile(file, imageId);
    const dataset = await fileLoaderService.getDataset(image, imageId);
    const studies = await fileLoaderService.getStudies(dataset, imageId);

    return studies;
  } catch (error) {
    console.log(
      error.name,
      ':Error when trying to load and process local files:',
      error.message
    );
  }
};

export const processFileForUpload = async dcmFile => {
  const fileLoaderService = new FileLoaderService(dcmFile);
  const imageId = fileLoaderService.addFile(dcmFile);
  const image = await fileLoaderService.loadFile(dcmFile, imageId);
  const dataset = await fileLoaderService.getDataset(image, imageId);
  const studyMetadata = await fileLoaderService.getStudiesForUpload(
    dataset,
    dcmFile.path,
    dcmFile.filetype,
    dcmFile.size
  );
  return studyMetadata;
};

export async function filesToStudiesForUpload(dcmFiles) {
  const processFilesPromises = dcmFiles.map(processFileForUpload);
  const studies = await Promise.all(processFilesPromises);
  return FileLoaderService.groupSeries(studies.flat());
}

export async function anonymizeAndGetMetadata(fileItem) {
  console.log('anonymizeAndGetMetadata');
  // 匿名化・編集結果の上書きをした新しいfileの作成
  const {
    isEdit,
    isAnonymize,
  } = window.store.getState().studies.anonymizedRadio;

  console.log('isEdit', isEdit, 'isAnonymize', isAnonymize);
  const anonymizedStudies = window.store.getState().studies.anonymizedStudyData;

  const fileLoaderService = new FileLoaderService(fileItem);
  const imageId = fileLoaderService.addFile(fileItem);
  const image = await fileLoaderService.loadFile(fileItem, imageId);

  let dataset = await fileLoaderService.getDataset(
    image,
    imageId,
    anonymizedStudies,
    isEdit,
    isAnonymize
  );
  const blob = dcmjs.data.datasetToBlob(dataset);
  console.debug('datasetToBlob', blob);
  const file = new File([blob], `${fileItem.path}`, {
    lastModified: fileItem.lastModified,
    path: fileItem.path,
    type: fileItem.type,
  });
  const fileWithPath = defineNewProperty(file, 'path', fileItem.path);
  console.debug('fileWithPath', fileWithPath);
  const fileWithPathAndFiletype = defineNewProperty(
    fileWithPath,
    'filetype',
    fileItem.filetype
  );
  // metadata付与
  let metadata = {};
  const keys = Object.keys(dataset);
  DICOM_METADATA_KEYS.forEach(key => {
    if (keys.includes(key)) {
      metadata[key] = dataset[key];
      if (dataset[key] instanceof String) {
        metadata = {
          ...metadata,
          [key]: dataset[key].valueOf(),
        };
      }
    }
  });
  const fileWithPathAndfiletypeAndMetadata = defineNewProperty(
    fileWithPathAndFiletype,
    'metadata',
    metadata
  );
  console.debug(
    'fileWithPathAndfiletypeAndMetadata',
    fileWithPathAndfiletypeAndMetadata
  );
  return fileWithPathAndfiletypeAndMetadata;
}

function defineNewProperty(item, name, value) {
  Object.defineProperty(item, name, {
    value: value,
    writable: false,
    configurable: false,
    enumerable: true,
  });
  return item;
}

function toFileWithPath(f, path) {
  Object.defineProperty(f, 'path', {
    value: path,
    writable: false,
    configurable: false,
    enumerable: true,
  });
  return f;
}

function toFileWithFiletype(f, filetype) {
  Object.defineProperty(f, 'filetype', {
    value: filetype,
    writable: false,
    configurable: false,
    enumerable: true,
  });
  return f;
}
