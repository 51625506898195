// auth.js
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

// termsOfService.js
const IS_TERMS_OF_SERVICE_AGREE = 'is_terms_of_service_agree';

// AgGrid
const SELECTED_COLUMN_FIELDS = 'selected_colum_fields';
const DEFAULT_COLUMN_FIELDS = 'default_colum_fields';

// Tutorial.js
const SAW_TUTORIAL = 'saw_tutorial';

// orgLastUrl.js
const ORG_LAST_URL = 'org_last_url';

export {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IS_TERMS_OF_SERVICE_AGREE,
  SELECTED_COLUMN_FIELDS,
  DEFAULT_COLUMN_FIELDS,
  SAW_TUTORIAL,
  ORG_LAST_URL,
};
