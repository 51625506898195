import React, { createContext, useMemo } from 'react';
import propsTypes from 'prop-types';
import useEditMetaModal from '../features/storage/components/misc/EditMetaModal';
import useShareModal from '../features/sharePerm/ShareModal';
import {
  useDeleteDialog,
  useRenameDialog,
  useRenameURLFileDialog,
  useCreateURLFileDialog,
  useCreateFolderDialog,
  useCreateRootFolderDialog,
} from '../components/FormDialog';
import { useDicomTagsDialog } from '../features/storage/components/tags/DicomTagsDialog';
import { useMovePopover } from '../features/storage/components/MovePopover';
import { useSettingMembers } from '../features/storage/components/settingMembers/SettingMembers';
import useAnonymizeDialog from '../features/annonymization/AnonymizeDialog';
import { useFolderSettingsDialog } from '../features/storage/components/FolderSettings';
import useConfirmDuplicateModal from '../features/storage/components/misc/ConfirmDuplicateModal';
import useConfirmAbortUploadModal from '../features/storage/components/misc/ConfirmAbortUploadModal';
import { isMobile } from '../utils/detectDevice';

const ModalContext = createContext({});

const ModalProvider = props => {
  // メタデータ編集
  const [EditMetaModal, openEditMeta] = useEditMetaModal();
  const [ShareModal, openShareModal] = useShareModal();
  const [openDeleteDialog, DeleteDialog] = useDeleteDialog();
  const [openRenameDialog, RenameDialog] = useRenameDialog();
  const [
    openRenameURLFileDialog,
    RenameURLFileDialog,
  ] = useRenameURLFileDialog();
  const [
    openCreateURLFileDialog,
    CreateURLFileDialog,
  ] = useCreateURLFileDialog();
  const [openCreateFolderDialog, CreateFolderDialog] = useCreateFolderDialog();
  const [
    openCreateRootFolderDialog,
    CreateRootFolderDialog,
  ] = useCreateRootFolderDialog();
  const [openDicomTagsDialog, DicomTagsDialog] = useDicomTagsDialog();
  const [openSettingMembers, SettingMembers] = useSettingMembers();
  const [openMovePopover, MovePopover] = useMovePopover();
  const [openAnonymizeDialog, AnonymizeDialog] = useAnonymizeDialog();
  const [
    openFolderSettingsDialog,
    FolderSettingsDialog,
  ] = useFolderSettingsDialog();
  const [
    openConfirmDuplicateModal,
    ConfirmDuplicateModal,
  ] = useConfirmDuplicateModal();
  const [
    openConfirmAbortUploadModal,
    ConfirmAbortUploadModal,
  ] = useConfirmAbortUploadModal();

  // 左右のDrawer
  const [openDrawer, setOpenDrawer] = React.useState(!isMobile());
  const [openDrawerInfo, setOpenDrawerInfo] = React.useState(false);

  return (
    <ModalContext.Provider
      value={{
        openEditMeta,
        openShareModal,
        openDeleteDialog,
        openRenameDialog,
        openRenameURLFileDialog,
        openCreateURLFileDialog,
        openCreateFolderDialog,
        openCreateRootFolderDialog,
        openDicomTagsDialog,
        openMovePopover,
        openAnonymizeDialog,
        openFolderSettingsDialog,
        openConfirmDuplicateModal,
        openConfirmAbortUploadModal,
        openDrawer,
        setOpenDrawer,
        openDrawerInfo,
        setOpenDrawerInfo,
        openSettingMembers,
      }}
    >
      <EditMetaModal />
      <ShareModal />
      <DeleteDialog />
      <RenameDialog />
      <RenameURLFileDialog />
      <CreateURLFileDialog />
      <CreateFolderDialog />
      <CreateRootFolderDialog />
      <DicomTagsDialog />
      <MovePopover />
      <SettingMembers />
      <AnonymizeDialog />
      <FolderSettingsDialog />
      <ConfirmDuplicateModal />
      <ConfirmAbortUploadModal />
      {props.children}
    </ModalContext.Provider>
  );
};

ModalProvider.propsTypes = {
  children: propsTypes.node.isRequired,
};

export { ModalContext, ModalProvider };
