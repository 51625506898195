import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    margin: theme.spacing(3),
    // backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function DataNotFound() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <HelpOutlineOutlinedIcon className={classes.icon} />
        <Typography component="h1" variant="h4" gutterBottom>
          データが存在しません
        </Typography>
        <Typography component="h4" variant="h6" gutterBottom>
          このデータは既に削除されたか、URLに含まれるIdに誤りがある可能性があります。
        </Typography>
        <Grid container className={classes.buttons}>
          <Grid item xs={6} container justifyContent="center">
            <Button color="primary" href="/">
              トップに戻る
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="center">
            <Button color="primary" href="/login">
              ログイン
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
