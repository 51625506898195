import React, {
  useContext,
  useRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

import { Grid, Box, Typography, Button } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Popover from '@material-ui/core/Popover';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import Checkbox from '@material-ui/core/Checkbox';

import { SnackbarContext } from '../../../../context/SnackbarContext';
import { allColumnsDefs } from '../../utils/aggridUtils';

import { DEFAULT_COLUMN_FIELDS } from '@ohif/viewer/src/utils/localStorageKeys';

import { useMultiUrl } from '../../providers/MultiUrlProvider';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export const acceptType = 'columnsCheckList';

function DraggableCheckList(props) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: acceptType,
    collect: monitor => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.onMoveColumn(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging, draggingItem }, drag] = useDrag({
    item: {
      index: props.index,
      type: acceptType,
      colDef: props.selectedCol,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      draggingItem: monitor.getItem(),
    }),
    isDragging: () => {
      return true;
    },
  });
  const opacity =
    isDragging && props.selectedCol.field == draggingItem.field ? 0 : 1;
  drag(drop(ref));

  return (
    <FormControlLabel
      ref={ref}
      key={props.index}
      control={
        <Checkbox
          checked={!props.selectedCol.hide}
          onChange={props.handleChangeColumnHide}
          name={props.selectedCol.field}
          size="small"
          color="primary"
        />
      }
      label={props.selectedCol.headerName}
      style={{ opacity }}
      data-handler-id={handlerId}
    />
  );
}

DraggableCheckList.propTypes = {
  index: PropTypes.number.isRequired,
  selectedCol: PropTypes.object.isRequired,
  columnDefs: PropTypes.array.isRequired,
  handleChangeColumnHide: PropTypes.func.isRequired,
  onMoveColumn: PropTypes.func.isRequired,
};

export const getInitSelectedCOlumnFields = () => {
  return allColumnsDefs.map(colDef => {
    return {
      field: colDef.field,
      headerName: colDef.headerName,
      hide: false,
    };
  });
};

const useToolbarStyles = makeStyles(theme => ({
  columnPopover: {
    width: '300px',
    padding: theme.spacing(1, 0, 1, 2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbarContainer: {
    display: 'flex',
    padding: '0rem',
    justifyContent: 'space-between',
  },
  flexLeft: {
    flex: 1,
  },
  flexRight: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginLeft: 'auto',
    maxWidth: '200px',
  },
}));

export const CheckedItemToolbar = props => {
  // A toolbar Component displayed above the AG Grid table
  // Provides actions to be done with the checked (selected) subset of items
  // Get org from props passed to this component
  const classes = useToolbarStyles();
  const [checkedStudyUrl, setCheckedStudyUrl] = useState('');

  // Import the checked state provider
  const { checkedStudyIDs } = useMultiUrl();
  useEffect(() => {
    // Join the checked study UIDs into a comma-separated string
    const urlParams = Array.from(checkedStudyIDs).join(',');
    const viewerUrl = '/universal-viewer?orgId=' + props.orgId
      + '&studyUId=' + urlParams;
    setCheckedStudyUrl(viewerUrl);
  }, [checkedStudyIDs]);

  const openViewerChecked = async () => {
    console.warn('Open Viewer:', checkedStudyUrl);
    window.open(checkedStudyUrl);
  };

  const onCopyURL = () => {
    const message = 'URLはコピーされました';
    toast.info(message);
  }

  return (
    <div>
      { checkedStudyIDs.size > 1 && (
        <div>
          <Button
            className={classes.button}
            disabled={checkedStudyIDs.size<=1}
            onClick={openViewerChecked}
            color="primary"
            variant="contained"
            size="small"
          >
            {checkedStudyIDs.size}の項目を開く
          </Button>
          <CopyToClipBoard text={window.location.origin + checkedStudyUrl} onCopy={onCopyURL}>
            <Button
              className={classes.button}
              disabled={checkedStudyIDs.size<=1}
              color="primary"
              variant="contained"
              size="small"
            >
              {checkedStudyIDs.size}URLをコピー
            </Button>
          </CopyToClipBoard>
        </div>
      )}
    </div>
  );
}

export const AgGridTableToolbar = props => {
  const classes = useToolbarStyles();
  const { setOpenSnackbar, setSnackbarMessage } = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveAndSetSelectedColumns = useCallback(
    newSelectedColumns => {
      props.saveSelectedColumns(newSelectedColumns);
      // set aggrid table
      if (props.setColumnDefs !== undefined) {
        props.setColumnDefs(
          newSelectedColumns
            .filter(col => !col.hide)
            .map(col =>
              allColumnsDefs.find(colDef => colDef.field == col.field)
            )
        );
      }
    },
    [props]
  );

  const handleChangeColumnHide = event => {
    event.persist();
    const newSelectedColumns = props.selectedColumns.map(col => {
      if (col.field != event.target.name) {
        return col;
      } else {
        return { ...col, hide: !event.target.checked };
      }
    });
    saveAndSetSelectedColumns(newSelectedColumns);
  };

  const handleCheckAll = () => {
    const newSelectedColumns = props.selectedColumns.map(col => {
      return { ...col, hide: false };
    });
    saveAndSetSelectedColumns(newSelectedColumns);
  };

  const handleUnchekAll = () => {
    const newSelectedColumns = props.selectedColumns.map(col => {
      return { ...col, hide: true };
    });
    saveAndSetSelectedColumns(newSelectedColumns);
  };

  const handleResetOrder = () => {
    const initSelectedColumnFields = getInitSelectedCOlumnFields();
    const newSelectedColumns = initSelectedColumnFields.map(col => {
      return props.selectedColumns.find(
        selectedCol => selectedCol.field == col.field
      );
    });
    saveAndSetSelectedColumns(newSelectedColumns);
  };

  const handleSaveDefault = () => {
    window.localStorage.setItem(
      DEFAULT_COLUMN_FIELDS,
      JSON.stringify(props.selectedColumns)
    );
    setOpenSnackbar(true);
    setSnackbarMessage('デフォルトのカラムを保存しました。');
    setInterval(() => {
      setOpenSnackbar(false);
    }, 2000);
  };

  const handleLoadDefault = () => {
    const defaultColumnFields = JSON.parse(
      window.localStorage.getItem(DEFAULT_COLUMN_FIELDS)
    );
    if (defaultColumnFields) {
      saveAndSetSelectedColumns(defaultColumnFields);
    } else {
      alert('デフォルトのカラム設定がありません');
    }
  };

  const handlePutUrlParams = () => {
    window.location.search = `columns=${props.selectedColumns
      .map(selectedCol => selectedCol.field)
      .join(',')}`;
  };

  const onMoveColumn = useCallback(
    (dragIndex, hoverIndex) => {
      // swap props.selectedColumns by two index
      const newArray = update(props.selectedColumns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, props.selectedColumns[dragIndex]],
        ],
      });
      saveAndSetSelectedColumns(newArray);
    },
    [props.selectedColumns, saveAndSetSelectedColumns]
  );

  return (
    <div className={classes.toolbarContainer}>
      <div className={classes.flexLeft}>
        <CheckedItemToolbar orgId={props.orgId} />
      </div>
      <div className={classes.flexRight}>
        <Button onClick={handleOpen}>
          <SettingsIcon />
        </Button>
        <Popover
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.columnPopover}
          >
            {!props.isSetParamsColumns ? (
              <>
                {/* 全選択とリセット */}
                <Grid>
                  <Button color="primary" onClick={handleCheckAll} size="small">
                    全て選択
                  </Button>
                  <Button onClick={handleUnchekAll} size="small">
                    全て解除
                  </Button>
                  <Tooltip title="カラムの順序を初期の状態にリセットします">
                    <Button onClick={handleResetOrder} size="small">
                      順番をリセット
                    </Button>
                  </Tooltip>
                </Grid>

                {/* DraggableCheckList */}
                <FormControl
                  style={{
                    width: '100%',
                    maxHeight: '200px',
                    overflow: 'auto',
                  }}
                >
                  <FormGroup>
                    {props.selectedColumns.map((selectedCol, index) => (
                      <DraggableCheckList
                        key={index}
                        index={index}
                        selectedCol={selectedCol}
                        columnDefs={props.columnDefs}
                        handleChangeColumnHide={handleChangeColumnHide}
                        onMoveColumn={onMoveColumn}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <FormHelperText>
                  {props.columnDefs.length} カラムを選択中
                </FormHelperText>
                {!props.isSetParamsColumns && (
                  <Box>
                    <DialogActions
                      style={{
                        padding: 0,
                      }}
                    >
                      <Button onClick={handleSaveDefault}>
                        現在の設定をデフォルトにする
                      </Button>
                      <Button onClick={handleLoadDefault}>
                        デフォルトを適用する
                      </Button>
                    </DialogActions>

                    <Tooltip title="URLにカラムを指定することで、簡単にカラム情報を共有できます">
                      <Button onClick={handlePutUrlParams}>
                        URLに設定されたカラムを付与する
                      </Button>
                    </Tooltip>
                  </Box>
                )}
              </>
            ) : (
              <Grid
                container
                style={{
                  width: '200px',
                  height: '70px',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip title="urlによって固定化されたカラムを解除し、変更可能にします。">
                  <Button
                    color="default"
                    size="large"
                    onClick={() => {
                      window.location.href =
                        window.location.origin + window.location.pathname;
                    }}
                  >
                    変更可能にする
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Popover>
      </div>
    </div>
  );
};

AgGridTableToolbar.propTypes = {
  selectedColumns: PropTypes.array.isRequired,
  saveSelectedColumns: PropTypes.func.isRequired,
  setColumnDefs: PropTypes.func.isRequired,
  columnDefs: PropTypes.array.isRequired,
  isSetParamsColumns: PropTypes.bool.isRequired,
};
