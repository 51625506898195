import {
  ORGANIZATION_ADMIN,
  ORGANIZATION_MEMBER,
  FULL_ACCESS,
  CAN_COMMENT,
  CAN_EDIT,
  CAN_VIEW,
} from './permissionSettings';

export function checkFullAccess(perm_str) {
  if (perm_str == FULL_ACCESS) {
    return true;
  } else {
    return false;
  }
}

export function checkCanEdit(perm_str) {
  if (perm_str == FULL_ACCESS || perm_str == CAN_EDIT) {
    return true;
  } else {
    return false;
  }
}

export function checkCanComment(perm_str) {
  if (
    perm_str == FULL_ACCESS ||
    perm_str == CAN_EDIT ||
    perm_str == CAN_COMMENT
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkCanView(perm_str) {
  if (
    perm_str == FULL_ACCESS ||
    perm_str == CAN_EDIT ||
    perm_str == CAN_COMMENT ||
    perm_str == CAN_VIEW
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkAdminPerm(perm_str) {
  if (perm_str == ORGANIZATION_ADMIN) {
    return true;
  } else {
    return false;
  }
}

export function checkMemberPerm(perm_str) {
  if (perm_str == ORGANIZATION_ADMIN || perm_str == ORGANIZATION_MEMBER) {
    return true;
  } else {
    return false;
  }
}
