import { QueryClient } from '@tanstack/react-query';

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 1, // 1min // 3
    cacheTime: 1000 * 60 * 5, // 5min
    retry: false,
    //suspense: true, // ここでsuspenseモードを全体で有効化する
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
