import React, { createContext, useContext, useState } from 'react';

const ExpiryDateContext = createContext();

export const ExpiryDateProvider = ({ children }) => {
  // 全検査の共有の有効期限
  // Initialize to null, instead of empty string (which causes an "Invalid Date Format" warning)
  const [expiryDate, setExpiryDate] = useState(null);
  // Global on-off sharing switch
  const [globalShared, setGlobalShared] = useState(false);

  return (
    <ExpiryDateContext.Provider value={
        { expiryDate, setExpiryDate, globalShared, setGlobalShared }
      }>
      {children}
    </ExpiryDateContext.Provider>
  );
};

export const useExpiryDate = () => useContext(ExpiryDateContext);
