import React, { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';
import { authRequest } from '../../../../api/auth';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { useCheckOrgPerm } from '../../../organization/api/getOrgUserPerm';
import {
  ORGANIZATION_ADMIN,
  ORGANIZATION_MEMBER,
  DELETE,
} from '../../../../utils/permissionSettings';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  Dialog,
  DialogContent,
  Divider,
  Avatar,
  Popover,
  Typography,
  Paper,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

const useStyles = makeStyles(theme => ({
  section: {
    margin: theme.spacing(2, 0),
  },
  button: {
    textTransform: 'none',
  },
  menu: {
    maxHeight: 300,
  },
  list: {
    margin: theme.spacing(0),
    width: 300,
  },
  delete: {
    color: 'error',
  },
  addButton: {
    textTransform: 'none',
    margin: theme.spacing(2, 0, 0, 0),
  },
  addMembersDialog: {
    height: '500px',
  },
}));

const ALL_OPTIONS = [
  {
    value: ORGANIZATION_ADMIN,
    label: '管理者',
    description: '組織の参加者の権限を管理することができます。',
  },
  {
    value: ORGANIZATION_MEMBER,
    label: 'メンバー',
    description:
      'ストレージを作成し、ファイルをアップロードすることができます。',
  },
  {
    value: DELETE,
    label: '削除',
    description: '組織のメンバーから削除します',
  },
];

function SelectPerm({ perm, changePerm, isDisabled }) {
  // {perm}と書かれた選択式ボタンを表示し、選択に変更があったときにchangePerm(option.value)を実行する
  // 選択肢はALL_OPTIONSで定義する

  const classes = useStyles();
  const statusToJa = {
    org_admin: '管理者',
    org_member: 'メンバー',
    delete: '削除',
  };

  // Menuのstate, handleOpenを呼び出したtargetをセット
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = option => () => {
    changePerm(option);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleOpen}
        endIcon={<ArrowDropDownOutlinedIcon />}
        disabled={isDisabled}
      >
        {statusToJa[perm]}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* 権限の選択画面 */}
        <Paper className={classes.orgPaper}>
          <List component="nav" className={classes.list}>
            {ALL_OPTIONS.map((option, index) => (
              <ListItem key={index} button onClick={handleClick(option.value)}>
                <ListItemText
                  primaryTypographyProps={
                    option.value == DELETE && classes.delete
                  }
                  primary={option.label}
                  secondary={option.description}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  );
}
SelectPerm.propTypes = {
  perm: PropTypes.string,
  changePerm: PropTypes.func,
  isDisabled: PropTypes.bool,
};

function UserListItem({ user, perm, changeUserPerm }) {
  // user情報を表示する
  const {
    data: { adminPerm },
  } = useCheckOrgPerm();

  const classes = useStyles();
  const changePerm = newPerm => {
    changeUserPerm(user, newPerm);
  };

  let username = user.username;
  if (username.length == 0) {
    username = user.email;
  }

  return (
    <>
      <ListItem dense>
        <ListItemAvatar>
          <Avatar>{username[0].toUpperCase()}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={user.username}
          secondary={user.email}
          classes={{
            root: classes.root,
            multiline: classes.multiline,
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
        <ListItemSecondaryAction className={classes.listItem}>
          <div>
            <SelectPerm
              perm={perm}
              changePerm={changePerm}
              isDisabled={!adminPerm}
            />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      {/* <Divider /> */}
    </>
  );
}
UserListItem.propTypes = {
  user: PropTypes.object,
  perm: PropTypes.string,
  changeUserPerm: PropTypes.func,
};

function AccountSearch({ orgUUID, fetchPermData, changeUserPerm, isDisabled }) {
  const [selectUsers, setSelectUsers] = React.useState([]);
  const [selectedPerm, setSelectedPerm] = React.useState(ORGANIZATION_MEMBER);
  /*
  const [inputValue, setInputValue] = React.useState('');
  const handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '');
    setInputValue(inputValue);
  };
  */

  const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
      fontSize: 14,
      // This line disable the blue border
      boxShadow: 'none',
      ':hover': {
        background: '#f0f0f0',
      },
      '.description': { display: 'none' },
    }),
  };

  const promiseOptions = async inputValue => {
    let url = `/api/access/user/search?query=${inputValue}`;
    if (orgUUID && orgUUID !== '') {
      url += `&org_id=${orgUUID}`;
    }
    const response = await authRequest.get(url);
    return response.data;
  };

  const handleOnClick = () => {
    // userをすべて更新したら、fetchPermDataを呼び出して、selectUsers, inputValueを初期化する
    selectUsers.forEach(user => {
      changeUserPerm(user, selectedPerm);
    });
    fetchPermData();
    setSelectUsers([]);
    setSelectedPerm(ORGANIZATION_MEMBER);
    //setInputValue('');
  };

  return (
    <>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Grid container style={{ textAlign: 'left' }}>
          <Typography>ユーザーを招待する</Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          style={{ border: '1px solid', borderRadius: '5px' }}
        >
          <Grid item xs={7}>
            <AsyncSelect
              value={selectUsers}
              isMulti
              isClearable={false}
              placeholder="ユーザー名、メールアドレスから検索"
              loadOptions={promiseOptions} // inputのqueryを更新するたびに検索して、リストを更新
              //onInputChange={handleInputChange} // inputのqueryが変わるたびに検索をかけて、リストを更新
              onChange={setSelectUsers} // select したときに更新
              isDisabled={isDisabled}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              cacheOptions
              getOptionValue={option => option.id} // ここを指定しないと複数選択できない
              getOptionLabel={option => (
                <div>
                  <Box
                    className="username"
                    sx={{
                      fontWeight: 'regular',
                      fontSize: 16,
                      textAlign: 'left',
                    }}
                  >
                    {option.username}
                  </Box>
                  <Box
                    className="description"
                    sx={{
                      fontWeight: 'light',
                      fontSize: 14,
                      textAlign: 'left',
                    }}
                  >
                    {option.email}
                  </Box>
                </div>
              )}
              styles={selectStyle}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectPerm
              perm={selectedPerm}
              changePerm={setSelectedPerm}
              withDelete={false}
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <Button
              onClick={handleOnClick}
              variant="contained"
              color={'primary'}
              disabled={isDisabled}
            >
              招待
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
AccountSearch.propTypes = {
  orgUUID: PropTypes.string.isRequired,
  fetchPermData: PropTypes.func,
  changeUserPerm: PropTypes.func,
  isDisabled: PropTypes.bool,
};

function AddMembersButton({ fetchPermData, changeUserPerm, isDisabled }) {
  // メンバー追加用ボタンと、その表示画面
  //

  const classes = useStyles();
  // Dialogのstate
  const [openDialog, setOpenDialog] = React.useState(false);
  const { data: orgDetails } = useOrgDetail();
  const handleOpenDialog = event => {
    setOpenDialog(open => true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(open => false);
  };

  return (
    <>
      <Button
        className={classes.addButton}
        onClick={handleOpenDialog}
        variant="outlined"
        disabled={isDisabled}
      >
        Add Members
      </Button>
      <Dialog
        open={openDialog}
        fullWidth
        onClose={handleCloseDialog}
        maxWidth={'sm'}
      >
        <DialogContent>
          <Box className={classes.addMembersDialog}>
            <AccountSearch
              orgUUID={orgDetails.uuid}
              fetchPermData={fetchPermData}
              changeUserPerm={changeUserPerm}
              isDisabled={isDisabled}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
AddMembersButton.propTypes = {
  fetchPermData: PropTypes.func,
  changeUserPerm: PropTypes.func,
  isDisabled: PropTypes.bool,
};

function PermInfo() {
  const classes = useStyles();
  const {
    data: { adminPerm, memberPerm },
  } = useCheckOrgPerm();

  return (
    <>
      <Box className={classes.section}>
        <Typography>あなたのアクセス権限レベル</Typography>
        <Typography>
          {adminPerm ? '管理者' : memberPerm ? 'メンバー' : '権限なし'}
        </Typography>
      </Box>
    </>
  );
}

function MembersPage({ userList, fetchUserList, permTypeToStatus }) {
  const {
    data: { adminPerm },
    refetch: refetchOrgPerm,
  } = useCheckOrgPerm();
  const { data: orgDetails } = useOrgDetail();

  useEffect(() => {
    fetchUserList();
    refetchOrgPerm();
  }, [fetchUserList, refetchOrgPerm]);

  const changeUserPerm = useCallback(
    // userの権限を変更して更新
    (user, newPerm) => {
      let url = `/api/access/org/user-perm/${orgDetails.uuid}`;
      authRequest
        .patch(url, { email: user.email, perm: newPerm })
        .then(res => {
          fetchUserList();
          refetchOrgPerm();
        })
        .catch(err => {
          if ('detail' in err.response.data) {
            alert(err.response.data['detail']);
          }
        });
    },
    [fetchUserList, refetchOrgPerm, orgDetails.uuid]
  );

  return (
    <>
      <Typography>メンバー</Typography>
      <Typography color="textSecondary">
        組織に参加しているユーザーの権限を管理します
      </Typography>
      <Typography color="textSecondary">
        管理者権限を持つユーザーのみが操作可能です
      </Typography>

      <PermInfo />
      <AddMembersButton
        fetchPermData={fetchUserList}
        changeUserPerm={changeUserPerm}
        isDisabled={!adminPerm}
      />
      <List>
        <ListSubheader component="div">{'User access level'}</ListSubheader>
        <Divider />
        {userList &&
          Object.keys(userList).map(permType => {
            return userList[permType].map((user, index) => (
              <UserListItem
                key={index}
                user={user}
                perm={permTypeToStatus[permType]}
                changeUserPerm={changeUserPerm}
              />
            ));
          })}
      </List>
    </>
  );
}
MembersPage.propTypes = {
  userList: PropTypes.object,
  fetchUserList: PropTypes.func,
  permTypeToStatus: PropTypes.object,
};

export default MembersPage;
