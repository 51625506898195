import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import AccountSearch from './AccountSearch';

const useStyles = makeStyles(theme => ({
  addMembersDialog: {
    height: '500px',
  },
  gridItem: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    height: '200px',
    fontSize: '1.2em',
  },
}));

function InviteUserDialog({ data, changeUserPerm, isDisabled, open, setOpen }) {
  const classes = useStyles();
  const [mailChecked, setMailChecked] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const handleCheckChange = event => {
    setMailChecked(event.target.checked);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  const changeUserPermWithMessage = async (user, perm) => {
    await changeUserPerm(user, perm, mailChecked, message);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogContent>
        <Box className={classes.addMembersDialog}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} className={classes.gridItem}>
              <AccountSearch
                orgUUID={data.org}
                changeUserPerm={changeUserPermWithMessage}
                isDisabled={isDisabled}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} className={classes.gridItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mailChecked}
                        onChange={handleCheckChange}
                        color="primary"
                        name="checkedA"
                      />
                    }
                    label="共有先にメールで通知する"
                  />
                </Grid>

                <Grid item xs={12} style={{ width: '100%' }}>
                  <textarea
                    className={classes.textarea}
                    value={message}
                    disabled={!mailChecked}
                    placeholder="メッセージ"
                    onChange={handleMessageChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

InviteUserDialog.propTypes = {
  data: PropTypes.object.isRequired,
  changeUserPerm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default InviteUserDialog;
