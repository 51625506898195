import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const fontFamily = [
  'Roboto',
  'Helvetica Neue',
  'Noto Sans JP',
  'sans-serif',
].join(',');

export const themeInstance = createTheme({
  props: {
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
  typography: {
    fontFamily: fontFamily, // フォント
    button: {
      textTransform: 'none',
    },
  },
  fontFamilyBasic: fontFamily,
  drawerWidth: 220,
  drawerInfoWidth: 240,
  color: {
    bgColorSurface: '#fbfbfb',
    bgColorBase: '#f2f2f3',
    bgColorDark: '#3A3B3C',
    bgColorGray: '#F3F3F4',
    overlayColorLight: 'rgba(58, 59, 60, 0.05)',
    textColorGray: '#747476',
    textColorDefault: '#3A3B3C',
    mainColor: '#411FA9',
    mainColor2: '#1FE4FF',
    secondaryColor: '#391C93',
    secondaryColor2: '#159fb2',
  },
  font: {
    fontSizeDefault: 14,
    fontSizeMicro: 11,
    fontSizeBaby: 12,
    fontSizeMosquito: 14,
    fontSizeLarge: 17,
  },
  palette: {
    primary: {
      main: '#2c70df',
    },
  },
});

export default function AppThemeProvider({ children }) {
  return <ThemeProvider theme={themeInstance}>{children}</ThemeProvider>;
}
