import React from 'react';
import { SvgIcon } from '@material-ui/core';
import CircleInfoSVG from './circle_info.svg';
import ColumnSettingsSVG from './column-settings.svg';
import CRSVG from './CR.svg';
import CTSVG from './CT.svg';
import DicomSVG from './DICOM.svg';
import FolderSVG from './folder.svg';
import PdfSVG from './PDF.svg';
import ImageSVG from './image.svg';
import LinkSVG from './link.svg';
import MRSVG from './MR.svg';
import OtherSVG from './others.svg';
import UnknownSVG from './unknown.svg';
import VideoSVG from './video.svg';

import './CustomIcons.css';
const defaultViewBox = "0 0 32 32"

function DicomIcon(props) {
  return (
    <SvgIcon component={DicomSVG}
      viewBox={defaultViewBox}
      className="custom-icon"
      style={{ width: '1.2em', height: '1.2em' }}
    />
  );
}

function FolderIcon(props) {
  return (
    <SvgIcon component={FolderSVG} viewBox={defaultViewBox} />
  );
}

function PDFIcon(props) {
  return (
    <SvgIcon component={PdfSVG} viewBox={defaultViewBox} />
  );
}

function ImageIcon(props) {
  return (
    <SvgIcon component={ImageSVG} viewBox={defaultViewBox} />
  );
}

function OtherIcon(props) {
  return (
    <SvgIcon component={OtherSVG} viewBox={defaultViewBox} />
  );
}

function UnknownIcon(props) {
  return (
    <SvgIcon component={UnknownSVG} viewBox={defaultViewBox} />
  );
}

// Add additional icon functions for the remaining SVG icons
function CircleInfoIcon(props) {
  return (
    <SvgIcon component={CircleInfoSVG} viewBox={defaultViewBox} />
  );
}

function ColumnSettingsIcon(props) {
  return (
    <SvgIcon component={ColumnSettingsSVG} viewBox={defaultViewBox} />
  );
}

function CRIcon(props) {
  return (
    <SvgIcon component={CRSVG} viewBox={defaultViewBox} />
  );
}

function CTIcon(props) {
  return (
    <SvgIcon component={CTSVG} viewBox={defaultViewBox} />
  );
}

function LinkIcon(props) {
  return (
    <SvgIcon component={LinkSVG} viewBox={defaultViewBox} />
  );
}

function MRIcon(props) {
  return (
    <SvgIcon component={MRSVG} viewBox={defaultViewBox} />
  );
}

function VideoIcon(props) {
  return (
    <SvgIcon component={VideoSVG} viewBox={defaultViewBox} />
  );
}

export {
  DicomIcon,
  FolderIcon,
  PDFIcon,
  ImageIcon,
  OtherIcon,
  UnknownIcon,
  CircleInfoIcon,
  ColumnSettingsIcon,
  CRIcon,
  CTIcon,
  LinkIcon,
  MRIcon,
  VideoIcon,
};

// export { DicomIcon, FolderIcon, PDFIcon, ImageIcon, OtherIcon, UnknownIcon };
