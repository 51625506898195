import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { UserButton } from '@ohif/ui';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(3),
    // backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  icon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function AccessDenied() {
  const classes = useStyles();
  const history = useHistory();

  const onClickUserButton = event => {
    history.push(`/login?continueUrl=${location.pathname + location.search}`);
    location.reload();
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon className={classes.icon} />
        </Avatar>
        <Typography component="h1" variant="h4" gutterBottom>
          アクセス権が必要です
        </Typography>
        <Typography component="h4" variant="h6" gutterBottom>
          アクセス権をリクエストするか、
          アクセス権のあるアカウントに切り替えてください。
        </Typography>

        <Grid container className={classes.buttons}>
          <Grid item xs={6} container justifyContent="center">
            <Button color="primary" href="/">
              トップに戻る
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="center">
            <Button color="primary" onClick={onClickUserButton}>
              ログイン
            </Button>
          </Grid>
        </Grid>

        <UserButton onClick={onClickUserButton} />
      </div>
    </Container>
  );
}
