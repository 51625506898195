import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
  },
  title: {
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  content: {
    maxHeight: `200px`,
    overflow: 'auto',
    backgroundColor: 'white',
  },
}));

function SnackbarBox({
  openSnackbar,
  openCollapse,
  setOpenCollapse,
  handleCloseSnackbar,
  title,
  children,
}) {
  const classes = useStyles();

  return (
    <>
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackbar}
      >
        <Box>
          <SnackbarContent
            className={classes.title}
            elevation={0}
            message={title}
            action={
              <>
                <IconButton
                  onClick={() => setOpenCollapse(open => !open)}
                  size="small"
                  color="inherit"
                >
                  {openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <IconButton
                  onClick={handleCloseSnackbar}
                  size="small"
                  color="inherit"
                >
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
          <Collapse in={openCollapse}>
            <Paper elevation={0} className={classes.content} square>
              {children}
            </Paper>
          </Collapse>
        </Box>
      </Snackbar>
    </>
  );
}
SnackbarBox.propTypes = {
  openSnackbar: PropTypes.bool.isRequired,
  openCollapse: PropTypes.bool.isRequired,
  setOpenCollapse: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SnackbarBox;
