import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { authRequest } from '../../../../api/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { useCheckOrgPerm } from '../../../organization/api/getOrgUserPerm';

import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { FormDialog } from '../../../../components/FormDialog';
import { useAsyncError } from '../../../../api/error';
import { patchOrgName } from '../../../../api/rest';
import { removeOrgLastUrl } from '../../../../utils/orgLastUrl';

const useStyles = makeStyles(theme => ({
  section: {
    margin: theme.spacing(2, 0),
  },
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
  },
}));

// orgの名前変更のセクション

function RenameOrgSection({ isDisabled }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const [open, setOpen] = React.useState(false);
  const { data: orgDetails, refetch: refetchOrgDetail } = useOrgDetail(); // TODO:楽観的更新に変更する

  const handleClick = useCallback(
    name => async () => {
      try {
        const response = await patchOrgName(orgDetails.uuid, name);
        await refetchOrgDetail();
        setOpen(false);
      } catch (e) {
        throwError(e);
      }
    },
    [orgDetails.uuid, refetchOrgDetail, throwError]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Box className={classes.section}>
        <Typography>組織名を変更する</Typography>
        <Typography color="textSecondary">
          管理者権限を持つユーザーのみが操作可能です
        </Typography>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isDisabled}
        >
          変更する
        </Button>
      </Box>
      <FormDialog
        open={open}
        setOpen={setOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        title="組織名を変更"
        initialState={orgDetails.name}
      />
    </>
  );
}
RenameOrgSection.propTypes = {
  isDisabled: PropTypes.bool,
};

// org削除のセクション
function DeleteOrgSection({ isDisabled }) {
  const classes = useStyles();
  const history = useHistory();
  const { data: orgDetails } = useOrgDetail();

  const [isDeleted, setIsDeleted] = useState(false);
  const [isError, setIsError] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setIsError(false);
    setOpenDialog(false);
  };

  const deleteOrg = useCallback(async () => {
    const url = `/api/access/org/detail/${orgDetails.uuid}`;
    await authRequest
      .delete(url)
      .then(res => console.log(res))
      .then(() => {
        setIsDeleted(true);
        setIsError(false);
        removeOrgLastUrl();
        location.href = '/organization/join'; // 強制リダイレクト
      })
      .catch(err => {
        setIsError(true);
        setIsDeleted(false);
        console.log(err);
      });
  }, [orgDetails.uuid]);

  return (
    <>
      <Box className={classes.section}>
        <Typography>組織を削除する</Typography>
        <Typography color="textSecondary">
          管理者権限を持つユーザーのみが操作可能です
        </Typography>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={handleOpenDialog}
          disabled={isDisabled}
        >
          削除する
        </Button>
      </Box>
      <Dialog
        open={openDialog}
        fullWidth
        onClose={handleCloseDialog}
        maxWidth={'xs'}
      >
        <DialogTitle>組織を削除する</DialogTitle>
        {isDeleted ? (
          <DialogContent>
            <DialogContentText>削除に成功しました。</DialogContentText>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={() => history.push('/organization/join')}
              fullWidth
            >
              組織選択画面に戻る
            </Button>
          </DialogContent>
        ) : isError ? (
          <DialogContent>
            <DialogContentText>削除に失敗しました</DialogContentText>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleCloseDialog}
              fullWidth
            >
              戻る
            </Button>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              本当に削除しますか？この操作は取り消せません。
            </DialogContentText>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={deleteOrg}
              fullWidth
            >
              削除する
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleCloseDialog}
              fullWidth
            >
              キャンセル
            </Button>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
DeleteOrgSection.propTypes = {
  isDisabled: PropTypes.bool,
};

function OrgSettingPage({ permTypeToStatus }) {
  const {
    data: { adminPerm },
  } = useCheckOrgPerm();
  const { data: orgDetails } = useOrgDetail();

  return (
    <>
      <Typography>組織の設定</Typography>
      <Typography color="textSecondary">
        「{orgDetails.name}」の設定を行います
      </Typography>
      <RenameOrgSection isDisabled={!adminPerm} />
      <DeleteOrgSection isDisabled={!adminPerm} />
    </>
  );
}
OrgSettingPage.propTypes = {
  permTypeToStatus: PropTypes.object,
};

export default OrgSettingPage;
