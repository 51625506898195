export const isFile = data => {
  if (data && data.object_type === 'file') {
    return true;
  }
  return false;
};

export const isFolder = data => {
  if (data && data.object_type === 'folder') {
    return true;
  }
  return false;
};

export const isDicom = data => {
  return (
    data &&
    data.object_type === 'folder' &&
    (data.foldertype === 'study' ||
      data.foldertype === 'series' ||
      data.foldertype === 'imgSeries')
  );
};

export const isSeries = data => {
  return data.object_type === 'folder' && data.foldertype === 'series';
};

export const isRootFolder = type => {
  return type == 'org' || type == 'share' || type == 'private';
};

export const isValidUrl = url => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export function filetypeGroup(filetype) {
  /*
  大まかな分類をする
  */
  if (filetype == 'application/dicom') {
    return `dicom`;
  } else if (filetype == 'application/pdf') {
    return `pdf`;
  } else if (
    filetype == 'image/jpeg' ||
    filetype == 'image/jpg' ||
    filetype == 'image/png' ||
    filetype.includes('image/')
  ) {
    return `image`;
  } else if (
    filetype == 'video/mp4' ||
    filetype == 'video/webm' ||
    filetype == 'video/ogg' ||
    filetype.includes('video/')
  ) {
    return `video`;
  } else if (filetype == 'audio/mpeg' || filetype.includes('audio/')) {
    return 'audio';
  } else if (filetype.includes('text/')) {
    return `text`;
  } else if (filetype.includes('s9/url')) {
    return `url`;
  } else {
    return `other`;
  }
}
