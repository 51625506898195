import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ModalContext } from '../context/ModalContext';
import { SnackbarContext } from '../context/SnackbarContext';
import { useAsyncError } from '../api/error';
import {
  patchFolder,
  patchFile,
  createFile,
  createFolder,
  createRootFolder,
} from '../api/rest';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { isValidUrl } from '../utils/checkData';
import { useFolderPathReset } from '../features/storage/api/getFolderPath';
import { useFolderInfoReset } from '../features/storage/api/getFolderInfo';
import {
  useOrgRootList,
  useOrgRootListInvalidate,
} from '../features/organization/api/getOrgRootList';

import { useOrgDetail } from '../features/organization/api/getOrgDetail';
import { useIsCheck } from '../features/storage/utils/useParams';

const useStyles = makeStyles(theme => ({
  formRoot: { zIndex: '2000 !important' },
}));

function FormDialog({
  open,
  setOpen,
  handleClick,
  handleClose,
  title,
  initialState,
}) {
  const classes = useStyles();
  const [name, setName] = React.useState(initialState);

  const handleChange = useCallback(
    event => {
      setName(event.target.value);
    },
    [setName]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      className={classes.formRoot}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={handleChange}
          autoFocus
          fullWidth
          onFocus={event => event.target.select()}
        />
        {/* <Typography
          visibility={false}
        >err message</Typography> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClick(name)} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialState: PropTypes.string,
};

function ConfirmDeleteDialog({ open, onClose, onDelete, data }) {

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">削除の確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          「{data.name}」を本当に削除しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={onDelete} color="primary">
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

const useDeleteDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [resolvePromise, setResolvePromise] = React.useState(null);

  const openDeleteDialog = (data) => {
    console.log('openDeleteDialog', data);
    setData(data);
    setOpen(true);
    return new Promise((resolve, reject) => {
      setResolvePromise(() => resolve);
    });
  }

  const handleClose = (deleted) => {
    setOpen(false);
    if (resolvePromise) {
      if (deleted) {
        console.log('DELETE!');
        resolvePromise(true);
      } else {
        console.log('CANCELLED!');
        resolvePromise(false);
      }
      setResolvePromise(null);
    }
  }

  function deleteDialog() {
    return (
      <ConfirmDeleteDialog
        open={open}
        onClose={() => handleClose(false)}
        onDelete={() => handleClose(true)}
        data={data}
      />
    );
  }

  return [openDeleteDialog, deleteDialog];
};

function RenameDialog({ open, setOpen, data }) {
  const throwError = useAsyncError();

  const {
    setSnackbarMessage,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const refetchFolderPath = useFolderPathReset();
  const refetchFolderInfo = useFolderInfoReset();
  const refetchOrgRootList = useOrgRootListInvalidate();

  const handleClick = useCallback(
    name => async () => {
      try {
        setOpen(false);
        setOpenSnackbarCollapse(false);
        setOpenSnackbar(true);
        setSnackbarMessage('フォルダ名を変更しています...');
        if (data.object_type == 'folder') {
          await patchFolder(data.uuid, { name: name });
        } else if (data.object_type == 'file') {
          await patchFile(data.uuid, { name: name });
        } else {
          throw new Error('Unknown type');
        }
        await refetchFolderPath();
        await refetchFolderInfo();
        if (data.parent_folder === null) {
          await refetchOrgRootList(); // 親がなければrootを更新
        } else {
          await refetchFolderInfo({
            folderUUID: data.parent_folder,
            apiOptions: { noFile: true, noUrl: true, combine: false },
          }); // drawerRootFolderのフォルダ名がおかしくならないように親があれば更新
        }
        handleSnackbarCompleted('名前を変更しました');

      } catch (e) {
        throwError(e);
      }
    },
    [
      data.object_type,
      data.parent_folder,
      data.uuid,
      refetchFolderInfo,
      refetchFolderPath,
      refetchOrgRootList,
      setOpen,
      setOpenSnackbar,
      setOpenSnackbarCollapse,
      setSnackbarMessage,
      throwError,
    ]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      pressEnter={handleClick}
      handleClick={handleClick}
      handleClose={handleClose}
      title="名前を変更"
      initialState={data.name}
    />
  );
}

RenameDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};

const useRenameDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openRenameDialog = data => {
    setData(data);
    setOpen(true);
  };

  function renameDialog() {
    return <RenameDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openRenameDialog, renameDialog];
};

function CreateURLFileDialog({ open, setOpen, data }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const { setSnackbarMessage, setOpenSnackbar } = useContext(SnackbarContext);

  const { data: orgDetails } = useOrgDetail();

  const { isRootPage } = useIsCheck();
  const rootPermName = useSelector(state => state.storageManager.rootPermName);

  const { refetch: refetchOrgRootList } = useOrgRootList();
  const refetchFolderInfo = useFolderInfoReset();

  const [name, setName] = React.useState('新しいファイル');
  const [url, setUrl] = React.useState('');

  const newData = {
    name: name,
    filetype: 's9/url',
    url: url,
    parent_folder: data.uuid,
    org: orgDetails.uuid,
    has_org_perm: rootPermName === 'org',
  };

  const handleClick = useCallback(async () => {
    try {
      setOpen(false);
      setOpenSnackbar(true);
      setSnackbarMessage('ファイルを作成しています...');
      await createFile(newData);
      if (isRootPage()) {
        await refetchOrgRootList();
      } else {
        await refetchFolderInfo();
      }
      setSnackbarMessage('ファイルを作成しました');
    } catch (e) {
      setSnackbarMessage('ファイル作成が中断されました');
      throwError(e);
    }
  }, [
    isRootPage,
    newData,
    refetchFolderInfo,
    refetchOrgRootList,
    setOpen,
    setOpenSnackbar,
    setSnackbarMessage,
    throwError,
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChangeName = useCallback(
    event => {
      setName(event.target.value);
    },
    [setName]
  );

  const handleChangeUrl = useCallback(
    event => {
      setUrl(event.target.value);
    },
    [setUrl]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      className={classes.formRoot}
    >
      <DialogTitle>URLファイルを作成する</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={handleChangeName}
          autoFocus
          fullWidth
          onFocus={event => event.target.select()}
          label="Name"
        />
        <TextField
          error={!isValidUrl(url)}
          value={url}
          onChange={handleChangeUrl}
          fullWidth
          onFocus={event => event.target.select()}
          label="URL"
          helperText={!isValidUrl(url) ? '正しいURLを入力してください' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={!isValidUrl(url)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateURLFileDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};
const useCreateURLFileDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openCreateURLFileDialog = data => {
    setData(data);
    setOpen(true);
  };

  function createURLFileDialog() {
    return <CreateURLFileDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openCreateURLFileDialog, createURLFileDialog];
};

function RenameURLFileDialog({ open, setOpen, data }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const { setSnackbarMessage, setOpenSnackbar } = useContext(SnackbarContext);
  const { isRootPage } = useIsCheck();

  const { data: orgDetails } = useOrgDetail();
  const refetchFolderInfo = useFolderInfoReset();
  const { refetch: refetchOrgRootList } = useOrgRootList();

  const [name, setName] = React.useState(data.name);
  const [url, setUrl] = React.useState(data.url);

  const newData = {
    name: name,
    url: url,
    org: orgDetails.uuid,
  };

  const handleClick = useCallback(async () => {
    try {
      await setOpen(false);
      setOpenSnackbar(true);
      setSnackbarMessage('ファイルを更新しています...');
      await patchFile(data.uuid, newData);
      if (isRootPage()) {
        await refetchOrgRootList();
      } else {
        await refetchFolderInfo();
      }
      setSnackbarMessage('ファイルを更新しました');
    } catch (e) {
      setSnackbarMessage('ファイル更新が中断されました');
      throwError(e);
    }
  }, [
    data.uuid,
    isRootPage,
    newData,
    refetchFolderInfo,
    refetchOrgRootList,
    setOpen,
    setOpenSnackbar,
    setSnackbarMessage,
    throwError,
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChangeName = useCallback(
    event => {
      setName(event.target.value);
    },
    [setName]
  );

  const handleChangeUrl = useCallback(
    event => {
      setUrl(event.target.value);
    },
    [setUrl]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      className={classes.formRoot}
    >
      <DialogTitle>名前、URLの変更</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={handleChangeName}
          autoFocus
          fullWidth
          onFocus={event => event.target.select()}
          label="Name"
        />
        <TextField
          error={!isValidUrl(url)}
          value={url}
          onChange={handleChangeUrl}
          fullWidth
          onFocus={event => event.target.select()}
          label="URL"
          helperText={!isValidUrl(url) ? '正しいURLを入力してください' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={!isValidUrl(url)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RenameURLFileDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};
const useRenameURLFileDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openRenameURLFileDialog = data => {
    setData(data);
    setOpen(true);
  };

  function renameURLFileDialog() {
    return <RenameURLFileDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openRenameURLFileDialog, renameURLFileDialog];
};

function CreateFolderDialog({ open, setOpen, data }) {
  const throwError = useAsyncError();
  const { setSnackbarMessage, setOpenSnackbar } = useContext(SnackbarContext);
  const { refetch: refetchOrgRootList } = useOrgRootList();
  const refetchFolderInfo = useFolderInfoReset();

  const handleClick = useCallback(
    name => async () => {
      try {
        await setOpen(false);
        setOpenSnackbar(true);
        setSnackbarMessage('フォルダを作成しています...');
        if (data.object_type == 'folder') {
          await createFolder(data.uuid, name);
          await refetchFolderInfo({
            folderUUID: data.uuid,
            apiOptions: { noFile: true, noUrl: true, combine: false },
          }); // drawerRootFolderのフォルダ名がおかしくならないように親があれば更新
        } else if (data.object_type == 'file') {
          throw new Error('Cannot create folder from file');
        } else {
          throw new Error('Unknown type');
        }
        await refetchFolderInfo();
        await refetchOrgRootList();
        setSnackbarMessage('フォルダを作成しました');
      } catch (e) {
        setSnackbarMessage('フォルダ作成が中断されました');
        throwError(e);
      }
    },
    [
      data.object_type,
      data.uuid,
      refetchFolderInfo,
      refetchOrgRootList,
      setOpen,
      setOpenSnackbar,
      setSnackbarMessage,
      throwError,
    ]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      handleClick={handleClick}
      handleClose={handleClose}
      title="フォルダを作成"
      initialState={'新しいフォルダ'}
    />
  );
}

CreateFolderDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};
const useCreateFolderDialog = () => {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const openCreateFolderDialog = data => {
    setData(data);
    setOpen(true);
  };

  function createFolderDialog() {
    return <CreateFolderDialog open={open} setOpen={setOpen} data={data} />;
  }

  return [openCreateFolderDialog, createFolderDialog];
};

function CreateRootFolderDialog({ open, setOpen, hasOrgPerm }) {
  const throwError = useAsyncError();

  const { refetch: refetchOrgRootList } = useOrgRootList();
  const { setSnackbarMessage, setOpenSnackbar } = useContext(SnackbarContext);
  const { data: orgDetails } = useOrgDetail();
  const handleClick = name => async () => {
    try {
      setOpen(false);
      setOpenSnackbar(true);
      setSnackbarMessage('フォルダを作成しています...');
      await createRootFolder(orgDetails.uuid, name, hasOrgPerm);
      await refetchOrgRootList();
      setSnackbarMessage('フォルダを作成しました');
    } catch (e) {
      setSnackbarMessage('フォルダ作成が中断されました');
      throwError(e);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      handleClick={handleClick}
      handleClose={handleClose}
      title="フォルダを作成"
      initialState={'新しいフォルダ'}
    />
  );
}

CreateRootFolderDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  hasOrgPerm: PropTypes.bool,
};

const useCreateRootFolderDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [hasOrgPerm, setHasOrgPerm] = React.useState(false);

  const openCreateRootFolderDialog = (hasOrgPerm = false) => {
    setOpen(true);
    setHasOrgPerm(hasOrgPerm);
  };

  function createRootFolderDialog() {
    return (
      <CreateRootFolderDialog
        open={open}
        setOpen={setOpen}
        hasOrgPerm={hasOrgPerm}
      />
    );
  }

  return [openCreateRootFolderDialog, createRootFolderDialog];
};

export {
  FormDialog,
  RenameDialog,
  CreateURLFileDialog,
  CreateFolderDialog,
  CreateRootFolderDialog,
  useDeleteDialog,
  useRenameDialog,
  useRenameURLFileDialog,
  useCreateURLFileDialog,
  useCreateFolderDialog,
  useCreateRootFolderDialog,
};
