/**
 * Template for icons in the OHIF toolbar.
 */
import './Icon.styl';

import PropTypes from 'prop-types';
import getIcon from './getIcon.js';

const Icon = props => {
  return getIcon(props.name, props);
};

Icon.propTypes = {
  /**
   * The string name of the icon to display, from the collection of
   * SVG icons in OHIF at Viewers/platform/ui/src/elements/Icon/icons
   */
  name: PropTypes.string.isRequired,
};

export default Icon;
