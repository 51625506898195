const DICOM_FILETYPE = 'application/dicom';
const DICOMDIR_FILETYPE = 'application/dicomdir';

const DICOM_METADATA_KEYS = [
  'SpecificCharacterSet',
  'ImageType',
  'InstanceCreationDate',
  'InstanceCreationTime',
  'InstanceCreatorUID',
  'SOPClassUID',
  'SOPInstanceUID',
  'StudyDate',
  'SeriesDate',
  'ContentDate',
  'StudyTime',
  'SeriesTime',
  'ContentTime',
  'AccessionNumber',
  'Modality',
  'BodyPartExamined',
  'Manufacturer',
  'InstitutionName',
  'InstitutionAddress',
  'ReferringPhysicianName',
  'StationName',
  'StudyDescription',
  'SeriesDescription',
  'InstitutionalDepartmentName',
  'AdmittingDiagnosesDescription',
  'ManufacturerModelName',
  'PatientName',
  'PatientID',
  'PatientBirthDate',
  'PatientBirthTime',
  'PatientSex',
  'PatientWeight',
  'MedicalAlerts',
  'Allergies',
  'EthnicGroup',
  'Occupation',
  'AdditionalPatientHistory',
  'PregnancyStatus',
  'PatientComments',
  'ScanningSequence',
  'SequenceVariant',
  'ScanOptions',
  'MRAcquisitionType',
  'SequenceName',
  'SliceThickness',
  'RepetitionTime',
  'EchoTime',
  'InversionTime',
  'NumberOfAverages',
  'ImagingFrequency',
  'ImagedNucleus',
  'EchoNumbers',
  'MagneticFieldStrength',
  'SpacingBetweenSlices',
  'NumberOfPhaseEncodingSteps',
  'EchoTrainLength',
  'PercentSampling',
  'PercentPhaseFieldOfView',
  'DeviceSerialNumber',
  'SoftwareVersions',
  'ProtocolName',
  'LowRRValue',
  'HighRRValue',
  'IntervalsAcquired',
  'IntervalsRejected',
  'HeartRate',
  'ReconstructionDiameter',
  'ReceiveCoilName',
  'TransmitCoilName',
  'AcquisitionMatrix',
  'InPlanePhaseEncodingDirection',
  'FlipAngle',
  'PatientPosition',
  'StudyInstanceUID',
  'SeriesInstanceUID',
  'StudyID',
  'SeriesNumber',
  'AcquisitionNumber',
  'InstanceNumber',
  'ImagePositionPatient',
  'ImageOrientationPatient',
  'FrameOfReferenceUID',
  'Laterality',
  'TemporalPositionIdentifier',
  'NumberOfTemporalPositions',
  'PositionReferenceIndicator',
  'SliceLocation',
  'ImageComments',
  'SamplesPerPixel',
  'PhotometricInterpretation',
  'Rows',
  'Columns',
  'PixelSpacing',
  'PixelAspectRatio',
  'BitsAllocated',
  'BitsStored',
  'HighBit',
  'PixelRepresentation',
  'SmallestImagePixelValue',
  'LargestImagePixelValue',
  'WindowCenter',
  'WindowWidth',
  'RescaleIntercept',
  'RescaleSlope',
  'RescaleType',
  'LossyImageCompression',
  'RequestingPhysician',
  'RequestingService',
  'RequestedProcedureDescription',
  'RequestedContrastAgent',
  'RETIRED_StudyComments',
  'SpecialNeeds',
  'PatientState',
  'PerformedStationAETitle',
  'PerformedStationName',
  'PerformedLocation',
  'PerformedProcedureStepStartDate',
  'PerformedProcedureStepStartTime',
  'PerformedProcedureStepEndDate',
  'PerformedProcedureStepEndTime',
  'PerformedProcedureStepStatus',
  'PerformedProcedureStepID',
  'PerformedProcedureStepDescription',
  'PerformedProcedureTypeDescription',
  'CommentsOnThePerformedProcedureStep',
  'FilmConsumptionSequence',
  'RequestedProcedureID',
  'ReasonForTheRequestedProcedure',
  'RequestedProcedurePriority',
  'PatientTransportArrangements',
  'RequestedProcedureLocation',
  'NamesOfIntendedRecipientsOfResults',
  'RequestedProcedureComments',
  'RETIRED_ReasonForTheImagingServiceRequest',
  'IssueDateOfImagingServiceRequest',
  'IssueTimeOfImagingServiceRequest',
  'RETIRED_PlacerOrderNumberImagingServiceRequestRetired',
  'RETIRED_FillerOrderNumberImagingServiceRequestRetired',
  'OrderEnteredBy',
  'OrderEntererLocation',
  'OrderCallbackPhoneNumber',
  'ImagingServiceRequestComments',
  'imageId',
  // 'NumberOfFrames', multiframe 対応のために必要。現状は保存したアノテーションが正しく表示されない
];

export { DICOM_FILETYPE, DICOMDIR_FILETYPE, DICOM_METADATA_KEYS };
