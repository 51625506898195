import axios from 'axios';
import axiosRetry from 'axios-retry';

export const initAxiosRetry = () => {
  axiosRetry(axios, {
    retries: 2, // retry 2 times
    retryCondition: () => true, // always retry
    shouldResetTimeout: true,
    retryDelay: axiosRetry.exponentialDelay,
  });
};
