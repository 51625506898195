import sha256 from 'crypto-js/sha256';
import { isConferencePage } from '../storage/components/Conference/StorageConference';

export const editAttributeNames = [
  'StudyDescription',
  'InstitutionName',
  'PatientID',
  'PatientName',
  'PatientBirthDate',
  'PatientAge',
  'PatientSex',
  'PatientSize',
  'PatientWeight',
  'StudyID',
  'AccessionNumber',
  'StudyDate',
  'StudyTime',
  'ReferringPhysicianName',
  'PerformingPhysicianName',
];

export const replaceZeroLength = [
  'AccessionNumber', //'00080050',
  'InstitutionName', //'00080080',
  'ReferringPhysicianName', //'00080090',
  'PerformingPhysicianName', // '00081050',
  'NameOfPhysiciansReadingStudy', // '00081060',
  'OperatorsName', //'00081070',
  'DerivationDescription', //'00082111',
  'PatientBirthDate', //'00100030',
  'PatientSex', //'00100040',
  'OtherPatientIDs', //'00101000',
  'PatientAge', //'00101010',
  'PatientSize', //'00101020',
  'PatientWeight', //'00101030',
  'AdditionalPatientHistory', //'001021B0',
  'DeviceSerialNumber', // '00181000',
  'ProtocolName', //'00181030',
  'ImageComments', //'00204000',

  // Below metadata keys added for more complete Anonymization
  'AcquisitionDate',
  'AcquisitionTime',
  'ContentDate',
  'ContentTime',
  'SeriesDate',
  'SeriesTime',
];

export const replaceANON = [
  'PatientName', //'00100010',
  'PatientBirthName', //'00101005',
  'PatientMotherBirthName', //'00101060',
  'PatientID', //'00120062',
];

export const replaceANONYMIZED = [
  'StationName', //'00081010',
  'StudyDescription', //'00081030',
  'SeriesDescription', //'0008103E',
];

export const replaceHash = [
  'SOPInstanceUID', //'00080018',
  'StudyInstanceUID', //'0020000D',
  'SeriesInstanceUID', //'0020000E',
  'FrameOfReferenceUID', //'00200052',
];

export const elementDrop = [
  'InstitutionAddress', //'00080081',
  'ReferringPhysicianAddress', //'00080092',
  'ReferringPhysicianTelephoneNumbers', //'00080094',
  'InstitutionalDepartmentName', //'00081040',
  'PhysiciansOfRecord', //'00081048',
  'AdmittingDiagnosesDescription', //'00081080',
  'ReferencedStudySequence', //'00081110',
  'ReferencedSOPClassUID', //'00081150',
  'ReferencedSOPInstanceUID', //'00081155',
  'PatientBirthTime', //'00100032',
  'OtherPatientNames', //'00101001',
  'PatientAddress', //'00101040',
  'PatientTelephoneNumbers', //'00102154',
  'MedicalRecordLocator', //'00101090',
  'EthnicGroup', //'00102160',
  'Occupation', //'00102180',
  'PatientComments', //'00104000',
  'SynchronizationFrameOfReferenceUID', //'00200200',
  'UID', //'0040A124',
  'ContentSequence', //'0040A730',
  'StorageMediaFileSetUID', //'00880140',
  'ReferencedFrameOfReferenceUID', //'30060024',
  'RETIRED_RelatedFrameOfReferenceUID', //'300600C2',
];

// Default anonymization for Conference uploads
export const conferenceAnon = {
  PatientName: '',
  StudyDescription: '',
  PatientID: '',
  PatientBirthName: '', //'00101005',
  PatientMotherBirthName: '', //'00101060',
  StudyDate: '20010101',
  StudyTime: '120000.0',
};

export function digestMessage(message) {
  const hashHex = sha256(message).toString();
  return hashHex;
}

export function getAnonymizeDicomData(study) {
  const isConference = isConferencePage();
  // タグ名はdcmjsのdictionary.jsを元に作成
  // dcmjs/src/dictionary.js
  // https://github.com/dcmjs-org/dcmjs/blob/c209047861d2ef6c17526c6d1e29d5db7e895b29/src/dictionary.js
  let dataset = {};

  // 編集するタグ（editAttributeNames）を匿名化
  editAttributeNames.map(key => {
    if (replaceZeroLength.includes(key)) {
      dataset[key] = '';
    } else if (replaceANONYMIZED.includes(key)) {
      dataset[key] = 'ANONYMIZED';
    } else if (replaceANON.includes(key)) {
      dataset[key] = `ANON${study.StudyID}`;
    } else if (replaceHash.includes(key)) {
      dataset[key] = digestMessage(study[key]);
    }

    // Special case for Conference
    if (isConference) {
      if (key in conferenceAnon) {
        dataset[key] = conferenceAnon[key];
      }
    }
  });
  dataset['StudyInstanceUID'] = study['StudyInstanceUID'];
  return dataset;
}
