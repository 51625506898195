import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
  },
}));

export default function TopTitle() {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.title} noWrap>
      <Link component={RouterLink} to={'/'} color="primary">
        S9 Mercury α版
      </Link>
    </Typography>
  );
}
