import React, { useContext, useEffect, useRef, useState } from 'react';
import propsTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ModalContext } from '../../../context/ModalContext';
import { SnackbarContext } from '../../../context/SnackbarContext';
import { addMimeTypeToFiles, UploadCancelError } from '../../../utils/upload';

import AnonymizeSimple from '../../annonymization/AnonymizeSimple';

import { splitDicomFiles } from '../../../utils/upload/extractStudyMetadataFromPaths';

import { checkFullAccess } from '../../../utils/checkAccessPerm';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';
import {
  useUpdateWhenDelete,
  useUpdateWhenUpload,
} from '../api/fetchUpdateInfo';
import { useFolderInfoReset } from '../api/getFolderInfo';
import { useOrgRootListInvalidate } from '../../organization/api/getOrgRootList';

import {
  uploadPyDicomFile,
  setStudiesCompleted,
  abortUpload,
} from '../../../api/rest';
import { isConferencePage } from './Conference/StorageConference';
import UploadConfirmationModal from './Conference/UploadConfirmationModal';
import { generateUniviewerUrl } from '../../univiewer/UniversalViewerContainer';

const UploadContext = React.createContext({});

const UploadProvider = props => {
  const { openConfirmAbortUploadModal } = useContext(ModalContext);

  const {
    setOpenSnackbar,
    setSnackbarMessage,
    setProgressValue,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const updateWhenUpload = useUpdateWhenUpload();
  const updateWhenDelete = useUpdateWhenDelete();
  const {
    data: { memberPerm },
  } = useCheckOrgPerm();

  const [progress, setProgress] = React.useState(0);
  const resetProgress = () => {
    setProgress(0);
    setProgressValue(0);
  };

  const completeProgress = () => setProgressValue(100);

  const refetchFolderInfo = useFolderInfoReset();
  const refetchOrgRootList = useOrgRootListInvalidate();

  const isConference = isConferencePage();
  let uploadSummaryDefault = {
    success: 0,
    errors: 0,
    messages: [],
    studies: {},
  };
  const [uploadedStudies, setUploadedStudies] = React.useState(
    uploadSummaryDefault
  );
  const [uploadOptions, setUploadOptions] = React.useState({});
  const [openSummary, setOpenSummary] = React.useState(false);
  const [openAnon, setOpenAnon] = React.useState(false);

  // useParams doesn't work inside a Context provider
  // https://stackoverflow.com/questions/70568571/why-doesnt-react-router-dom-useparams-hook-work-within-context
  // const { initialOrgId, parentUUID, folderUUID } = useParams();

  // Use regex instead to get the current org
  // Define a regular expression to extract org from the URL
  const orgRegex = /\/org\/([^\/]+)/;
  const match = window.location.href.match(orgRegex);
  const currentOrg = match ? match[1] : null;

  useEffect(() => {
    setProgressValue(progress);
  }, [progress, setProgressValue]);

  const handleOpenAnon = () => {
    setOpenAnon(true);
  };

  const handleAnonClose = () => {
    // Cancel button was clicked in Anonymize dialog
    // Set the dialog closed
    setOpenAnon(false);
    // Hide the upload status indicator
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  const handleAnonOptions = anonOptions => {
    setOpenAnon(false);
    // Run with these options
    console.log('anonOptions', anonOptions, 'startUploads');
    // Wait for user input (anonOptions) returned from AnonymizeSimple, then:
    startUploads(anonOptions);
  };

  const handleOpenSummary = () => {
    setOpenSummary(true);
  };

  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const BATCH_SIZE = 10;
  const [uploadCount, setUploadCount] = useState(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [currentController, setCurrentController] = useState(null);
  const cancelledRef = useRef(false); // Use a ref to track cancellation

  // useEffect for cancelled upload controller
  useEffect(() => {
    console.info('currentController updated:', currentController);
    if (isCancelled && currentController !== null) {
      console.info('isCancelled:', isCancelled);
      currentController.abort();
    }
  }, [currentController, isCancelled]);

  async function openConfirmAbortUploadModalAsync() {
    return new Promise((resolve, reject) => {
      openConfirmAbortUploadModal({ resolve, reject });
    });
  }

  const onCancelUpload = async () => {
    console.info('onCancelUpload : Cancel upload button clicked');

    try {
      const abortModal = await openConfirmAbortUploadModalAsync();
      console.info('onCancelUpload : abortModal:', abortModal);
      if (abortModal.status === 'cancel') {
        console.info('Upload continues.');
        setIsCancelled(false);
        // Continue with the upload process
      } else {
        console.info('Upload aborted by user.');
        setIsCancelled(true);
        setOpenSnackbar({ open: false });
        cancelledRef.current = true;
      }
    } catch (err) {
      console.info('onCancelUpload : Aborting upload');
      setIsCancelled(true);
      setOpenSnackbar({ open: false });
      cancelledRef.current = true;
    } finally {
    }
  };

  async function uploadFileWithProgress(dcmBatch, signal, options, totalFiles) {
    // If the upload is aborted, throw an error to stop the upload process
    if (signal.aborted) {
      throw new UploadCancelError('Upload cancelled during batch');
    }

    try {
      const resp = await uploadPyDicomFile({
        dcmBatch: dcmBatch,
        options: options,
        signal: signal,
      });

      if (resp.status !== 201) {
        throw new Error('Upload error');
      }

      setUploadCount(prev => {
        const newCount = prev + dcmBatch.length;
        const progress = Math.round((newCount / totalFiles) * 100);
        setProgress(progress);
        setSnackbarMessage(`アップロード中 (${newCount}/${totalFiles})`);
        return newCount;
      });

      return resp;
    } catch (error) {
      if (error.name === 'AbortError') {
        throw new UploadCancelError('Batch upload aborted');
      }
      throw error;
    }
  }

  async function uploadConcurrent(dcmFiles, options) {
    const totalFiles = dcmFiles.length;
    let uploadSummary = {
      success: 0,
      errors: 0,
      messages: [],
      studies: {},
    };

    const controller = new AbortController();
    const signal = controller.signal;
    setCurrentController(controller);
    // Reset cancelled ref state
    cancelledRef.current = false;

    try {
      setUploadCount(0);
      resetProgress();

      for (
        let batchStart = 0;
        batchStart < totalFiles && !isCancelled;
        batchStart += BATCH_SIZE
      ) {
        // console.info('signal.aborted:', signal.aborted);

        if (signal.aborted) {
          throw new UploadCancelError(
            'Upload cancelled during uploadConcurrent'
          );
        }

        const dcmBatch = dcmFiles.slice(batchStart, batchStart + BATCH_SIZE);
        const batchResp = await uploadFileWithProgress(
          dcmBatch,
          signal,
          options,
          totalFiles
        );

        const respData = batchResp.data;
        if (respData && respData.results) {
          for (let result of respData.results) {
            if (result.success) {
              uploadSummary.success += 1;
              const study = result.study;
              const series = result.series;

              if (!uploadSummary.studies[study.StudyInstanceUID]) {
                uploadSummary.studies[study.StudyInstanceUID] = {
                  modalities: new Set(),
                  numSeries: new Set(),
                  numInstances: 0,
                  name: study.name,
                  url: generateUniviewerUrl(
                    {
                      name: study.name,
                      uuid: study.uuid,
                      org: currentOrg,
                    },
                    true
                  ),
                };
              }

              const studySummary =
                uploadSummary.studies[study.StudyInstanceUID];
              if (series.modality) {
                studySummary.modalities.add(series.modality);
              }
              if (series.SeriesInstanceUID) {
                studySummary.numSeries.add(series.SeriesInstanceUID);
              }
              studySummary.numInstances++;
            } else {
              uploadSummary.errors += 1;
              // Append error message, if available
              uploadSummary.messages.push(result);
            }
            // Print summary after adding this result
            console.log('uploadSummary', uploadSummary);
          }
        }
      }

      if (!isCancelled) {
        console.log('Dataset upload finished!');
        handleSnackbarCompleted('アップロードが完了しました');

        // Mark studies as completed only if not cancelled
        const resp = await setStudiesCompleted(
          Object.keys(uploadSummary.studies),
          options.target_folder
        );

        if (resp.status === 201) {
          const results = resp.data.results;
          console.log('setStudiesCompleted Data:', results);
        } else {
          console.log('setStudiesCompleted Resp:', resp);
        }

        // Refresh folder contents
        console.log('Refresh folder contents, ENABLED');
        let targetFolderUUID = options.target_folder;
        if (!targetFolderUUID) {
          refetchOrgRootList();
        } else {
          refetchFolderInfo({ folderUUID: targetFolderUUID });
        }
        handleOpenSummary();
        setUploadedStudies(uploadSummary);
      }
    } catch (error) {
      if (error instanceof UploadCancelError) {
        console.info('Upload cancelled:', error.message);

        const resp = await abortUpload(
          Object.keys(uploadSummary.studies),
          options.target_folder
        );

        // reload files list
        if (options.target_folder) {
          refetchFolderInfo({ folderUUID: options.target_folder });
          console.info(
            Object.keys(uploadSummary.studies),
            options.target_folder
          );
        } else {
          refetchOrgRootList();
        }
        const message =
          'アップロードをキャンセルしました : ' +
          uploadSummary.success +
          '件のデータがアップロードされました';
        toast.info(message);
      } else {
        handleSnackbarCompleted('アップロードが失敗しました');
        throw error;
      }
    } finally {
      setCurrentController(null);
      setOpenSnackbar({ open: false });
      cancelledRef.current = false; // Reset cancelled ref
    }
  }

  async function startUploads(anonOptions) {
    try {
      setIsCancelled(false);
      const {
        dcmFiles,
        targetFolderUUID,
        targetOrgUUID,
        isOrgPerm,
      } = uploadOptions;

      setSnackbarMessage('アップロード中');
      setOpenSnackbar({ open: true, onClose: onCancelUpload });

      const options = {
        anon_options: anonOptions,
        target_folder: targetFolderUUID,
        target_org: targetOrgUUID,
        is_org_perm: isOrgPerm,
      };

      await uploadConcurrent(dcmFiles, options);
    } catch (error) {
      console.error('Error in startUploads:', error);
      if (!(error instanceof UploadCancelError)) {
        handleSnackbarCompleted('アップロードが失敗しました');
      }
    }
  }

  const uploadFiles = async (
    files,
    targetFolderUUID,
    targetOrgUUID = '',
    isOrgPerm = false
  ) => {
    try {
      resetProgress();
      setIsCancelled(false);
      setOpenSnackbar({ open: true, onClose: onCancelUpload });
      setSnackbarMessage('アップロード準備中');

      const filesWithMime = await addMimeTypeToFiles(
        files,
        setSnackbarMessage,
        cancelledRef
      );

      const [dcmFiles, notDcmFiles] = await splitDicomFiles(filesWithMime);

      setUploadOptions({
        dcmFiles: dcmFiles,
        notDcmFiles: notDcmFiles,
        targetFolderUUID: targetFolderUUID,
        targetOrgUUID: targetOrgUUID,
        isOrgPerm: isOrgPerm,
      });

      handleOpenAnon();
    } catch (err) {
      console.error('Upload Error', err);
      let message = 'アップロードが失敗しました';

      if (err instanceof UploadCancelError) {
        message = 'アップロードをキャンセルしました';
        toast.info(message);
        cancelledRef.current = false; // Reset cancelled ref
      } else if (err instanceof DOMException) {
        message =
          'アップロードが失敗しました。ファイルサイズが大きすぎる可能性があります。';
        toast.error(message);
      } else if (err.response && err.response.status === 507) {
        message = 'アップロードが失敗しました。容量制限を超えています';
        toast.error(message);
      }

      handleSnackbarCompleted(message);
    }
  };

  const makeOnDrop = (uploadFolder, isFetch = true) => {
    const onDrop = async files => {
      if (checkFullAccess(uploadFolder.perm)) {
        console.log('makeOnDrop uploadFiles', files.length);
        await uploadFiles(files, uploadFolder.uuid, '', false);
        if (isFetch && window.location.pathname.includes(uploadFolder.uuid)) {
          await updateWhenUpload(uploadFolder);
        }
      } else {
        alert('アップロードにはフルアクセス権限が必要です');
      }
    };
    return onDrop;
  };

  const makeOnDropRoot = (targetOrgUUID = '', rootPermName, isFetch = true) => {
    const onDrop = async files => {
      if (memberPerm) {
        if (rootPermName === 'share') {
          alert('データはプライベート内にアップロードされます。');
        }
        const isOrgPerm = rootPermName === 'org' ? true : false;
        console.log('makeOnDropRoot uploadFiles', files);
        await uploadFiles(files, '', targetOrgUUID, isOrgPerm);
        if (isFetch) {
          await updateWhenUpload();
        }
      } else {
        alert('アップロードには組織に参加する必要があります。');
      }
    };
    return onDrop;
  };

  return (
    <div>
      <UploadConfirmationModal
        open={openSummary}
        handleClose={handleCloseSummary}
        uploadSummary={uploadedStudies}
      />

      {!isCancelled && (
        <AnonymizeSimple
          open={openAnon}
          handleAnonOptions={handleAnonOptions}
          handleAnonClose={handleAnonClose}
        />
      )}
      <UploadContext.Provider
        value={{
          progress,
          setProgress,
          resetProgress,
          completeProgress,
          makeOnDrop,
          makeOnDropRoot,
          uploadFiles,
        }}
      >
        {props.children}
      </UploadContext.Provider>
    </div>
  );
};

UploadProvider.propsTypes = {
  props: propsTypes.node.isRequired,
};

export { UploadContext, UploadProvider };
