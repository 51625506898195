import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedOrgUUID } from './currentOrgUUID';

const getOrgRootList = async ({ orgUUID }) => {
  const pathUrl = `/api/datamanage/list/org-root/${orgUUID}`;
  return await authRequest.get(pathUrl).then(res => res.data);
};

export const useOrgRootList = ({
  orgUUID = '',
  useErrorBoundary = undefined,
} = {}) => {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const placeholderData = useMemo(() => {
    return { org: [], share: [], private: [] };
  }, []);
  return useQuery({
    queryKey: ['orgRootList', usedOrgUUID],
    queryFn: () => getOrgRootList({ orgUUID: usedOrgUUID }),
    enabled: !!usedOrgUUID && usedOrgUUID !== '', // org uuid が存在するまで待機
    placeholderData,
    useErrorBoundary,
  });
};

export function useOrgRootListInvalidate({ orgUUID = '' } = {}) {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['orgRootList', usedOrgUUID]);
  }, [queryClient, usedOrgUUID]);
  return invalidateQueries;
}
