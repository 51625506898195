import React, { useState, useContext, useEffect } from 'react';
import { SnackbarContext } from '../../context/SnackbarContext';
import OHIF from '@ohif/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import AnonymizeTextFields from './AnonymizeTextFields';
import { getAnonymizeDicomData } from './anonymizeUtils';
import { isConferencePage } from '../storage/components/Conference/StorageConference';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AnonymizeForm(props) {
  const classes = useStyles();
  const isConference = isConferencePage();

  const { setOpenSnackbarCollapse, setSnackbarMessage } = useContext(
    SnackbarContext
  );
  const { closeDialog, cancelUpload, studies } = props;
  const { setAnonymizedStudyData, setAnonymizedRadio } = OHIF.redux.actions;
  // Tab選択用Index
  const [studyIndex, setStudyIndex] = useState(0);
  // 変換後のstudy
  const [newStudies, setNewStudies] = useState(studies);

  // Default state of the Radio Group in the Dicomタグの編集及び匿名化 popup
  const initialAnonymizeValue = isConference ? 'anonymizeAndEdit' : 'notAnonymize';

  // Set the default anon settings for all Studies
  const [radioAnonymize, setRadioAnonymize] = React.useState(
    studies.map(() => initialAnonymizeValue)
  );
  const setAnonymizedStudyDataWrap = data => {
    window.store.dispatch(setAnonymizedStudyData(data));
    setNewStudies(data);
  };
  const setAnonymizedRadioWrap = state => {
    window.store.dispatch(setAnonymizedRadio(state));
  };

  const handleChangeTabs = (event, newValue) => {
    setStudyIndex(newValue);
  };

  const doAnonymization = (index) => {
    // 匿名化 was either set as the default, or the radio button was selected
    // This function runs the anonymization and sets appropriate
    // values in newStudies state
    setAnonymizedStudyDataWrap(
      newStudies.map((study, _index) => {
        if (index == _index) {
          return {
            ...getAnonymizeDicomData(studies[_index]),
            HashedStudyInstanceUID: studies[_index]['HashedStudyInstanceUID'],
          };
        } else {
          return study;
        }
      })
    );
  }

  // Run this useEffect once when studyIndex is updated
  // (i.e. user switches tabs in case of multiple studies being uploaded)
  useEffect(() => {
    // Run right away if initialAnonymizeValue set to anonymize,
    // because we need to display anonymized values in the form for the user
    if (isConference && (initialAnonymizeValue == 'anonymizeAndEdit')) {
      console.log('isConference, Default anonymizeAndEdit Study', studyIndex);
      doAnonymization(studyIndex);

      // Also need to set state in Redux
      if (initialAnonymizeValue == 'anonymize') {
        setAnonymizedRadioWrap({ isEdit: false, isAnonymize: true });
      } else if (initialAnonymizeValue == 'anonymizeAndEdit') {
        setAnonymizedRadioWrap({ isEdit: true, isAnonymize: true });
      }
    }
  }, [studyIndex]);

  // useEffect(() => {
  //   console.log('radioAnonymize', radioAnonymize);
  // }, [radioAnonymize]);


  // useEffect(() => {
  //   if (isConference) {
  //     const initAnonSettings = studies.map(() => initialAnonymizeValue);
  //     console.log('Conference studies: set state in Redux, initAnonSettings', initAnonSettings);
  //     setRadioAnonymize(initAnonSettings);
  //   }
  // }, [studies])

  const handleChangeRadio = index => event => {
    setRadioAnonymize(pre =>
      pre.map((check, _index) => {
        if (_index == index) {
          return event.target.value;
        } else {
          return check;
        }
      })
    );

    if (
      event.target.value == 'anonymizeAndEdit' ||
      event.target.value == 'anonymize'
    ) {
      // 匿名化を選択
      // setAnonymizedStudyDataWrap(
      //   newStudies.map((study, _index) => {
      //     if (index == _index) {
      //       return {
      //         ...getAnonymizeDicomData(studies[_index]),
      //         HashedStudyInstanceUID: studies[_index]['HashedStudyInstanceUID'],
      //       };
      //     } else {
      //       return study;
      //     }
      //   })
      // );
      doAnonymization(index);
      if (event.target.value == 'anonymize') {
        setAnonymizedRadioWrap({ isEdit: false, isAnonymize: true });
      } else if (event.target.value == 'anonymizeAndEdit') {
        setAnonymizedRadioWrap({ isEdit: true, isAnonymize: true });
      }
    } else {
      // 匿名化しないを選択
      setAnonymizedStudyDataWrap(studies);
      if (event.target.value == 'notAnonymize') {
        setAnonymizedRadioWrap({ isEdit: false, isAnonymize: false });
      } else if (event.target.value == 'notAnonymizeAndEdit') {
        setAnonymizedRadioWrap({ isEdit: true, isAnonymize: false });
      }
    }
  };

  const handleUpload = () => {
    closeDialog();
  };

  const handleCancel = () => {
    cancelUpload();
  };
  return (
    <>
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Dicomタグの編集及び匿名化
      </DialogTitle>
      <Tabs
        value={studyIndex}
        onChange={handleChangeTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {studies.map((study, index) => {
          return (
            <Tab
              label={`study No.${index + 1}`}
              key={index}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      <DialogContent dividers={scroll === 'paper'}>
        {studies.map((study, index) => {
          // set default to 匿名化及びDICOMタグを編集する if Conference
          console.debug('radioAnonymize', radioAnonymize[index], 'isConference', isConference);
          return (
            <TabPanel value={studyIndex} index={index} key={index}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="radioAnonymize"
                  name="radioAnonymize"
                  value={radioAnonymize[index]}
                  onChange={handleChangeRadio(index)}
                >
                  <FormControlLabel
                    value="notAnonymize"
                    control={<Radio />}
                    label="匿名化しない"
                  />
                  <FormControlLabel
                    value="anonymize"
                    control={<Radio />}
                    label="匿名化する"
                  />
                  <FormControlLabel
                    value="anonymizeAndEdit"
                    control={<Radio />}
                    label="匿名化及びDICOMタグを編集する"
                  />
                  <FormControlLabel
                    value="notAnonymizeAndEdit"
                    control={<Radio />}
                    label="DICOMタグを編集する"
                  />
                </RadioGroup>
              </FormControl>
              {(radioAnonymize[index] == 'anonymizeAndEdit' ||
                radioAnonymize[index] == 'notAnonymizeAndEdit') && (
                <AnonymizeTextFields
                  study={study}
                  studyIndex={index}
                  anonymizedStudyData={newStudies}
                  setAnonymizedStudyData={setAnonymizedStudyDataWrap}
                />
              )}
            </TabPanel>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" id="anonymizeCansel">
          キャンセル
        </Button>
        <Button onClick={handleUpload} color="primary" id="anonymizeButton">
          アップロードする
        </Button>
      </DialogActions>
    </>
  );
}

AnonymizeForm.propTypes = {
  studies: PropTypes.array,
  closeDialog: PropTypes.func.isRequired,
  cancelUpload: PropTypes.func.isRequired,
};
