import React, {
  useEffect,
  useContext,
  useState,
} from 'react';

import { ExpiryDateProvider } from '../../providers/ConferenceExpiryContext';
import { useExpiryDate } from '../../providers/ConferenceExpiryContext';

import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { setOrgLastUrl } from '../../../../utils/orgLastUrl';
import { useSelectData } from '../../utils/useSelectData';
import { useDeleteDialog } from '../../../../components/FormDialog';
import { SnackbarContext } from '../../../../context/SnackbarContext';

import DrawerRootFolder, { NewStorageMenuButton } from '../DrawerRootFolder';
import CaseStudyDialog from './CaseStudyDialog';
import ConferenceHeader from './ConferenceHeader';
import {
  createConference, getConference, editConference, deleteFolder
} from '../../../../api/rest';
import { checkConferenceUser } from './ConferenceList';
import { CONFERENCE_APP_TITLE } from './ConferenceTitle';

import {
  Typography, Button, TextField, Breadcrumbs, Switch,
  FormControlLabel, Link, Paper, Chip, Grid,
  Input, Toolbar
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@material-ui/pickers';

import { CheckedItemToolbar } from '../DataViewer/AgGridTableToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.drawerWidth}px)`,
      margin: 0,
      padding: 0,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  conferenceText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '800px',
    padding: theme.spacing(2), // Add spacing around the form
    margin: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2), // Add spacing between buttons
  },
  button: {
    borderRadius: '10px',
    margin: theme.spacing(1),
  },
  conferenceContainer: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  flexLeft: {
    flex: 1,
  },
  flexRight: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginLeft: 'auto',
    width: `200px`,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

/**
 * Component to display the details for a Conference event.
 */
const ConferenceDetails = ({accessDenied, setAccessDenied}) => {

  console.log('Enviro', process.env);

  // Data for currently selected (active) folder
  const selectData = useSelectData();
  if (selectData.data) {
    console.log('ConferenceDetails data', selectData.data);
  }
  const [openDeleteDialog, DeleteDialog] = useDeleteDialog();

  // Details for a single conference (one event)
  const classes = useStyles();
  const history = useHistory();
  const {
    setSnackbarMessage,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
    setProgressValue,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const [name, setName] = useState('');
  // saved status for Conference form
  const [saved, setSaved] = useState(false);
  const [description, setDescription] = useState('');
  // List of managers (email addresses) for this conference
  const [managers, setManagers] = useState([
    // { key: 0, label: 'test@test.com' },
    // { key: 1, label: 'jQuery@foo.com' },
  ]);
  const [newManager, setNewManager] = useState('');
  const [newManagerError, setNewManagerError] = useState('');
  const { expiryDate, setExpiryDate, globalShared, setGlobalShared } = useExpiryDate();

  // Flag for editing conference details
  const [editing, setEditing] = useState(false);
  // Flag for a new conference (this org has never created a conference)
  const [newConf, setNewConf ] = useState(false);

  // Get user's default org if not set.
  const { data: orgDetails } = useOrgDetail();

  // Default new conference data. Match field names in backend Folder model
  const conferenceData = {
    name: name,
    foldertype: 'folder',
    comment: description,
    org: orgDetails.uuid,
    anon_link_expiry: expiryDate,
    managers: managers,
  };

  // Flag for showing Case Study dialog
  const [showCaseStudy, setShowCaseStudy] = useState(false);
  const handleShowCaseStudy = () => {
    setShowCaseStudy(true);
  }

  /**
   * folderUUID: conferenceUUID converts the local name (in this module)
   * of URL parameter 'folderUUID' to conferenceUUID
   */
  const { action, folderUUID: conferenceUUID, initialOrgId } = useParams();

  // URLs for Breadcrumbs component
  const conferenceListUrl = `/conference/org/${orgDetails.uuid}`;
  const conferenceUrl = `${conferenceListUrl}/event/${conferenceUUID}`;

  // Redirect to parent Conference list
  const goConferenceList = () => {
    history.push(conferenceListUrl);
  }

  // Component has loaded
  useEffect(() => {
    checkConferenceUser();
  }, []);

  // Run this useEffect when the org or folderUUID has been updated
  useEffect(() => {
    // Check if this org already has a Conference
    const getCurrentConference = async () => {
      if (orgDetails.uuid && conferenceUUID){
        // Get conference info only if org is not empty (has been set)
        console.log('Get Conference org', orgDetails.name, 'folder', conferenceUUID);
        try {
          const conferenceInfo = await getConference(conferenceUUID);
          console.log('conferenceInfo', conferenceInfo);
          if (Object.keys(conferenceInfo).length === 0) {
            console.log('No conference found!');
            // Show the conference editing form, and prompt the user
            // to enter conference details
            setNewConf(true);
          } else {
            // Set conference details from saved data
            setName(conferenceInfo.name);
            // Update page title with Conference name
            document.title = conferenceInfo.name + ' | ' + CONFERENCE_APP_TITLE;
            setDescription(conferenceInfo.comment);
            setExpiryDate(conferenceInfo.anon_link_expiry);
            setManagers(conferenceInfo.managers);
          }
          setAccessDenied(false);
          setOrgLastUrl();
        } catch (error) {
          const statusCode = error.response.status;
          console.error('getCurrentConference', error);
          if (statusCode >= 400 && statusCode < 600) {
            // Show access denied page
            setAccessDenied(true);
          }
        }
      }
    }
    getCurrentConference();
  }, [orgDetails.uuid, conferenceUUID]);

  // Run this useEffect when action is changed
  // Check for the action (if any) and act accordingly
  useEffect(() => {
    if (action === 'new') {
      // When action is 'new', set the newConf state to true
      console.log('action NEW CONFERENCE');
      setNewConf(true);
      setEditing(true);
      setAccessDenied(false);
    } else {
      // Optionally handle other cases here, e.g., set to false if needed
      console.log('action NOT SET');
    }
  }, [action]);

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  }

  const handleAddManager = (event) => {
    // Add to the list of managers
    const isValid = validateEmail(newManager);
    console.log('Email', isValid, newManager);
    if (isValid) {
      // Ignore if duplicate
      if (managers.includes(newManager)) {
        console.log('Duplicate, ignore', newManager);
        return;
      }
      // Add email to React Chips with array spreading
      setManagers([
        ...managers,
        newManager
        // { key: managers.length, label: newManager }
      ]);
      // Clear the newManager text box
      setNewManager('');
    }
  }

  const validateEmail = (email) => {
    let isValid = false;
    if (email.length > 3) {
      // Email string has length, check here
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        // Set error text empty
        setNewManagerError('');
        isValid = true;
        // Add the email to React Chips component
      } else {
        // Email is not valid
        setNewManagerError('無効なメールアドレス');
        isValid = false;
      }
    } else {
      // Email too short
      setNewManagerError('無効なメールアドレス');
    }
    return isValid;
  }

  const handleChangeNewManager = (event) => {
    // Set error text empty until next click on 管理者を追加 button
    setNewManagerError('');
    const val = event.target.value;
    console.log('handleChangeNewManager', val);
    // Update the new manager text field
    setNewManager(val);
  }

  const handleDeleteManagers = (managerToDelete) => () => {
    const newManagers = managers.filter((manager) => manager !== managerToDelete);
    console.log('Remove manager', managerToDelete, 'newManagers', newManagers);
    setManagers(newManagers);
  };

  const handleSave = async () => {
    console.log('Save Conference');
    // User tried to save a conference, validate here
    setSaved(true);
    if (name.trim() === '') {
      console.log('Conference form is incomplete!');
      return;
    }

    try {
      if (newConf) {
        console.log('NEW Conference', conferenceData);
        const conferenceInfo = await createConference(conferenceData);
        console.log('conferenceInfo', conferenceInfo);
        setEditing(false);
        setNewConf(false);
        setAccessDenied(false);
        // Redirect to page for the conference just created
        const newConferenceUrl = conferenceListUrl + '/event/' + conferenceInfo.uuid;
        console.log('newConferenceUrl', newConferenceUrl);
        history.push(newConferenceUrl);
        // Set last-used URL here, AFTER navigating to the Conference URL
        // if done before, it might set last URL to /login
        setOrgLastUrl();
      } else {
        console.log('EDIT existing Conference, data', conferenceData, 'folder', conferenceUUID);
        const conferenceInfo = await editConference(conferenceData, conferenceUUID);
        setEditing(false);
        setAccessDenied(false);
      }
    } catch (error) {
      console.log('Bad conference data');
    }
  }

  const handleCancel = () => {
    setEditing(false);
    setSaved(false);
    // TODO: if cancel button was clicked from a new Conference, go back to the conference list page
  }

  const deleteConference = async () => {
    setOpenSnackbar(true);
    setSnackbarMessage('削除しています...');
    await deleteFolder(selectData.data.uuid);
    // Redirect to parent Conference list
    goConferenceList();
    handleSnackbarCompleted('削除が完了しました');
  }

  const showConfirmDelete = async () => {
    // Show delete confirmation, act based on user's response
    try {
      const deleted = await openDeleteDialog(selectData.data);
      console.log(selectData.data.name, 'DELETED', deleted);
      if (deleted) {
        await deleteConference();
      }
    } catch (e) {
      throwError(e);
    }
  }

  const onExpiryDateChange = async (newDate) => {
    setExpiryDate(newDate);
    console.log('NEW GLOBAL expiry date', newDate);
    const editData = {
      name: name,
      foldertype: 'folder',
      comment: description,
      org: orgDetails.uuid,
      anon_link_expiry: newDate,
      uuid: conferenceUUID,
    }
    console.log('Edit Conference', editData);
    const conferenceInfo = await editConference(editData, conferenceUUID);
    console.log('conferenceInfo', conferenceInfo);
  }

  const handleGlobalShared = () => {
    // Global on-off switch was toggled.
    console.log('Setting globalShared:', !globalShared);
    if (globalShared) {
      setGlobalShared(false);
    } else {
      setGlobalShared(true);
    }
  }

  const handleEditing = () => {
    setEditing(true);
    setSaved(false);
  }

  return (
    <div>
      <ConferenceHeader/>
      {/* Render ConferenceDetails if access is allowed */}
      {(
        accessDenied === null ? (<div></div>) :
        accessDenied === false ? (
          <div>
            {!editing ? (
              // Viewing a Conference
              <div className={classes.conferenceContainer}>
                <div className={classes.flexLeft}>
                  {/* Breadcrumbs component at top of Conference */}
                  <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                    <Link color="inherit" href={conferenceListUrl}>
                      {orgDetails.name}
                    </Link>
                    <Link color="inherit" href={conferenceUrl}>
                      {name}
                    </Link>
                  </Breadcrumbs>
                  <div className={classes.conferenceText}>
                    <Typography>
                      <Link
                        href={conferenceListUrl}
                        onClick={goConferenceList}
                      >
                        学会のリストに戻る
                      </Link>
                    </Typography>
                    <Typography variant="h6">
                      {name}
                    </Typography>
                    <Typography>{description}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditing}
                      className={classes.button}
                    >
                      編集
                    </Button>
                    <CheckedItemToolbar orgId={orgDetails.uuid} />
                  </div>
                </div>
                <div className={classes.flexRight}>
                  {/* Global sharing on-off switch */}
                  <FormControlLabel
                    control = {
                      <Switch
                        checked={globalShared}
                        onClick={handleGlobalShared}
                        name="globalShareCheckbox"
                        color="primary"
                      />
                    }
                    label="全検査の共有"
                  />
                  {/* Date picker for conference expiry date */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={expiryDate}
                      format="yyyy-MM-dd"
                      label="公開期限"
                      onChange={onExpiryDateChange}
                      disablePast={true}
                    />
                  </MuiPickersUtilsProvider>
                  {/* Data Upload button is shown here after all, not only inside a CaseStudy
                  */}
                  <NewStorageMenuButton />

                  <CaseStudyDialog
                    open={showCaseStudy}
                    setOpen={setShowCaseStudy}
                    conferenceUUID={conferenceUUID}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowCaseStudy}
                    className={classes.button}
                  >
                    症例を追加
                  </Button>
                </div>
              </div>

            ) : (
              // Editing a Conference
              <div className={classes.form}>
                <DeleteDialog />
                { newConf && (
                  <Typography>
                    この組織に学会はまだ作成していない。最初は、新しい学会を追加しましょう。
                  </Typography>
                )}
                <form>
                  <TextField
                    label="名前"
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeName}
                    value={name}
                    className={classes.textField}
                    placeholder="学会イベントの名前"
                    required={true}
                    error={!name && saved} // Set to true if name is empty
                    helperText={!name && "名前は必須です"}
                  />
                  <TextField
                    label="説明テキスト"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    onChange={handleChangeDescription}
                    value={description}
                    className={classes.textField}
                    placeholder="学会の説明テキスト"
                    required={true}
                  />
                  {/* React Chips for editing list of Conference managers */}
                  <Typography>{managers.length}人の学会管理者</Typography>
                  { managers.length > 0 && (
                    // Display the Paper container for managers only if managers > 0
                    <Paper component="ul" className={classes.paper}>
                      {managers.map((email, i) => {
                        // Map manager emails with index i to an array of React Chips
                        return (
                          <li key={i}>
                            <Chip
                              label={email}
                              // Handle delete using the item index
                              onDelete={ handleDeleteManagers(email) }
                              className={classes.chip}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  )}

                  {/* Input field and button to add manager by email */}
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        label="管理者のメールアドレスを追加"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleChangeNewManager}
                        value={newManager}
                        className={classes.textField}
                        placeholder="xxx@mnes.org"
                        error={newManagerError}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddManager}
                        className={classes.button}
                      >管理者を追加</Button>
                    </Grid>
                  </Grid>

                  {/*
                    Show cancel button if this is not a new conference.
                    If user has never created a conference, require them to
                    enter basic conference details here.)
                  */}
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                    {!newConf && (

                        <Button
                          variant="outlined"
                          color="default"
                          onClick={handleCancel}
                          className={classes.button}
                        >
                          キャンセル
                        </Button>

                    )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        className={classes.button}
                      >
                        保存
                      </Button>
                    </Grid>
                    {/* Delete button on the right */}
                    <div className={classes.flexRight}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={showConfirmDelete}
                        className={classes.button}
                      >
                        学会を削除する
                      </Button>
                    </div>
                  </Grid>
                </form>
              </div>
            )}
          </div>
        ) :
        (<div></div>)
      )}
    </div>
  )
}

export default ConferenceDetails;
