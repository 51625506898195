import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '@ohif/viewer/src/providers/UserContext';
import Typography from '@material-ui/core/Typography';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';

/**
 * NOTE: This UserButton component is NOT used anywhere in S9.
 * ALSO NOTE: this component is identical (appears to be a symlink?) to the UserButton
 * component in Viewers/node_modules/@ohif/ui/src/components/userButton/UserButton.js
 * NEITHER of these UserButton components appear to be used in S9, other than being
 * imported in index.js, but appears to not be used anywhere.
 *
 * Instead, the top-right button, and the menu that pops out from the top-right, are at:
 * Viewers/platform/viewer/src/features/storage/components/TopBar/OrgListButton.js
 *
 */

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

function UserButton(props) {
  const classes = useStyles();
  const { isUserLoggedIn, user } = useContext(UserContext);

  return (
    <div className={classes.buttons}>
      {isUserLoggedIn && Object.keys(user).length > 0 ? (
        <>
          <Button variant="outlined" onClick={props.onClick}>
            <Avatar className={classes.small}>
              <PermIdentityOutlinedIcon />
            </Avatar>
            <Typography>{user.email}</Typography>
          </Button>
        </>
      ) : (
        <Button variant="outlined" onClick={props.onClick}>
          {'未ログイン'}
        </Button>
      )}
    </div>
  );
}

UserButton.propTypes = {
  onClick: PropTypes.func,
};

export default UserButton;
