import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { editAttributeNames } from './anonymizeUtils';
import { isConferencePage } from '../storage/components/Conference/StorageConference';
import { conferenceAnon } from './anonymizeUtils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    height: '50%',
  },
  // Highlighted input field for warning user
  warningInput: {
    background: "rgb(255, 244, 229)",  // MUI warning color
  },
  // Default white background
  whiteInput: {
    background: "white",
  }
}));

export default function AnonymizeTextFields(props) {
  const {
    study,
    studyIndex,
    anonymizedStudyData,
    setAnonymizedStudyData,
  } = props;
  const classes = useStyles();
  const isConference = isConferencePage();

  return (
    <div className={classes.root}>
      { isConference && (
        <Alert severity="warning">
          以下の黄色欄はビューワ内に表示されるので、空欄となるように設定しています。ただしテキスト入力も可能です。
        </Alert>
      )}
      {editAttributeNames.map((attributeName, index) => {
        return (
          <TagTextField
            label={attributeName}
            originalValue={study[attributeName] || ''}
            studyIndex={studyIndex}
            anonymizedStudyData={anonymizedStudyData}
            setAnonymizedStudyData={setAnonymizedStudyData}
            key={index}
          />
        );
      })}
    </div>
  );
}

AnonymizeTextFields.propTypes = {
  study: PropTypes.object.isRequired,
  studyIndex: PropTypes.number.isRequired,
  anonymizedStudyData: PropTypes.array.isRequired,
  setAnonymizedStudyData: PropTypes.func.isRequired,
};

function TagTextField(props) {
  const {
    label,
    originalValue,
    studyIndex,
    anonymizedStudyData,
    setAnonymizedStudyData,
  } = props;
  const classes = useStyles();
  const isConference = isConferencePage();

  let fieldStyle;
  if (isConference && (label in conferenceAnon)) {
    fieldStyle = classes.warningInput;
  } else {
    fieldStyle = classes.whiteInput;
  }

  const handleChange = event => {
    event.persist();
    // 選択されているstudyの[label]を編集する
    setAnonymizedStudyData(
      anonymizedStudyData.map((study, index) => {
        if (index == studyIndex) {
          return { ...study, [label]: event.target.value };
        } else {
          return study;
        }
      })
    );
  };

  return (
    <>
      <Typography>{label}</Typography>
      <TextField
        value={anonymizedStudyData[studyIndex][label] || ''}
        onChange={handleChange}
        variant="outlined"
        size="small"
        color="warning"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ className: fieldStyle }}
        helperText={`変更前：${originalValue}`}
      />
    </>
  );
}

TagTextField.propTypes = {
  label: PropTypes.string.isRequired,
  originalValue: PropTypes.string.isRequired,
  anonymizedStudyData: PropTypes.array.isRequired,
  setAnonymizedStudyData: PropTypes.func.isRequired,
  studyIndex: PropTypes.number.isRequired,
};
