// Custom hook to track if the component is mounted
import { useEffect, useRef } from 'react';

export default function useIsMounted() {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
// Usage
// const isMounted = useIsMounted();
// console.log(isMounted.current); // true or false
