import { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import SimpleDialog from '../SimpleDialog/SimpleDialog.js';
import './EditDescriptionDialog.css';

export default class EditDescriptionDialog extends Component {
  static propTypes = {
    text: PropTypes.string,
    description: PropTypes.string,
    measurementData: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      description: props.measurementData.description || '',
      text: props.measurementData.text || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text) {
      this.setState({
        text: this.props.text,
      });
    }
    if (this.props.description !== prevProps.description) {
      this.setState({
        description: this.props.description,
      });
    }
  }

  render() {
    // console.log('Edit Dialog:', this.props.measurementData);
    return (
      <SimpleDialog
        headerTitle="アノテーションを編集する"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        rootClass="editDescriptionDialog"
      >
        { this.props.measurementData.toolType === 'ArrowAnnotate' ? (
          <div>
            <div>アノテーション</div>
            <input
            value={this.state.text}
            className="simpleDialogInput"
            id="text"
            autoComplete="off"
            autoFocus
            onChange={this.handleChangeText}
            />
          <p></p>
          </div>

          ) : (<></>) }
        <div>
          <div>記述・本体部</div>
          <input
            value={this.state.description}
            className="simpleDialogInput"
            id="description"
            autoComplete="off"
            onChange={this.handleChangeDescription}
          />
        </div>
      </SimpleDialog>
    );
  }

  onClose = () => {
    this.props.onCancel();
  };

  onConfirm = e => {
    e.preventDefault();
    this.props.onUpdate(
      this.state.text,
      this.state.description
    );
  };

  handleChangeDescription = event => {
    this.setState({ description: event.target.value });
  };

  handleChangeText = event => {
    this.setState({ text: event.target.value });
  };
}
