import FileType from 'file-type/browser';
import { DICOM_FILETYPE, DICOMDIR_FILETYPE } from '../constants';
import { UploadCancelError } from './index';

export const addMimeTypeToFiles = async (
  selectedFiles,
  setSnackbarMessage,
  cancelledRef
) => {
  /*
   ファイルタイプ を取得して加える
  */
  for (let i = 0; i < selectedFiles.length; i++) {
    if (cancelledRef.current === true) {
      console.warn('Pre-processing CANCELLED! (Upload cancelled by user)');
      throw new UploadCancelError(
        'Pre-processing CANCELLED! (Upload cancelled by user)'
      );
    }
    const file = selectedFiles[i];
    const filetype = await FileType.fromStream(file.stream());
    if (filetype) {
      selectedFiles[i].filetype = filetype.mime;
    } else {
      selectedFiles[i].filetype = '';
    }
    setSnackbarMessage(`アップロード準備中(${i})`);
  }
  // DICOMDIRのfiletypeを変更
  for (let i = 0; i < selectedFiles.length; i++) {
    if (selectedFiles[i].path.split('/').pop() === 'DICOMDIR') {
      if (selectedFiles[i].filetype === DICOM_FILETYPE) {
        selectedFiles[i].filetype = DICOMDIR_FILETYPE;
      }
    }
  }
  return selectedFiles;
};
