import { asyncComponent, retryImport } from '@ohif/ui';
import OHIF from '@ohif/core';
import { lazyImport } from '@ohif/viewer/src/utils/lazyImports';

const { urlUtil: UrlUtil } = OHIF.utils;

const StandaloneRouting = asyncComponent(() =>
  retryImport(() =>
    import('../features/dicomViewer/routes/ConnectedStandaloneRouting')
  )
);
const ViewerLocalFileData = asyncComponent(() =>
  retryImport(() =>
    import('../features/dicomViewer/routes/ViewerLocalFileData')
  )
);

const FrontPage = asyncComponent(() =>
  retryImport(() => import('../features/misc/routes/FrontPage'))
);
const OldFrontPage = asyncComponent(() =>
  retryImport(() => import('../features/misc/routes/OldFrontPage'))
);

// New Universal Viewer
const UniversalViewerContainer = asyncComponent(() =>
  retryImport(() => import('../features/univiewer/UniversalViewerContainer.js'))
);

// Import ConferenceList as a separate import
const ConferenceList = asyncComponent(() =>
  retryImport(() => import('../features/storage/components/Conference/ConferenceList.js'))
);

const WelcomePage = asyncComponent(() =>
  retryImport(() => import('../features/storage/components/Conference/WelcomePage.js'))
);

// Conference event page. Details of the specific conference displayed are set by URL parameter
const EventPageTemplate = asyncComponent(() =>
  retryImport(() => import('../features/storage/components/Conference/EventPages/EventPageTemplate.js'))
);

const StorageManagerWrap = asyncComponent(() =>
  retryImport(() => import('../features/storage/routes/StorageManagerWrap.js'))
);
const DownloadPage = asyncComponent(() =>
  retryImport(() => import('../api/DownloadSnippet.js'))
);
const StoragePrev = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "StoragePrev" */ '../features/storage/routes/StoragePrev.js'
    )
  )
);
const LoginPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "LoginPage" */ '../features/auth/routes/LoginPage.js'
    )
  )
);
const SignupPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "SignupPage" */ '../features/auth/routes/SignupPage.js'
    )
  )
);
const PasswordResetPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PasswordResetPage" */ '../features/auth/routes/PasswordResetPage.js'
    )
  )
);
const PasswordResetConfirmPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PasswordResetConfirmPage" */ '../features/auth/routes/PasswordResetConfirmPage.js'
    )
  )
);
const ResendEmailPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ResendEmailPage" */ '../features/auth/routes/ResendEmailPage.js'
    )
  )
);
const EmailConfirmationPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ResendEmailPage" */ '../features/auth/routes/EmailConfirmationPage.js'
    )
  )
);
const OrganizationPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "OrganizationPage" */ '../features/organization/routes/OrganizationPage.js'
    )
  )
);
const CreateOrganizationPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "CreateOrganizationPage" */ '../features/organization/components/CreateOrganizationPage.js'
    )
  )
);
const JoinOrganizationPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "JoinOrganizationPage" */ '../features/organization/components/JoinOrganizationPage.js'
    )
  )
);
const PdfViewerPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PdfViewer" */ '../features/easyViewer/PdfViewer.js'
    )
  )
);
const ImgViewerPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ImageViewer" */ '../features/easyViewer/ImageViewer.js'
    )
  )
);
const VideoViewerPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "VideoViewer" */ '../features/easyViewer/VideoViewer.js'
    )
  )
);
const TextViewerPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "TextViewer" */ '../features/easyViewer/TextViewer.js'
    )
  )
);

/* 元のcornerstoneで利用 */
// Dynamic Import Routes (CodeSplitting)
const IHEInvokeImageDisplay = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ './IHEInvokeImageDisplay.js'
    )
  )
);
const StudyListRouting = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "StudyListRouting" */ '../unused/studylist/StudyListRouting.js'
    )
  )
);
const ViewerRouting = asyncComponent(() =>
  retryImport(() =>
    lazyImport(() => import('../features/dicomViewer'), 'ViewerRouting')
  )
);

const UrlNotFound = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "TextViewer" */ '../../../ui/src/components/errorBoundary/UrlNotFound.js'
    )
  )
);

/** Concierge data search */
const ConciergeSearch = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConciergeSearch" */ '../features/concierge/ConciergeSearch.js'
    )
  )
);

const reload = () => window.location.reload();


/**
 * Routes defined in S9 frontend.
 * */
const ROUTES_DEF = {
  default: {
    oldfrontpage: {
      path: '/oldhome',
      component: OldFrontPage,
    },
    frontpage: {
      path: '/',
      component: FrontPage,
    },
    standaloneViewer: {
      path: '/viewer',
      component: StandaloneRouting,
    },
    list: {
      path: '/studylist',
      component: StudyListRouting,
      condition: appConfig => {
        return appConfig.showStudyList;
      },
    },
    local: {
      path: '/local',
      component: ViewerLocalFileData,
    },

    /** Paths for Universal Viewer */
    universalViewer: {
      path: ['/universal-viewer'],
      component: UniversalViewerContainer,
    },
    conferenceWelcome: {
      path: [
        '/conference/welcome',
        '/conference/welcome/:email',
      ],
      component: WelcomePage,
    },
    conference: {
      path: [
        // Display the list of Conferences for the given Org
        '/conference/org/:initialOrgId',
        // Display the ConferenceList page if no org is set.
        // ConferenceList will create a new org, or redirect to an existing org,
        // if none is defined in the URL
        '/conference',
        '/conference/',
      ],
      component: ConferenceList,
    },
    eventpages: {
      path: [
        // Display the Conference event page template
        '/conference/eventpage/:eventName',
      ],
      component: EventPageTemplate,
    },
    storagePrev: {
      path: ['/storage', '/storage/org/:initialOrgId'],
      component: StoragePrev,
    },

    /** Paths for S9 File Manager */
    storage: {
      path: [
        '/storage/:folderUUID',
        '/storage/org/:initialOrgId/tutorial/:tutorialId',
        '/storage/org/:initialOrgId/root/:rootPermName',
        // Use storage manager (File Manager view) to display the contents of a specific conference
        '/conference/org/:initialOrgId/event/:folderUUID',
        '/conference/org/:initialOrgId/event/:parentUUID/casestudy/:folderUUID',
        '/conference/org/:initialOrgId/event/:parentUUID/casestudy/:folderUUID/upload',
        // Path to add a new conference
        '/conference/org/:initialOrgId/:action',
        '/conference/org/:initialOrgId/root/:rootPermName',
        // Filter view on data (not a specific folder)
        '/storage/org/:initialOrgId/:filter',
      ],
      component: StorageManagerWrap,
    },

    /**
     * Make an upload page URL that looks like
     * /conferenceupload/:caseStudyUUID
     * where caseStudyUUID is the ID of a Case Study folder within a conference.
     * This URL will render a simplified upload interface, with only this content:
     * - Upload button (file and folder)
     * - File manager table with case study contents, if any
     * - instructions for uploading
     *
     * Make implementation simpler. Just use a regular Folder for each Case Study.
     * Store title > name, and description > comment.
     *
     * When uploading to this folder, we don't need a specific property indicating that
     * it's a Case Study folder. The fact that it's under a Conference is all we need to know.
     */

    /** Data download URL */
    download: {
      path: [
        '/download/:uuid',
      ],
      component: DownloadPage,
    },

    /** Login, sign-up, and related URLs */
    login: {
      path: '/login',
      component: LoginPage,
    },
    signup: {
      path: '/signup',
      component: SignupPage,
    },
    pw: {
      path: '/pw-reset',
      component: PasswordResetPage,
    },
    pwReset: {
      path: '/rest-auth/password/reset/confirm/:uid/:token/',
      component: PasswordResetConfirmPage,
    },
    resendEmail: {
      path: '/resend-email/',
      component: ResendEmailPage,
    },
    // Email confirmation redirect URL
    confirmEmail: {
      path: '/email-verified/',
      component: EmailConfirmationPage,
    },

    /** Organization-related URLs */
    organization: {
      path: '/organization',
      component: OrganizationPage,
    },
    createOrganization: {
      path: '/organization/create',
      component: CreateOrganizationPage,
    },
    joinOrganization: {
      path: '/organization/join',
      component: JoinOrganizationPage,
    },
    IHEInvokeImageDisplay: {
      path: '/IHEInvokeImageDisplay',
      component: IHEInvokeImageDisplay,
    },
    /** PDF Viewer */
    PdfViewerPage: {
      path: '/pdf/:pdfUUID',
      component: PdfViewerPage,
    },
    /** Image viewer (jpg, png, non-DICOM) */
    ImgViewerPage: {
      path: '/img/:imgUUID',
      component: ImgViewerPage,
    },
    VideoViewerPage: {
      path: '/video/:videoUUID',
      component: VideoViewerPage,
    },
    TextViewerPage: {
      path: '/text/:textUUID',
      component: TextViewerPage,
    },

    /** MNES Medical search page */
    ConciergePage: {
      path: [
        '/medical-search',
        '/medical-search/search',
      ],
      component: ConciergeSearch,
    },

    // 使用していない
    viewer: {
      path: '/viewer/:studyInstanceUIDs',
      component: ViewerRouting,
    },
  },
  gcloud: {
    viewer: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUIDs',
      component: ViewerRouting,
      condition: appConfig => {
        return !!appConfig.enableGoogleCloudAdapter;
      },
    },
    list: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore',
      component: StudyListRouting,
      condition: appConfig => {
        const showList = appConfig.showStudyList;

        return showList && !!appConfig.enableGoogleCloudAdapter;
      },
    },
  },
};

const getRoutes = appConfig => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];

    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute =
        typeof route.condition === 'function'
          ? route.condition(appConfig)
          : true;

      if (validRoute) {
        routes.push({
          path: route.path,
          Component: route.component,
        });
      }
    }
  }

  return routes;
};

const parsePath = (path, server, params) => {
  let _path = path;
  const _paramsCopy = Object.assign({}, server, params);

  for (let key in _paramsCopy) {
    _path = UrlUtil.paramString.replaceParam(_path, key, _paramsCopy[key]);
  }

  return _path;
};

const parseViewerPath = (appConfig = {}, server = {}, params) => {
  let viewerPath = ROUTES_DEF.default.viewer.path;
  if (appConfig.enableGoogleCloudAdapter) {
    viewerPath = ROUTES_DEF.gcloud.viewer.path;
  }
  return parsePath(viewerPath, server, params);
};

const parseStudyListPath = (appConfig = {}, server = {}, params) => {
  let studyListPath = ROUTES_DEF.default.list.path;
  if (appConfig.enableGoogleCloudAdapter) {
    studyListPath = ROUTES_DEF.gcloud.list.path || studyListPath;
  }

  return parsePath(studyListPath, server, params);
};

export { getRoutes, parseViewerPath, parseStudyListPath, reload };
