import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { useOrgDetail } from '../../../organization/api/getOrgDetail';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.color.bgColorDark,
    padding: theme.spacing(2),
  },
}));

export default function HelpButton() {
  const classes = useStyles();
  const history = useHistory();
  const { data: orgDetails } = useOrgDetail();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleClick}
        className={classes.icon}
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem
            button
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(null);
              history.push(`/storage/org/${orgDetails.uuid}/tutorial/1`);
            }}
          >
            チュートリアル
          </ListItem>
          <ListItem
            button
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(null);
              history.push(`/storage/org/${orgDetails.uuid}/tutorial/2`);
            }}
          >
            画像シリーズの作成
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
