import { useCallback, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useFolderInfo } from '../api/getFolderInfo';
import { useIsCheck } from './useParams';

export const useSelectData = () => {
  return useSelector(state => state.storageManager.selectData);
};

export const useSetSelectData = () => {
  const dispatch = useDispatch();
  return useCallback(
    _selectData => {
      dispatch({ type: 'SET_SELECTDATA', state: _selectData });
    },
    [dispatch]
  );
};

export const useSetSelectDataCurrent = () => {
  const { isRootPage } = useIsCheck();
  const setSelectData = useSetSelectData();

  const {
    data: folderInfo,
    isLoading: isLoadingFolderInfo,
    isError: isErrorFolderInfo,
  } = useFolderInfo();

  return useCallback(() => {
    if (isRootPage()) {
      setSelectData({
        type: 'folder',
        data: {},
      });
    } else if (!isLoadingFolderInfo && !isErrorFolderInfo) {
      setSelectData({
        type: 'folder',
        data: folderInfo.current_folder,
      });
    }
  }, [
    folderInfo,
    isErrorFolderInfo,
    isLoadingFolderInfo,
    isRootPage,
    setSelectData,
  ]);
};
