import { useFolderPathReset } from './getFolderPath';
import { useFolderInfoReset } from './getFolderInfo';
import { useOrgDetail } from '../../organization/api/getOrgDetail';
import { useOrgRootList } from '../../organization/api/getOrgRootList';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const useFetchUpdateInfo = () => {
  // 表示に重要な全てのデータをUpdateする
  const history = useHistory();
  const refetchFolderPath = useFolderPathReset();
  const refetchFolderInfo = useFolderInfoReset();
  const { refetch: refetchOrgDetail } = useOrgDetail();
  const { refetch: refetchOrgRootList } = useOrgRootList();

  const fetchUpdateInfo = async (folderUUID = '') => {
    history.replace();
    await refetchFolderPath({ folderUUID });
    await refetchFolderInfo({ folderUUID });
    await refetchOrgDetail();
    await refetchOrgRootList();
  };
  return fetchUpdateInfo;
};

export const useUpdateWhenDelete = () => {
  const { refetch: refetchOrgDetail } = useOrgDetail();
  const { refetch: refetchOrgRootList } = useOrgRootList();
  const refetchFolderInfo = useFolderInfoReset();
  const updateWhenDelete = async items => {
    await Promise.all(
      items.map(item => {
        if (item.object_type === 'folder' && item.parent_folder !== null) {
          return refetchFolderInfo({
            folderUUID: item.parent_folder,
            apiOptions: { noFile: true, noUrl: true, combine: false },
          });
        }
      })
    );
    await refetchOrgDetail();
    await refetchOrgRootList();
  };
  return updateWhenDelete;
};

export const useUpdateWhenUpload = () => {
  const refetchFolderInfo = useFolderInfoReset();
  const { refetch: refetchOrgRootList } = useOrgRootList();
  const queryClient = useQueryClient();

  const updateWhenUpload = async (toFolder = null) => {
    queryClient.invalidateQueries();
    //queryClient.resetQueries();
    /*
    // 以下の処理ではアップデートされない場合がある（フォルダを置き換えずにそのままアップロードした場合
    console.log(toFolder);
    if (toFolder === null) {
      await refetchOrgRootList();
    } else {
      await refetchFolderInfo({ folderUUID: toFolder.uuid });
      await refetchFolderInfo({
        folderUUID: toFolder.uuid,
        apiOptions: { noFile: true, noUrl: true, combine: false },
      });
    }
    */
  };
  return updateWhenUpload;
};

export const useUpdateMoveData = () => {
  // データ移動時に必要な更新を行う
  const refetchFolderPath = useFolderPathReset();
  const refetchFolderInfo = useFolderInfoReset();
  const { refetch: refetchOrgRootList } = useOrgRootList();

  const updateMoveData = async (data, toFolder) => {
    // 移動対象の更新
    if (data.object_type == 'folder') {
      refetchFolderPath({
        folderUUID: data.uuid,
      });
    }
    // 変更元更新
    if (data.parent_folder !== null) {
      refetchFolderPath({ folderUUID: data.parent_folder });
      refetchFolderInfo({ folderUUID: data.parent_folder });
      refetchFolderInfo({
        folderUUID: data.parent_folder,
        apiOptions: { noFile: true, noUrl: true, combine: false },
      });
    }
    // 変更先更新
    refetchFolderInfo({ folderUUID: toFolder.uuid });
    refetchFolderInfo({
      folderUUID: toFolder.uuid,
      apiOptions: { noFile: true, noUrl: true, combine: false },
    });
    // rootの更新
    refetchOrgRootList();
  };
  return updateMoveData;
};
