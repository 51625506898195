import React, {
  useEffect,
  useContext,
  useState,
} from 'react';

import OrgMenuSecondary from '../../../concierge/OrgMenuSecondary';
import ConferenceTitle from './ConferenceTitle';
import {
  Toolbar, Typography, Link, Button
} from '@material-ui/core';
import { UserContext } from '../../../../providers/UserContext';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.drawerWidth}px)`,
      margin: 0,
      padding: 0,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  // title: {
  //   flexGrow: 1,
  //   color: theme.palette.primary.main,
  //   margin: theme.spacing(2),
  // },
  toolbar: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  conferenceText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '800px',
    padding: theme.spacing(2), // Add spacing around the form
    margin: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2), // Add spacing between buttons
  },
  button: {
    borderRadius: '10px',
    margin: theme.spacing(1),
  },
  conferenceContainer: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  flexLeft: {
    flex: 1,
  },
  flexRight: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginLeft: 'auto',
    maxWidth: '400px',
  },
}));

const ConferenceLogin = () => {

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.primary.main,
      width: '88px',
      height: '40px',
      borderRadius: '40px',
      marginRight: '2rem',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'rgba(44, 112, 223, 0.1)',
      },
      '&.MuiButton-outlinedSecondary': {
        borderColor: theme.color.mainColor,
      },
    },
  }))(Button);

  // TODO: use login URL: http://localhost/login?continueUrl=/conference/ ?
  // const conferenceLoginUrl = '/login?continueUrl=/conference/';
  const conferenceLoginUrl = '/login';

  return (
    <div>
      <ColorButton color="primary" component={Link} href={conferenceLoginUrl}>
        ログイン
      </ColorButton>
    </div>
  );
}

const ConferenceHeader = () => {

  const classes = useStyles();
  /**
   * If this is a welcome page (user account is pending), show neither Login
   * button, nor OrgMenuSecondary button.
   *
   *
   * If user is not logged in, show the ConferenceLogin button.
   * Otherwise, show the OrgMenuSecondary button.
   */
  const { isUserLoggedIn } = useContext(UserContext);
  const isWelcomePage = window.location.pathname.startsWith('/conference/welcome');

  return (
    <Toolbar className={classes.toolbar}>
        <div className={classes.flexLeft}>
          <div className={classes.hiddenSm}>
            <ConferenceTitle />
          </div>
        </div>

        {(!isWelcomePage) && (
          <div className={classes.flexRight}>
            { isUserLoggedIn ? (
              <OrgMenuSecondary />
            ) : (
              <ConferenceLogin />
            )}
          </div>
        )}
      </Toolbar>
  );
}

export default ConferenceHeader;
