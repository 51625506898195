/** Action Creators:
 *  https://redux.js.org/basics/actions#action-creators
 */

import {
  CLEAR_VIEWPORT,
  SET_ACTIVE_SPECIFIC_DATA,
  SET_SERVERS,
  SET_VIEWPORT,
  SET_VIEWPORTS,
  SET_VIEWPORT_ACTIVE,
  SET_VIEWPORT_LAYOUT,
  SET_VIEWPORT_LAYOUT_AND_DATA,
  SET_USER_PREFERENCES,
} from './constants/ActionTypes.js';

/**
 * The definition of a viewport layout.
 *
 * @typedef {Object} ViewportLayout
 * @property {number} numRows -
 * @property {number} numColumns -
 * @property {array} viewports -
 */

/**
 * VIEWPORT
 */
export const setViewportSpecificData = (
  viewportIndex,
  viewportSpecificData
) => ({
  type: SET_VIEWPORT,
  viewportIndex,
  viewportSpecificData,
});

export const setViewportActive = viewportIndex => ({
  type: SET_VIEWPORT_ACTIVE,
  viewportIndex,
});

/**
 * @param {ViewportLayout} layout
 */
export const setLayout = ({ numRows, numColumns, viewports }) => ({
  type: SET_VIEWPORT_LAYOUT,
  numRows,
  numColumns,
  viewports,
});

export const setViewports = viewports => ({
  type: SET_VIEWPORTS,
  viewports,
});

/**
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} viewports
 */
export const setViewportLayoutAndData = (
  { numRows, numColumns, viewports },
  viewportSpecificData
) => ({
  type: SET_VIEWPORT_LAYOUT_AND_DATA,
  numRows,
  numColumns,
  viewports,
  viewportSpecificData,
});

export const clearViewportSpecificData = viewportIndex => ({
  type: CLEAR_VIEWPORT,
  viewportIndex,
});

export const setActiveViewportSpecificData = viewportSpecificData => ({
  type: SET_ACTIVE_SPECIFIC_DATA,
  viewportSpecificData,
});

/**
 * NOT-VIEWPORT
 */
export const setUserPreferences = state => ({
  type: SET_USER_PREFERENCES,
  state,
});

export const setExtensionData = (extension, data) => ({
  type: 'SET_EXTENSION_DATA',
  extension,
  data,
});

export const setViewingUsers = state => ({
  type: 'SET_VIEWING_USERS',
  state,
});
export const setViewingSender = state => ({
  type: 'SET_VIEWING_SENDER',
  state,
});
export const setViewingIsSender = state => ({
  type: 'SET_VIEWING_IS_SENDER',
  state,
});
export const setTimepoints = state => ({
  type: 'SET_TIMEPOINTS',
  state,
});

export const setSelectData = state => ({
  type: 'SET_SELECTDATA',
  state,
});
export const setCurrentFolderUUID = state => ({
  type: 'SET_CURRENT_FOLDER_UUID',
  state,
});

export const setCurrentOrgUUID = state => ({
  type: 'SET_CURRENT_ORG_UUID',
  state,
});

export const setTutorialId = state => ({
  type: 'SET_TUTORIAL_ID',
  state,
});
export const setRootPermName = state => ({
  type: 'SET_ROOT_PERM_NAME',
  state,
});

export const resetStorageManeger = () => ({
  type: 'RESET_STORAGE_MANEGER',
});

export const setMeasurements = state => ({
  type: 'SET_MEASUREMENTS',
  state,
});

export const setStudyData = (StudyInstanceUID, data) => ({
  type: 'SET_STUDY_DATA',
  StudyInstanceUID,
  data,
});

export const setAnonymizedStudyData = data => ({
  type: 'SET_ANONYMIZED_STUDY_DATA',
  data,
});

export const setAnonymizedRadio = state => ({
  type: 'SET_ANONYMIZED_RADIO',
  state,
});

export const setServers = servers => ({
  type: SET_SERVERS,
  servers,
});

// notificationは2022/11/8時点では不使用
export const addNotification = state => ({
  type: 'ADD_NOTIFICATION',
  state,
});
// notificationは2022/11/8時点では不使用
export const dissmissNotification = state => ({
  type: 'DISSMISS_NOTIFICATION',
  state,
});

const actions = {
  /**
   * VIEWPORT
   */
  setViewportActive,
  setViewportSpecificData,
  setViewportLayoutAndData,
  setLayout,
  setViewports,
  clearViewportSpecificData,
  setActiveViewportSpecificData,
  /**
   * NOT-VIEWPORT
   */
  setUserPreferences,
  setExtensionData,
  setViewingUsers,
  setViewingSender,
  setViewingIsSender,
  setTimepoints,
  setSelectData,
  setCurrentFolderUUID,
  setCurrentOrgUUID,
  setTutorialId,
  resetStorageManeger,
  setRootPermName,
  setMeasurements,
  setStudyData,
  setAnonymizedStudyData,
  setAnonymizedRadio,
  setServers,
  addNotification,
  dissmissNotification,
};

export default actions;
