import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from '../lib/react-query';

import { getActiveContexts } from '../store/layout/selectors.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './UserContext.js';


let AppContext = React.createContext({});

export const CONTEXTS = {
  CORNERSTONE: 'ACTIVE_VIEWPORT::CORNERSTONE',
  VTK: 'ACTIVE_VIEWPORT::VTK',
};

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, config }) => {
  const activeContexts = useSelector(state => getActiveContexts(state));

  const google_client_id = process.env.GOOGLE_CLIENT_ID;
  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppContext.Provider value={{ appConfig: config, activeContexts }}>
          <UserProvider>{children}</UserProvider>
        </AppContext.Provider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export const withAppContext = Component => {
  return function WrappedComponent(props) {
    const { appConfig, activeContexts } = useAppContext();
    return (
      <Component
        {...props}
        appConfig={appConfig}
        activeContexts={activeContexts}
      />
    );
  };
};

export default AppContext;
