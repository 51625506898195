/**
 * Component to display a summary of uploaded data, after the upload
 * is complete.
 */

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const useConfirmAbortUploadModal = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    resolve: () => {},
    reject: () => {},
  });

  const openConfirmAbortUploadModal = state => {
    setOpen(true);
    setState(state);
  };

  const cancelUpload = async () => {
    // console.info(
    //   'Upload cancelled from cancelUpload in useConfirmAbortUploadModal'
    // );
    state.reject({
      status: 'abort',
      message: 'Upload cancelled from ConfirmAbortUploadModal',
    });
    setOpen(false);
  };

  const resolveUpload = async () => {
    state.resolve({
      status: 'cancel',
      message: 'Abort cancelled from ConfirmAbortUploadModal',
    });
    setOpen(false);
  };

  function ConfirmAbortUploadModal() {
    const handleCancel = async () => {
      await cancelUpload();
    };

    const handleUpload = async () => {
      await resolveUpload();
    };

    return (
      <>
        <Dialog
          open={open}
          scroll="paper"
          className={classes.modal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">中止確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              アップロードを中止しますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary" variant="contained">
              中止します
            </Button>
            <Button onClick={handleUpload} color="info" variant="contained">
              中止しない
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return [openConfirmAbortUploadModal, ConfirmAbortUploadModal];
};

export default useConfirmAbortUploadModal;
