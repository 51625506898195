import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedOrgUUID } from './currentOrgUUID';

export const getOrgDetail = async ({ orgUUID }) => {
  const pathUrl = `/api/access/org/detail/${orgUUID}`;
  return await authRequest.get(pathUrl).then(res => res.data);
};

export const useOrgDetail = ({
  orgUUID = '',
  select = data => data,
  useErrorBoundary = undefined,
} = {}) => {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  const placeholderData = useMemo(() => {
    return {};
  }, []);
  const orgQuery = useQuery({
    queryKey: ['orgDetail', usedOrgUUID],
    queryFn: () => getOrgDetail({ orgUUID: usedOrgUUID }),
    enabled: !!usedOrgUUID && usedOrgUUID !== '', // org uuid が存在するまで待機
    placeholderData,
    select,
    useErrorBoundary,
  });

  return orgQuery;
};

export const useIsOrgSet = () => {
  return useOrgDetail({
    select: data => {
      return Object.keys(data).length > 0;
    },
  });
};
